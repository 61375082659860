'use strict';

(function () {
    angular.module('allMedsApp').factory('pqHelpers', function () {
        var service = {
            newGuid: newGuid,
            getQueryStringParams: getQueryStringParams
        };

        return service;

        function getQueryStringParams() {
            return window.location.search.split(/[&||?]/).filter(function (c) {
                return c.indexOf("=") > -1;
            }).map(function (c) {
                return c.split(/=/);
            }).map(function (c) {
                c[1] = c[1].replace(/\+/g, " ");
                return c;
            }).reduce(function (obj, item) {
                obj[item[0]] = item[1];
                return obj;
            }, {});
        }

        function newGuid() {
            var chars = '0123456789abcdef'.split(''),
                id = '';

            for (var i = 0; i < 36; i++) {
                if (i === 8 || i === 13 || i === 18 || i === 23) {
                    id += '-';
                } else {
                    id += chars[getRandom(chars.length)];
                }
            }
            return id;
        }

        function getRandom(max) {
            return Math.random() * max & 0xf;
        }
    }).directive('convertToNumber', function () {
        return {
            require: 'ngModel',
            link: function link(scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    return parseInt(val, 10);
                });
                ngModel.$formatters.push(function (val) {
                    return '' + val;
                });
            }
        };
    });
})();