'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').factory('scheduleEventSettingsService', scheduleEventSettingsService);

    scheduleEventSettingsService.$inject = ['$timeout', '$document', 'scheduleEventDataService'];

    function scheduleEventSettingsService($timeout, $document, scheduleEventDataService) {
        return {
            init: function init($scope, scheduler) {

                // prepare util structures
                $scope.viewModel.lists.appointmentStatusesById = {};
                $scope.viewModel.lists.appointmentStatuses.forEach(function (element) {
                    $scope.viewModel.lists.appointmentStatusesById[element.id] = element;
                });

                var flowAppointmentStatuses = $scope.viewModel.lists.appointmentStatuses.filter(function (status) {
                    return status.systemName != "new" && status.systemName != "scheduled" && status.systemName != "on-hold";
                });

                // changes just for Day flow
                flowAppointmentStatuses = JSON.parse(JSON.stringify(flowAppointmentStatuses));
                flowAppointmentStatuses.forEach(function (status) {
                    if (status.systemName == 'charges-posted') status.name = 'Charges';
                    if (status.systemName == 'post-to-bill') status.name = 'Post to Bill';
                    if (status.systemName == 'charges-finalized') status.name = 'Finalized';
                });

                var minimumPercent = 7;
                var statusItemWidth = 70;
                var statusWidth;
                var widthPercent;

                function calculateWidth() {
                    statusWidth = flowAppointmentStatuses.length * statusItemWidth;
                    var wrapperWidth = document.getElementsByClassName('page-content')[0].clientWidth;
                    widthPercent = (wrapperWidth - statusWidth) / 100;

                    if (widthPercent < minimumPercent) {
                        widthPercent = minimumPercent;
                    }
                };

                calculateWidth();

                var buildFlowStatusesElementByFlowStage = function buildFlowStatusesElementByFlowStage(flowStage, groupName, ev) {
                    if (groupName === '') {
                        groupName = '&nbsp;';
                    }

                    var widthPercent = document.getElementsByClassName('content-wrapper')[0].clientWidth;

                    var flowStageAppointmentStatuses = flowAppointmentStatuses.filter(function (status) {
                        return status.flowStage == flowStage;
                    });

                    var flowStageCount = flowStageAppointmentStatuses.length;

                    var builder = '';

                    builder += '<div style="min-width:' + statusItemWidth * flowStageCount + 'px;" class="flow-type-group ' + (ev ? 'flow-type-content-group' : '') + '">';

                    if (groupName) {
                        builder += '<div class="group-name" style="text-align:center; width:' + statusItemWidth * flowStageCount + 'px;">' + groupName + '</div>';
                    }

                    builder += '<div data-flow-stage="' + flowStage + '" class="flow-type-wrapper ' + (ev ? 'flow-type-content' : 'flow-type-header') + '">';
                    if (ev) {
                        for (var i = 0; i < flowStageCount; i++) {
                            var status = flowStageAppointmentStatuses[i];
                            var isFlowStatusExists = ev.flowStatuses != null && ev.flowStatuses.indexOf(status.id) != -1;
                            //var isFlowStatusScheduleOnly = equalsArrays(ev.flowStatuses, [scheduledStatus.id]);
                            var colorStyle = isFlowStatusExists /* && !isFlowStatusScheduleOnly */ ? 'background-color:' + status.color + '; ' : '';
                            builder += '<div class="flow-type" style="' + colorStyle + ';"></div>';
                        }
                    } else {
                        for (var i = 0; i < flowStageCount; i++) {
                            builder += '<div class="flow-type" >' + flowStageAppointmentStatuses[i].name + '</div>';
                        }
                    }
                    builder += '</div>';
                    builder += '</div>';
                    return builder;
                };

                var methods = {
                    getUnitBusyClass: function getUnitBusyClass(eventsCount) {
                        if (eventsCount > 0 && eventsCount < 3) {
                            return "easy-busy";
                        } else if (eventsCount >= 3 && eventsCount < 6) {
                            return "partial-busy";
                        } else if (eventsCount >= 6) {
                            return "fully-busy";
                        }

                        return "light";
                    },
                    getFlowSatusesHeader: function getFlowSatusesHeader() {
                        var builder = buildFlowStatusesElementByFlowStage(0, '');
                        builder += buildFlowStatusesElementByFlowStage(1, 'Patient Flow');
                        builder += buildFlowStatusesElementByFlowStage(2, 'Billing Flow');
                        return builder;
                    },
                    getFlowViewField: function getFlowViewField(start, end, ev) {
                        var builder = buildFlowStatusesElementByFlowStage(0, null, ev);
                        builder += buildFlowStatusesElementByFlowStage(1, null, ev);
                        builder += buildFlowStatusesElementByFlowStage(2, null, ev);

                        return builder;
                    }
                };

                $.extend(scheduler.config, schedulerDefaultConfig, AllMeds.Appointment.schedulerConfig, {
                    multi_day: true,
                    all_timed: true,
                    multisection: true,
                    active_link_view: "UnitDayView",
                    max_month_events: 0
                });

                AllMeds.Appointment.setIcons(scheduler);

                // Day View
                scheduler.createUnitsView({
                    name: "UnitDayView",
                    property: "schedulerUnitId",
                    list: scheduler.serverList("units"),
                    skip_incorrect: true,
                    size: 6,
                    step: 1
                });

                function getPatientFlowColumns() {
                    return [{
                        id: "start_date",
                        label: 'Time',
                        template: function template(start, end, ev) {
                            return moment(ev.start_date).format("LT");
                        },
                        sort: function sort(ev1, ev2) {
                            return ev1.start_date < ev2.start_date ? 1 : ev1.start_date > ev2.start_date ? -1 : 0;
                        },
                        initialWidth: widthPercent * 10,
                        width: widthPercent * 10
                    }, {
                        id: "patientName",
                        label: 'Patient',
                        template: function template(start, end, ev) {
                            return $.templates('<a href="Patient/Details/{{:patientId}}">{{:patientName}}</a>').render(ev);
                        },
                        initialWidth: widthPercent * 18,
                        width: widthPercent * 18
                    }, {
                        id: "providerCode",
                        label: 'Prov',
                        initialWidth: widthPercent * 8,
                        width: widthPercent * 8
                    }, {
                        id: "reason",
                        label: 'Reas',
                        template: function template(start, end, ev) {
                            return ev.reason || "";
                        },
                        initialWidth: widthPercent * 18,
                        width: widthPercent * 18
                    }, {
                        id: "officeCode",
                        label: 'Loc',
                        initialWidth: widthPercent * 10,
                        width: widthPercent * 10
                    }, {
                        id: "paid",
                        label: 'Paid',
                        initialWidth: widthPercent * 10,
                        width: widthPercent * 10
                    }, {
                        id: "eligibility",
                        label: 'Elig',
                        template: function template(start, end, ev) {
                            var elig = ev.eligibility;
                            if (elig) {

                                switch (elig.status) {
                                    case 'inactive':
                                        elig.text = 'Inactive';
                                        break;
                                    case 'needs-attention':
                                        elig.text = 'Needs Attention';
                                        break;
                                    case 'no-insurance':
                                        elig.text = 'Missing Insurance Id';
                                        break;
                                    case 'no-provider':
                                        elig.text = 'Missing Provider';
                                        break;
                                    case 'no-payor':
                                        elig.text = 'Missing Payor Id';
                                        break;
                                    case 'no-insured':
                                        elig.text = 'Missing Insured';
                                        break;
                                    case 'no-relation':
                                        elig.text = 'Missing Relation';
                                        break;
                                    case 'error':
                                        elig.text = 'ERROR';
                                        break;
                                    default:
                                        elig.text = moment(elig.date).format("L");
                                        break;
                                }

                                var node = $.templates('<span ' + 'onClick="eligibility.showModalDynamicView(\'Eligibility/Content/{{:eligibilityId}}\')" ' + 'class="flow-view-elig {{:status}}">{{:text}}</span>').render(elig);
                                return node;
                            } else {
                                return "";
                            }
                        },
                        initialWidth: widthPercent * 14,
                        width: widthPercent * 14
                    }, {
                        //AL-1485
                        id: "authorizationRequiredAlert",
                        label: 'Ref/Auth',
                        template: function template(start, end, ev) {
                            var state = ev.authorizationRequiredAlert;
                            var template = "";
                            if (ev.isRefferalPresent != null) {
                                template = template + '<span class="flow-view-authorization ' + 'flow-view-authorization_required_' + !ev.isRefferalPresent + ' ">R</span>';
                            }

                            if (ev.isAuthorizationPresent != null) {
                                template = template + '<span class="flow-view-authorization ' + 'flow-view-authorization_required_' + !ev.isAuthorizationPresent + ' ">A</span>';
                            }

                            return template;
                        },
                        initialWidth: widthPercent * 12,
                        width: widthPercent * 12
                    }, {
                        id: "statuses",
                        label: methods.getFlowSatusesHeader(),
                        template: methods.getFlowViewField,
                        initialWidth: statusWidth,
                        width: statusWidth
                    }];
                }

                // Flow View
                var flowViewProperties = {
                    name: "PatientFlow",
                    fields: getPatientFlowColumns(),
                    select: false,
                    paging: true,
                    unit: 'day'
                };

                scheduler.filter_PatientFlow = function (id, event) {
                    return event.metaTypes !== 1 && event.patientId; // only non-empty appointments
                };

                scheduler.createGridView(flowViewProperties);
                scheduler.locale.labels.PatientFlow_tab = "Flow";

                // Events
                scheduler.attachEvent("onBeforeViewChange", function (old_mode, old_date, mode, date) {
                    var startDateMoment = moment(date);
                    var endDateMoment = moment(date);
                    switch (mode) {
                        case "UnitDayView":
                        case "PatientFlow":
                            $scope.scheduler.startDate = startDateMoment.toDate();
                            $scope.scheduler.endDate = endDateMoment.toDate();
                            break;
                        case "week":
                            $scope.scheduler.startDate = startDateMoment.startOf('week').toDate();
                            $scope.scheduler.endDate = endDateMoment.endOf('week').toDate();
                            break;
                        case "month":
                            $scope.scheduler.startDate = startDateMoment.startOf('month').toDate();
                            $scope.scheduler.endDate = endDateMoment.endOf('month').toDate();
                            break;
                    }

                    $scope.scheduler._mode = mode;
                    return true;
                });
                var oldClickHandler = scheduler._click.dhx_cal_header;
                scheduler._click.dhx_cal_header = function (e) {
                    var target = e.target;
                    if (target.classList && target.classList.contains('dhx_grid_line')) {
                        return;
                    } else {
                        oldClickHandler(e);
                    }
                };

                var templates = {
                    day_date: function day_date(date) {
                        setDateTitle(scheduler.date.date_to_str(scheduler.config.default_date)(date));
                        return "";
                    },
                    week_date: function week_date(start, end) {
                        var formatFunc = scheduler.date.date_to_str(scheduler.config.default_date);
                        setDateTitle(formatFunc(start) + " - " + formatFunc(scheduler.date.add(end, -1, "day")));
                        return "";
                    },
                    month_date: function month_date(date) {
                        setDateTitle(scheduler.date.date_to_str(scheduler.config.month_date)(date));
                        return "";
                    },
                    PatientFlow_date: function PatientFlow_date(start, end) {
                        setDateTitle(scheduler.date.date_to_str(scheduler.config.default_date)(start));
                        return "";
                    },
                    month_date_class: function month_date_class(date) {
                        var dateEvents = scheduler.getEvents(date, scheduler.date.add(date, 1, 'day'));
                        if (dateEvents.length > 0) {
                            return 'full-day';
                        }
                        return 'empty-day';
                    },
                    month_day: function month_day(date) {
                        var week_x = scheduler.templates['_active_links_old_month_day'];
                        var d_s = scheduler.date.date_to_str(scheduler.config.api_date);
                        return "<a jump_to='" + d_s(date) + "' href='#'><span jump_to='" + d_s(date) + "'>" + week_x(date) + "</span></a>";
                    },
                    month_events_link: function month_events_link(date, count) {
                        var dateEvents = scheduler.getEvents(date, scheduler.date.add(date, 1, "day"));
                        if (dateEvents.length === 0) return "";

                        var amEventsCount = 0,
                            pmEventsCount = 0,
                            dateMidTime = scheduler.date.add(date, 12, "hour"),
                            doesAmUnavailableExist = false,
                            doesPmUnavailableExist = false;

                        dateEvents.filter(function (event) {
                            return event.metaTypeId !== 1 && event.patientId;
                        }).forEach(function (event) {
                            var startDateTimeMoment = moment(event.start_date);
                            if (startDateTimeMoment.diff(dateMidTime) < 0) {
                                amEventsCount++;
                            } else {
                                pmEventsCount++;
                            }
                        });

                        // unavailable events
                        var unavailableEvents = dateEvents.filter(function (event) {
                            return event.metaTypeId === 1;
                        });
                        var unavailableIntervals = Sets.unionIntervals(unavailableEvents.map(function (event) {
                            return {
                                from: event.start_date.getTime(),
                                to: event.end_date.getTime()
                            };
                        }));
                        if (unavailableIntervals.length !== 0) {
                            var dateEndTime = scheduler.date.add(date, 24, "hour");
                            doesAmUnavailableExist = Sets.isSubIntervalOfIntervals({ from: date.getTime(), to: dateMidTime.getTime() }, unavailableIntervals);
                            doesPmUnavailableExist = Sets.isSubIntervalOfIntervals({ from: dateMidTime.getTime(), to: dateEndTime.getTime() }, unavailableIntervals);
                        }

                        var totalEventsCount = amEventsCount + pmEventsCount;

                        if (totalEventsCount == 0 && !doesAmUnavailableExist && !doesPmUnavailableExist) {
                            return "";
                        }

                        var amCircle = totalEventsCount > 0 ? '<div class="circle">' + amEventsCount + '</div><span>am</span>' : '',
                            pmCircle = totalEventsCount > 0 ? '<div class="circle">' + pmEventsCount + '</div><span>pm</span>' : '';
                        var builder = "<table date='" + date + "' events-count='" + totalEventsCount + "'> \
                            <tr> \
                                <td class='am-day " + methods.getUnitBusyClass(amEventsCount) + ' ' + (doesAmUnavailableExist ? 'unavailable-exists' : '') + "'>" + amCircle + "</td> \
                                <td class='pm-day " + methods.getUnitBusyClass(pmEventsCount) + ' ' + (doesPmUnavailableExist ? 'unavailable-exists' : '') + "'>" + pmCircle + "</td> \
                            </tr> \
                        </table";
                        return builder;
                    },
                    event_class: function event_class(start, end, schedulerEvent) {
                        var builder = "";
                        if (!isUndefinedOrNull(schedulerEvent.metaTypeId)) {
                            builder += " meta-type-" + schedulerEvent.metaTypeId;
                        }
                        if (!isUndefinedOrNull(schedulerEvent.allowParallelAppointments)) {
                            builder += " allow-appt-" + schedulerEvent.allowParallelAppointments;
                        }
                        if (schedulerEvent.metaTypeId === 0 && !schedulerEvent.patientId) {
                            builder += " schedule-template-event";
                        } else if (schedulerEvent.appointmentTypeId) {
                            builder += " appointment-type-" + schedulerEvent.appointmentTypeId;
                        }
                        if (schedulerEvent.officeId) {
                            builder += " office-" + schedulerEvent.officeId;
                        }

                        if ($scope.viewModel.searchSlotParams.searchedNextSlotId != null && schedulerEvent.id == $scope.viewModel.searchSlotParams.searchedNextSlotId) {
                            builder += " searched-template-slot swing-animation";
                        }

                        if ($scope.viewModel.rescheduleItem && $scope.viewModel.rescheduleItem.originAppointmentId == schedulerEvent.id) {
                            builder += " reschedule-item";
                        }

                        return builder;
                    },
                    event_header: function event_header(start, end, schedulerEvent) {
                        var builder;
                        if (schedulerEvent.metaTypeId === 1) {
                            // Unavailable
                            builder = "<span class='title'>" + (schedulerEvent.notes ? schedulerEvent.notes : "") + "</span>";
                        } else if (schedulerEvent.metaTypeId === 0 && !schedulerEvent.patientId) {
                            builder = "<span class='office-strip'></span><span class='title'>" + (schedulerEvent.appointmentTypeName ? schedulerEvent.appointmentTypeName : "") + "</span>";
                        } else {
                            builder = "<span class='office-strip'></span><span class='title'>" + (schedulerEvent.patientName ? schedulerEvent.patientName : "") + "</span>";
                            if (schedulerEvent.appointmentStatusId) {
                                var imgUrl = "content/pages/schedule-event/images/statuses/" + $scope.viewModel.lists.appointmentStatusesById[schedulerEvent.appointmentStatusId].systemName + "-event" + (Retina.isRetina() ? '@2x' : '') + ".png";
                                builder += "<img class='status-icon' src='" + imgUrl + "' height='14px'></span>";
                            }
                        }
                        return builder;
                    },
                    event_text: function event_text(start, end, scheduleEvent) {
                        if (scheduleEvent.metaTypeId === 1) {
                            // Unavailable
                            return ''; // no body
                        }
                        var contentParts = [];
                        if (scheduleEvent.patientId) {
                            if (scheduleEvent.appointmentTypeName) {
                                contentParts.push('<span class="time-range">' + moment(start).format('LT') + ' - ' + moment(end).format('LT') + '</span>' + scheduleEvent.appointmentTypeName);
                            }
                            if (scheduleEvent.copay) {
                                contentParts.push('Copay - ' + scheduleEvent.copay);
                            }
                            //if (scheduleEvent.notes) {
                            //    contentParts.push(scheduleEvent.notes);
                            //}

                            if (scheduleEvent.isInRescheduleQueue) {
                                contentParts.push('<span class="reschedule-indicator">(In Reschedule Queue)</span>');
                            }
                        }

                        return '<span class="office-strip"></span><span class="description">' + contentParts.join('<br/>') + '</span>' + '<span class="template-code">' + (scheduleEvent.scheduleTemplateCode ? scheduleEvent.scheduleTemplateCode : '') + '</span>' + '<span class="office-code">' + (scheduleEvent.officeCode ? scheduleEvent.officeCode : '') + '</span>';
                    },
                    UnitDayView_scale_text: function UnitDayView_scale_text(key, label, unit) {
                        var builder = "<span class='resource-title' data-resource-type='" + unit.type + "'>";
                        builder += "<span class='name'>" + unit.name + "</span>";
                        if (unit.officeName) {
                            builder += " <small class='additional-info'>(" + unit.officeName + ")</small>";
                        }
                        builder += "</span>";

                        if (unit.type === 'Provider') {
                            builder += '<span data-provider-id="' + unit.id + '" data-on-header-added="scheduler_InsertProviderNoteElement"></span>';
                        } else if (unit.type === 'Resource') {
                            builder += '<span data-resource-id="' + unit.id + '" data-on-header-added="scheduler_InsertResourceNoteElement"></span>';
                        }

                        return builder;
                    },
                    hour_scale: function hour_scale(date) {
                        return scheduler.templates.hour_scale_by_zoom(date, $scope.scheduler.zoomCoef, $scope.scheduler.hourHeightSize);
                    }
                };

                function setDateTitle(dateTitle) {
                    if (dateTitle !== $scope.scheduler.dateTitle) {
                        $scope.scheduler.dateTitle = dateTitle;
                        $timeout(function () {
                            $scope.$apply();
                        });
                    }
                }
                scheduler.attachEvent("onTemplatesReady", function () {
                    $.extend(scheduler.templates, schedulerDefaultTemplates, templates);
                });

                scheduler.attachEvent("onViewMoreClick", function (date) {
                    $scope.scheduler.mode = 'UnitDayView';
                    $scope.scheduler.date = date;
                    scheduler.setCurrentView($scope.scheduler.date, $scope.scheduler.mode);
                });

                scheduler.attachEvent("onBeforeEventChanged", function (ev, e, is_new, original) {
                    if (!is_new) {
                        if (ev.schedulerUnitId != original.schedulerUnitId) {
                            var newUnitIdParts = ev.schedulerUnitId.split('-'),
                                oldUnitIdParts = original.schedulerUnitId.split('-');

                            if (newUnitIdParts[0] != oldUnitIdParts[0]) return false;

                            var unitType = newUnitIdParts[0],
                                unitId = parseInt(newUnitIdParts[1]);

                            switch (unitType) {
                                case 'Provider':
                                    ev.providerId = unitId;
                                    ev.resourceId = null;
                                    break;
                                case 'Resource':
                                    ev.providerId = null;
                                    ev.resourceId = unitId;
                                    break;
                            }
                        }

                        //ev.editorStatusType = "updated";

                        if (ev.isRecurring) {
                            var isSameDay = moment(original.start_date).isSame(ev.start_date, 'day') && moment(original.end_date).isSame(ev.end_date, 'day');
                            if (isSameDay) {
                                popup.showMessageModal({
                                    message: "Would you like to apply changes to all recurring appointments?",
                                    actions: [{
                                        style: 'success',
                                        caption: 'Yes',
                                        handler: function handler(e) {
                                            ev.recurringOperation = 'remove-update';
                                            checkUnavailable(ev);
                                        }
                                    }, {
                                        style: 'success',
                                        caption: 'Apply Only to Current Appointment',
                                        handler: function handler(e) {
                                            ev.recurringOperation = 'do-nothing';
                                            checkUnavailable(ev);
                                        }
                                    }, {
                                        style: 'info',
                                        caption: 'Apply to all Future Appointments',
                                        handler: function handler(e) {
                                            ev.recurringOperation = 'recurring-update-future-only';
                                            checkUnavailable(ev);
                                        }
                                    }, {
                                        style: 'default',
                                        caption: 'Cancel',
                                        handler: function handler(e) {
                                            $(document).trigger('scheduleEvent:refresh');
                                        }
                                    }]
                                });
                            } else {
                                ev.recurringOperation = 'do-nothing';
                                scheduleEventDataService.updateProperties(ev);
                            }
                        } else if (!moment(original.start_date).isSame(ev.start_date) || !moment(original.end_date).isSame(ev.end_date) || ev.schedulerUnitId != original.schedulerUnitId) {
                            return scheduleEventDataService.updateProperties(ev);
                        }
                    }
                    return true;
                });

                function checkUnavailable(ev) {
                    scheduleEventDataService.checkUnavailability(ev).then(function (data) {
                        if (data.isSuccess && data.data.intersections && data.data.intersections.length > 0) {

                            var disallowSave = false;

                            data.data.intersections.forEach(function (i) {
                                if (moment(i).isSame(ev.start_date, 'day')) {
                                    disallowSave = true;
                                }
                            });

                            if (disallowSave) {
                                popup.showMessageModal({
                                    title: 'Warning',
                                    message: 'Unable to place Appointment because Unavailable slot',
                                    actions: [{
                                        style: 'default',
                                        caption: 'OK',
                                        handler: function handler(e) {
                                            $(document).trigger('scheduleEvent:refresh');
                                        }
                                    }]
                                });
                            } else {
                                popup.showMessageModal({
                                    title: 'Warning',
                                    message: 'There are one or more unavailable appointment slots within the date range you have specified. Do you still want to create the appointment in those slots? (If you choose “No” those appointments will be skipped and can only be recreated manually)',
                                    actions: [{
                                        style: 'success',
                                        caption: 'Yes',
                                        handler: function handler() {
                                            ev.skipUnavailable = true;
                                            scheduleEventDataService.updateProperties(ev);
                                        }
                                    }, {
                                        style: 'default',
                                        caption: 'No',
                                        handler: function handler() {
                                            ev.skipUnavailable = false;
                                            scheduleEventDataService.updateProperties(ev);
                                        }
                                    }, {
                                        style: 'default',
                                        caption: 'Cancel',
                                        handler: function handler(e) {
                                            $(document).trigger('scheduleEvent:refresh');
                                        }
                                    }]
                                });
                            }
                        } else {
                            scheduleEventDataService.updateProperties(ev);
                        }
                    });
                };

                function beautifyNav() {
                    calculateWidth();
                    scheduler.grid_PatientFlow.columns = getPatientFlowColumns();

                    $timeout(function () {
                        scheduler.updateView();
                    });
                }

                $document.on("navigation:collapse", beautifyNav);
                $document.on("navigation:expand", beautifyNav);
            },
            addWorkingTimes: function addWorkingTimes(workingTimes, schedulerMode) {
                workingTimes.forEach(function (wkTime) {
                    wkTime.timeRanges.forEach(function (item) {
                        var markTimespan = {
                            start_date: moment(item.start_date).toDate(),
                            end_date: moment(item.end_date).toDate(),
                            css: 'workingtime-timespan office-' + wkTime.officeId,
                            html: '<div class="office-strip"></div>'
                        };
                        if (schedulerMode === 'UnitDayView') {
                            markTimespan.sections = { UnitDayView: "Provider-" + wkTime.providerId };
                        }
                        scheduler.addMarkedTimespan(markTimespan);
                    });
                });
            },
            addNonWorkingTimes: function addNonWorkingTimes(nonWorkingTimes, schedulerMode) {
                nonWorkingTimes.forEach(function (item) {
                    var markTimespan = {
                        start_date: moment(item.start_date).toDate(),
                        end_date: moment(item.end_date).toDate(),
                        css: 'nonworkingtime-timespan'
                    };
                    if (schedulerMode === 'UnitDayView') {
                        markTimespan.sections = { UnitDayView: "Provider-" + item.providerId };
                    }
                    scheduler.addMarkedTimespan(markTimespan);
                });
            }
        };
    }
})();