'use strict';

blocks.register('waf-action_Estimation-Search', function () {
    return {
        link: function link(block) {
            block.find('.search-result-table__row td > a').on('click', function (e) {
                e.preventDefault();

                var url = $(e.currentTarget).attr('href');

                showWafModal({ url: url });
            });
        }
    };
});