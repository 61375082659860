'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('creditBalance', creditBalance);

    function creditBalance() {
        return {
            restrict: 'A',
            require: "pqTable",
            scope: false,
            link: function link(scope, element, attrs, tableCtrl) {
                scope.$on('Refund', function (e) {
                    var params = e.targetScope.$ctrl.field.params;

                    var $pqTable = $('<pq-table class="report-table" model="{action:\'VisitPatientPaymentForRefund/Data?patientId=' + params.patientId + '\'}"></pq-table>');
                    var $$scope = $pqTable.pqCompileWithNewScope();

                    var $container = $('<div style="min-width: 800px;"></div>');
                    $container.append($pqTable);

                    var modalService = new ModalService();

                    modalService.showAlertModalAsync('Choose a Payment to Refund', $container).then(function () {
                        $$scope.$destroy();

                        tableCtrl.refresh();
                    });
                });
            }
        };
    }
})();