'use strict';

(function () {
    'use strict';

    angular.module('table.module').directive('pqListFilter', listFilter);

    listFilter.$inject = ['$templateCache', '$compile'];

    function listFilter($templateCache, $compile) {
        return {
            restrict: "E",
            link: function link(scope, element, attrs) {
                scope.viewModel = {
                    selectedValue: scope.filter.values[0]
                };

                scope.$watch('viewModel.selectedValue', function (nv, ov) {
                    if (nv != ov) {
                        scope.filter.values = [nv];
                    }
                });

                scope.refreshSearch = function ($select) {
                    if (scope.filter.isSuggest) {
                        $.get(scope.filter.suggestUrl, { term: $select.search }).done(function (data) {
                            if (data.length && data[0].DisplayName) {
                                data = data.map(function (item) {
                                    return {
                                        displayName: item.DisplayName,
                                        value: item.Value
                                    };
                                });
                            }

                            scope.filter.options = data;
                            $select.refreshItems();
                        });
                    }
                };

                if (scope.filter) {
                    var templateName;

                    if (scope.filter.isMultiselect) {
                        templateName = '_multipleList.filter';
                    } else {
                        templateName = '_singleList.filter';
                    }

                    var templateHtml = $templateCache.get('components/table/searchFilters/list.filter/' + templateName + '.html');
                    element.html($compile(templateHtml)(scope));

                    if (scope.filter.isSuggest) {}
                }
            }
        };
    }
})();