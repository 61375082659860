'use strict';

function dragAndDropEnable($container, draggableElementSelector, initElementSelector, draggingClass) {
    var source;
    var onDragFinishFunc;
    var elementOriginalIndex;

    function isbefore(a, b) {
        if (a.parentNode == b.parentNode) {
            for (var cur = a; cur; cur = cur.previousSibling) {
                if (cur === b) {
                    return true;
                }
            }
        }
        return false;
    }

    function dragenter(e) {

        if (source != e.currentTarget) {

            if (isbefore(source, e.currentTarget)) {
                e.currentTarget.parentNode.insertBefore(source, e.currentTarget);
            } else {
                e.currentTarget.parentNode.insertBefore(source, e.currentTarget.nextSibling);
            }
        }
    }

    function dragstart(e) {
        source = e.currentTarget;
        e.dataTransfer = e.originalEvent.dataTransfer;
        e.dataTransfer.effectAllowed = 'move';
    }

    function ondragfinish(func) {
        onDragFinishFunc = func;
    }

    $container.on('mousedown', initElementSelector, function (e) {

        var $initElement = $(this);
        var $element = $initElement.parents(draggableElementSelector + ':first');

        elementOriginalIndex = $container.find(draggableElementSelector).index($element);

        $container.on('mouseup dragend', function (e) {
            $container.off('mouseup dragend');
            $container.off('dragenter', draggableElementSelector);

            $element.removeAttr('draggable');

            if (draggingClass) {
                $element.removeClass(draggingClass);
            }

            var elementNewIndex = $container.find(draggableElementSelector).index($element);

            if (elementNewIndex != elementOriginalIndex && onDragFinishFunc) {
                onDragFinishFunc($element, elementOriginalIndex, elementNewIndex);
            }
        });

        if (draggingClass) {
            $element.addClass(draggingClass);
        }

        $container.on('dragenter', draggableElementSelector, dragenter);
        $element.attr('draggable', 'true');
        $element.one('dragstart', dragstart);
    });

    return {
        onDragFinish: ondragfinish
    };
}