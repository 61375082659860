'use strict';

blocks.register("waf-action_EstimationLine-Details", function () {
    var $block;
    var $productFiltersGroup;
    var $productGroup;

    var $billedAmountField;

    return {
        link: function link(block) {
            $block = block;

            $productFiltersGroup = $block.find('.form-field-group[data-group="ProductFilters"]');
            $productGroup = $block.find('.form-field-group[data-group="Product"]');

            $billedAmountField = $block.find('[name$="_BilledAmount"]');

            //calculate Billed Total: on edit open
            var $units = $block.find("[id$=UnitNumber]");

            $units.change();

            var $lineTypeField = $block.find('[name$="_LineType"]');

            $lineTypeField.on('change', function () {
                toggleProductGroups($(this).val());
            });

            toggleProductGroups($lineTypeField.filter(':checked').val());

            applyValidation();
        }
    };

    function toggleProductGroups(lineType) {
        if (lineType == 1) {
            $productFiltersGroup.show();
            $productGroup.show();
        } else {
            $productFiltersGroup.hide();
            $productGroup.hide();
        }
    }

    function applyValidation() {
        $.validator.addMethod("estimationline-allowed-less-or-equal-billed", function (value, element) {
            return parseFloatNaNToZero(value) <= parseFloatNaNToZero($billedAmountField.val());
        });

        $block.find('[name$="_AlowedAmount"]').rules("add", {
            "estimationline-allowed-less-or-equal-billed": true,
            messages: {
                "estimationline-allowed-less-or-equal-billed": "Current amount can't be higher than the billed per unit amount"
            }
        });
    }
});