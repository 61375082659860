'use strict';

(function () {
    'use strict';

    angular.module('table.module').directive('pqTableField', pqTableField);

    pqTableField.$inject = ['$compile'];

    function pqTableField($compile) {
        return {
            restrict: 'E',
            scope: {
                model: '<',
                row: '<'
            },
            link: function link(scope, element, attrs) {
                $('<pq-' + scope.model.type.toLowerCase() + '-field field="model" row="row" />').appendTo(element);

                $compile(element.contents())(scope);
            }
        };
    }
})();