'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').directive('scheduleCopyingTemplate', scheduleCopyingTemplateDirective);

    scheduleCopyingTemplateDirective.$inject = ['$http', 'scheduleTemplateDataService'];

    function scheduleCopyingTemplateDirective($http, scheduleTemplateDataService) {
        return {
            restrict: 'A',
            scope: false,
            link: function link(scope, element) {
                var $form = $('form', element);
                scope.popupCopy = {};

                var viewModel = scope.popupCopy.viewModel = {
                    fromDay: null,
                    toDays: {}
                };

                Object.defineProperty(viewModel.toDays, "isValid", {
                    enumerable: false,
                    get: function get() {
                        return getCheckedToDays(this).length > 0 ? true : undefined;
                    }
                });

                $('#schedule_copying_template_btn').on('click', function () {
                    popup.openOverlay(element);
                });

                scope.popupCopy.copyDayCommand = function () {
                    if (!scope.schedule_copying_template_popup_form.$valid) {
                        $form.addClass('show-validation-error');
                        return;
                    } else {
                        $form.removeClass('show-validation-error');
                    }

                    var toDays = getCheckedToDays(viewModel.toDays);
                    if (!toDays.length) {
                        return;
                    }

                    scheduleTemplateDataService.copyDay(scope.applyViewModel.scheduleTemplateId, viewModel.fromDay, toDays).$promise.then(function (response) {
                        if (response.isSuccess) {
                            scope.popupCopy.cancelCommand();
                            scope.reLoadData(scope.applyViewModel.scheduleTemplateId);
                            popup.showAlertModal(response.countCopied + ' slots were copied to selected days.');
                        }
                    });
                };

                scope.popupCopy.cancelCommand = function () {
                    popup.closeOverlay(element);
                };

                scope.$watch("popupCopy.viewModel.fromDay", function (nv, ov) {
                    if (nv != ov) {
                        if (viewModel.toDays[nv]) {
                            viewModel.toDays[nv] = false;
                        }
                    }
                });

                function getCheckedToDays(toDaysObj) {
                    var toDays = [];
                    for (var key in toDaysObj) {
                        if (toDaysObj[key]) {
                            toDays.push(key);
                        }
                    }
                    return toDays;
                }
            }
        };
    }
})();