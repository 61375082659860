'use strict';

(function () {
    'use strict';

    angular.module('external.module').component('pqAcceptPayment', {
        bindings: {
            model: '<'
        },
        templateUrl: 'components/external/acceptPayment.component/acceptPayment.component.html',
        controller: AcceptPaymentController
    });

    AcceptPaymentController.$inject = ['externalDataService', '$scope', '$timeout'];

    function AcceptPaymentController(externalDataService, $scope, $timeout, $http) {
        $scope.data = {
            listState: 'list',
            token: this.model.Token,
            model: {
                statements: []
            },
            paymentModel: {}
        };

        $scope.payStatement = function (statementInfo) {
            $scope.data.listState = 'details';
            $scope.data.paymentModel.CorporationName = statementInfo.CorporationName;
            $scope.data.paymentModel.Amount = statementInfo.LastStatementAmount;
            $scope.data.paymentModel.CorporationId = statementInfo.CorporationId;
            $scope.data.paymentModel.StatementId = statementInfo.StatementId;
        };

        $scope.cancelPayment = function () {
            $scope.data.listState = 'list';
        };

        $scope.getViewModel = function () {
            $scope.data.isLoading = true;
            externalDataService.getPaymentViewModel($scope.data.token).then(function (response) {
                $scope.data.model.statements = response.statements;
                $scope.data.paymentModel = response.newPaymentModel;
                $scope.data.listState = 'list';
            }).catch(function (responseError) {
                showMessage('danger', responseError, $timeout, $scope);
            }).finally(function () {
                $scope.data.isLoading = false;
            });
        };

        $scope.getDownloadStatementlink = function (statementId) {
            return 'PaymentsExtension/GetStatementFile?id=' + statementId + '&token=' + $scope.data.token;
        };

        $scope.showStatement = function (statementId) {
            $('#statementViewBox').attr('data', $scope.getDownloadStatementlink(statementId));
            $scope.data.listState = 'preview';
        };

        $scope.executePayment = function () {
            if ($scope.validatePaymentInfo()) {
                $scope.data.isLoading = true;
                externalDataService.executePayment($scope.data.token, $scope.data.paymentModel).then(function (response) {
                    $scope.getViewModel();
                    showMessage('success', 'Request accepted.', $timeout, $scope);
                }).catch(function (responseError) {
                    showMessage('danger', 'Payment was not accepted. Please contact support.', $timeout, $scope);
                }).finally(function () {
                    $scope.data.isLoading = false;
                });
            }
        };

        $scope.validatePaymentInfo = function () {
            if (!$scope.data.paymentModel.CreditCardNumber || $scope.data.paymentModel.CreditCardNumber.length < 19) {
                showMessage('danger', 'Card number is invalid', $timeout, $scope);
                return false;
            }

            if (!$scope.data.paymentModel.NameOnCard) {
                showMessage('danger', 'Cardholder could not be empty', $timeout, $scope);
                return false;
            }

            if (!$scope.data.paymentModel.ExpirationDate || $scope.data.paymentModel.ExpirationDate.length !== 9) {
                showMessage('danger', 'Expiration Date should be in  MM / YYYY format', $timeout, $scope);
                return false;
            }

            if (!$scope.data.paymentModel.SecurityCode) {
                showMessage('danger', 'Security Code could not be empty', $timeout, $scope);
                return false;
            }
            return true;
        };

        $scope.getViewModel();
        $('.payment-form').card({ container: '.card-wrapper' });
    }

    function showMessage(state, message, $timeout, $scope) {
        $scope.data.message = { state: state, message: message, show: true };
        $timeout(function () {
            $scope.data.message.show = false;
        }, 5000);
    }

    function GetSelectedValueById(values, id) {
        for (var i = 0; i < values.length; i++) {
            if (values[i].Id === id) {
                return values[i];
            }
        }
    }
})();