'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').directive('scheduleEventHints', scheduleEventHintsDirective);

    scheduleEventHintsDirective.$inject = ['$http', '$document', '$templateCache', '$compile'];

    function scheduleEventHintsDirective($http, $document, $templateCache, $compile) {
        return {
            restrict: 'A',
            scope: false,
            link: function link(scope, element) {
                var $container = element.find(".dhx_cal_data");

                var isDown = false;
                $document.mousedown(function () {
                    isDown = true;
                }) //fix IE e.which and e.button
                .mouseup(function () {
                    isDown = false;
                });

                $container.on("mouseenter", ".dhx_cal_event[class*='meta-type-']", function (e) {
                    if (isDown) {
                        return;
                    }

                    var $eventElement = $(this);
                    var eventId = $eventElement.attr("event_id");
                    var event = scheduler.getEvent(eventId);
                    var isSuitableEvent = event.metaTypeId === 0 && event.patientId;

                    if (!isSuitableEvent) {
                        return;
                    }

                    // add handler
                    $eventElement.qtip({
                        content: {
                            text: function text(event, api) {
                                $http.get('/ScheduleEvent/AppointmentDetails', { params: { id: eventId } }).success(function (data) {
                                    if (!api) {
                                        // bubble was destroyed
                                        return;
                                    }

                                    var templateScope = scope.$new(true);
                                    templateScope.model = data;

                                    var templateHtml = $templateCache.get('scheduler/scheduleEventHints/appointmentDetailsBubble.html');
                                    var $compiledTemplate = $compile(templateHtml)(templateScope);

                                    // wait until $apply ($http) is not finished ($apply is required to complete template generation)
                                    setTimeout(function () {
                                        api.set({
                                            'content.title': data.patient ? data.patient.name + (data.patient.accountNumber ? ' <b>(<u>' + data.patient.accountNumber + '</u>)</b>' : '') : 'Appointment',
                                            'content.text': $compiledTemplate
                                        });
                                    }, 0);
                                });

                                return $compile('<div class="event-bubble-loading"><pq-preloader show="true" delay="200" class="pls-blue" /></div>')(scope);
                            }
                        },
                        position: {
                            my: 'bottom center',
                            at: 'top center',
                            viewport: $(window),
                            effect: false // effect removed because sizes of loading and content are very different (looks not nice)
                        },
                        show: {
                            ready: true,
                            solo: true,
                            delay: 300 // todo: move into global config
                        },
                        hide: {
                            event: 'mouseleave mousedown'
                        },
                        style: {
                            classes: 'qtip-light',
                            width: 200
                        },
                        events: {
                            hide: function hide(event, api) {
                                // scheduler creates new DOM elements for events very often (on each resize, mode/time change, etc.)
                                // so the simplest way to clean up qTips is to destroy them right after hide
                                api.destroy();
                            }
                        }
                    });
                });
            }
        };
    }
})();