'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').factory('scheduleEventDataService', scheduleEventDataService);

    scheduleEventDataService.$inject = ['$http', '$resource'];

    function scheduleEventDataService($http, $resource) {
        var scheduleEventResource = $resource('/ScheduleEvent/:action', null, {
            getViewModel: { method: 'Post', params: { action: 'GetViewModel' }, interceptor: { response: resourceResponseHandler } },
            updateProperties: { method: 'Post', params: { action: 'UpdateAppointmentProperties' } },
            data: { method: 'Post', params: { action: 'Data' } },
            dayInsuranceClass: { method: 'Post', params: { action: 'DayInsuranceClass' } },
            appointmentSummary: { method: 'Post', params: { action: 'AppointmentSummary' } },
            getParametersByLocation: { method: 'Post', params: { action: 'GetParametersByLocation' } },
            searchNextTemplateSlot: { method: 'Post', params: { action: 'searchNextTemplateSlot' } },
            searchNextOpenSlot: { method: 'Post', params: { action: 'SearchNextOpenSlot' } },
            saveFilterSettings: { method: 'Post', params: { action: 'SaveFilterSettingsAsync' }, interceptor: { response: resourceResponseHandler } },
            checkUnavailability: { method: 'Post', params: { action: 'CheckIfNoAvailableConflictsSimplified' } },
            checkIfAllowScheduleOutsideTemplateSlots: { method: 'Post', params: { action: 'CheckIfAllowScheduleOutsideTemplateSlots' } }
        });

        var methods = {
            eventResponseError: function eventResponseError(message) {
                popup.showAlertModal(message);
                return false;
            }
        };

        var debounceFilters = {};
        var debounce = $.debounce(1000, function filterSettings() {
            scheduleEventResource.saveFilterSettings({ filters: JSON.stringify(debounceFilters) });
        });

        return {
            getViewModel: function getViewModel(params) {
                return scheduleEventResource.getViewModel(params).$promise;
            },
            getAppointmentSummary: function getAppointmentSummary(patientId) {
                return scheduleEventResource.appointmentSummary({ patientId: patientId }).$promise;
            },
            getData: function getData(params) {
                return scheduleEventResource.data(params).$promise;
            },
            getDayInsuranceClass: function getDayInsuranceClass(params) {
                return scheduleEventResource.dayInsuranceClass(params).$promise;
            },
            getParametersByLocation: function getParametersByLocation(params) {
                return scheduleEventResource.getParametersByLocation(params).$promise;
            },
            searchNextTemplateSlot: function searchNextTemplateSlot(params) {
                return scheduleEventResource.searchNextTemplateSlot(params).$promise;
            },
            searchNextOpenSlot: function searchNextOpenSlot(params) {
                return scheduleEventResource.searchNextOpenSlot(params).$promise;
            },
            checkUnavailability: function checkUnavailability(scheduleEvent) {
                var startDateMoment = moment(scheduleEvent.start_date),
                    endDateMoment = moment(scheduleEvent.end_date),
                    startDate = startDateMoment.format('L') + ' ' + startDateMoment.format('LT'),
                    //fix timezone
                endDate = endDateMoment.format('L') + ' ' + endDateMoment.format('LT');

                return scheduleEventResource.checkUnavailability({
                    scheduleEventId: scheduleEvent.id,
                    newStart: startDate,
                    newEnd: endDate,
                    isSingleCHange: !(scheduleEvent.recurringOperation === 'remove-update'),
                    providerId: scheduleEvent.providerId
                }).$promise;
            },
            updateProperties: function updateProperties(scheduleEvent) {
                var startDateMoment = moment(scheduleEvent.start_date),
                    endDateMoment = moment(scheduleEvent.end_date),
                    startDate = startDateMoment.format('L') + ' ' + startDateMoment.format('LT'),
                    //fix timezone
                endDate = endDateMoment.format('L') + ' ' + endDateMoment.format('LT');

                var data = {
                    scheduleEventId: scheduleEvent.id,
                    startDate: startDate,
                    endDate: endDate,
                    providerId: scheduleEvent.providerId,
                    resourceId: scheduleEvent.resourceId,
                    recurringOperation: scheduleEvent.recurringOperation,
                    skipUnavailable: scheduleEvent.skipUnavailable,
                    metaType: scheduleEvent.metaTypeId
                };

                return scheduleEventResource.updateProperties(data).$promise.then(function (response) {
                    if (!response.success || scheduleEvent.recurringOperation == 'remove-update' || scheduleEvent.recurringOperation == 'recurring-update-future-only') {
                        $(document).trigger('scheduleEvent:refresh');
                    }

                    if (!response.success) {
                        //hack :(
                        methods.eventResponseError(response.message);
                    }

                    return true;
                }, function (response) {
                    methods.eventResponseError(response.statusText);
                });
            },
            saveFilterSettings: function saveFilterSettings(filters) {
                // debounced - { "officeIds": "", "providerIds", "", "resourceIds": "" }
                debounceFilters = filters;
                debounce();
            },
            checkIfAllowScheduleOutsideTemplateSlots: function checkIfAllowScheduleOutsideTemplateSlots() {
                return scheduleEventResource.checkIfAllowScheduleOutsideTemplateSlots().$promise;
            }
        };

        function resourceResponseHandler(response) {
            var data = response.data;

            if (data && !data.isSuccess && data.error) {
                methods.eventResponseError(data.error.message);
            }

            return response.resource;
        }
    }
})();