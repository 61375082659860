'use strict';

(function () {
    'use strict';

    angular.module('external.module').component('pqRequestAppointment', {
        bindings: {
            model: '<'
        },
        templateUrl: 'components/external/apointmentRequest.component/apointmentRequest.component.html',
        controller: ApointmentRequestController
    });

    ApointmentRequestController.$inject = ['externalDataService', '$scope', '$timeout'];

    function ApointmentRequestController(externalDataService, $scope, $timeout) {
        var $ctrl = this;

        $scope.data = {
            token: $ctrl.model.Token,
            isListState: true,
            isLoading: false,
            message: {},
            dates: {
                startDate: moment(new Date()).format('MM/DD/YYYY'),
                startTime: moment(new Date()).format('hh:mm'),
                endTime: moment(new Date()).format('hh:mm'),
                requestsFrom: '',
                requestsTo: '',
                apointmentsFrom: '',
                apointmentsTo: ''
            }
        };

        $scope.getViewModel = function () {
            $scope.data.isLoading = true;
            externalDataService.getAppointmentRequestViewModel($scope.data.token).then(function (response) {
                $scope.data.model = response.Data.Request;
                $scope.data.lookups = {
                    appointmentTypes: response.Lookups.AppointmentTypes,
                    offices: response.Lookups.Offices,
                    providers: response.Lookups.Providers,
                    selectedAppointmentType: {},
                    selectedOffice: {},
                    selectedProvider: {}
                };
            }).catch(function (responseError) {
                showMessage('danger', responseError, $timeout, $scope);
            }).finally(function () {
                $scope.data.isLoading = false;
            });
        };

        $scope.getAppointmentRequests = function () {
            $scope.data.isLoading = true;
            externalDataService.getAppointmentRequests($scope.data.token, $scope.data.dates.requestsFrom, $scope.data.dates.requestsTo).then(function (response) {
                $scope.data.prevRequests = response;
            }).catch(function (responseError) {
                showMessage('danger', responseError, $timeout, $scope);
            }).finally(function () {
                $scope.data.isLoading = false;
            });
        };

        $scope.getAppointments = function () {
            $scope.data.isLoading = true;
            externalDataService.getAppointments($scope.data.token, $scope.data.dates.apointmentsFrom, $scope.data.dates.apointmentsTo).then(function (response) {
                $scope.data.appointments = response;
            }).catch(function (responseError) {
                showMessage('danger', responseError, $timeout, $scope);
            }).finally(function () {
                $scope.data.isLoading = false;
            });
        };

        $scope.addRequest = function () {
            $scope.data.isListState = false;
        };

        $scope.saveChanges = function () {
            $scope.data.isLoading = true;
            $scope.data.model.AppointmentTypeId = $scope.data.lookups.selectedAppointmentType ? $scope.data.lookups.selectedAppointmentType.Id : null;
            $scope.data.model.OfficeId = $scope.data.lookups.selectedOffice ? $scope.data.lookups.selectedOffice.Id : null;
            $scope.data.model.ProviderId = $scope.data.lookups.selectedProvider ? $scope.data.lookups.selectedProvider.Id : null;
            $scope.data.model.StartTime = $scope.data.dates.startDate + ' 00:00';

            externalDataService.requestAppointment($scope.data.token, $scope.data.model).then(function (response) {
                $scope.getViewModel();
                showMessage('success', 'Request accepted.', $timeout, $scope);
            }).catch(function (responseError) {
                showMessage('danger', responseError, $timeout, $scope);
            }).finally(function () {
                $scope.data.isLoading = false;
                $scope.getAppointmentRequests();
            });
        };

        $('.date-item').datepicker({
            todayBtn: "linked",
            keyboardNavigation: false,
            forceParse: false,
            autoclose: true,
            format: "mm/dd/yyyy",
            orientation: "bottom auto",
            todayHighlight: true
        });

        $('.time-item').timepicker();

        $scope.getViewModel();

        $scope.$watchGroup(['data.dates.apointmentsFrom', 'data.dates.apointmentsTo'], function (newValue, oldValue, $scope) {
            $scope.getAppointments();
        });

        $scope.$watchGroup(['data.dates.requestsFrom', 'data.dates.requestsTo'], function (newValue, oldValue, $scope) {
            $scope.getAppointmentRequests();
        });
    }

    function showMessage(state, message, $timeout, $scope) {
        $scope.data.message = { state: state, message: message, show: true };
        $timeout(function () {
            $scope.data.message.show = false;
        }, 5000);
    }

    function GetSelectedValueById(values, id) {
        for (var i = 0; i < values.length; i++) {
            if (values[i].Id === id) {
                return values[i];
            }
        }
    }
})();