'use strict';

(function () {
    'use strict';

    angular.module('table.module').directive('pqTextFilter', textFilter);

    textFilter.$inject = ['$templateCache', '$compile'];

    function textFilter($templateCache, $compile) {
        return {
            restrict: "E",
            template: '<input type="{{viewModel.inputType}}" class="form-control input-sm" ng-model="viewModel.value" ng-blur="onBlur()" />',
            link: function link(scope, element, attrs) {
                scope.viewModel = {
                    value: scope.filter.values[0],
                    inputType: scope.filter.inputType
                };

                scope.onBlur = function () {
                    if (scope.viewModel.value != scope.filter.values[0]) {
                        scope.filter.values[0] = scope.viewModel.value;
                    }
                };
            }
        };
    }
})();