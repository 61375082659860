'use strict';

(function ($) {
    $.fn.removeClassByPrefix = function (prefix) {
        var self = this;

        self.each(function (i, el) {
            var regx = new RegExp('\\b' + prefix + '.* ?\\b', 'g');
            el.className = el.className.split(/\s+/).filter(function (cssClass) {
                return !regx.test(cssClass);
            }).join(' ');
        });

        return self;
    };

    $.fn.pqCompile = function ($scope) {
        var self = this;

        self.each(function (i, el) {
            var $injector = angular.injector(['ng', 'allMedsApp']);

            $injector.invoke(["$compile", "$rootScope", function ($compile, $rootScope) {
                $compile(el)($scope || $rootScope);
                $rootScope.$digest();
            }]);
        });

        return self;
    };

    $.fn.pqCompileWithNewScope = function () {
        var self = this;

        var $injector = angular.injector(['ng', 'allMedsApp']);

        var $scope = $injector.invoke(["$rootScope", function ($rootScope) {
            return $rootScope.$new();
        }]);

        self.each(function (i, el) {
            $injector.invoke(["$compile", "$rootScope", function ($compile, $rootScope) {
                $compile(el)($scope);
                $rootScope.$digest();
            }]);
        });

        return $scope;
    };
})(jQuery);