'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').factory('httpResponseInterceptor', httpResponseInterceptor);

    httpResponseInterceptor.$inject = ['$q', 'requestNotificationChannel'];

    function httpResponseInterceptor($q, notificationChannel) {
        return {
            request: function request(config) {
                if (config.params) config.params.nocache = Math.random().toString();

                notificationChannel.requestStarted();
                return config;
            },
            response: function response(_response) {
                notificationChannel.requestEnded();
                return _response || $q.when(_response);
            },
            responseError: function responseError(response) {
                notificationChannel.requestEnded();

                if (response.status == 500 && response.config.useGlobalErrorHandler !== false) {
                    showError(response.data);
                }

                return $q.reject(response);
            }
        };

        function showError(data) {
            if (!data) return null;

            var errorMessage = data.Message;

            if (data.InnerException) errorMessage += " (" + data.InnerException.Message + ")";

            var options = {
                isSystem: !data || data.IsBusinessLogicException != true
            };

            showErrorModal(errorMessage, options);
        }
    }
})();