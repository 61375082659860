'use strict';

(function () {
    'use strict';

    angular.module('external.module').component('pqPatientInfo', {
        bindings: {
            model: '<'
        },
        templateUrl: 'components/external/patientInfo.component/patientInfo.component.html',
        controller: PatientInfoController
    });

    PatientInfoController.$inject = ['externalDataService', '$scope', '$timeout'];

    function PatientInfoController(externalDataService, $scope, $timeout) {
        var $ctrl = this;
        $scope.data = {
            token: $ctrl.model.Token,
            isLoading: false,
            message: {}
        };

        $scope.getViewModel = function () {
            $scope.data.isLoading = true;
            externalDataService.getPatientInfoViewModel($scope.data.token).then(function (response) {
                $scope.data.model = response.Data;
                $scope.data.lookups = {
                    patientStatuses: response.Lookups.PatientStatuses,
                    nameSuffixes: response.Lookups.NameSuffixes,
                    namePrefixes: response.Lookups.NamePrefixes,
                    genders: response.Lookups.Genders,
                    races: response.Lookups.Races,
                    languages: response.Lookups.Languages,
                    maritalStatuses: response.Lookups.MaritalStatuses,
                    communicationOptions: response.Lookups.CommunicationOptions,
                    selectedPatientStatus: GetSelectedValueById(response.Lookups.PatientStatuses, response.Data.PatientStatusId),
                    selectedNameSuffix: GetSelectedValueById(response.Lookups.NameSuffixes, response.Data.NameSuffixId),
                    selectedNamePrefix: GetSelectedValueById(response.Lookups.NamePrefixes, response.Data.NamePrefixId),
                    selectedGender: GetSelectedValueById(response.Lookups.Genders, response.Data.GenderId),
                    selectedRace: GetSelectedValueById(response.Lookups.Races, response.Data.RaceId),
                    selectedLanguage: GetSelectedValueById(response.Lookups.Languages, response.Data.LanguageId),
                    selectedMaritalStatus: GetSelectedValueById(response.Lookups.MaritalStatuses, response.Data.MartialStatusId),
                    selectedCommunicationOption: GetSelectedValueById(response.Lookups.CommunicationOptions, response.Data.PortalCommunicationPreference)
                };
                $scope.data.model.DateOfBirth = moment($scope.data.model.DateOfBirth).format('MM/DD/YYYY');
                $scope.data.model.CreateDate = moment($scope.data.model.CreateDate).format('MM/DD/YYYY');
            }).catch(function (responseError) {
                showMessage('danger', responseError, $timeout, $scope);
            }).finally(function () {
                $scope.data.isLoading = false;
            });
        };

        $scope.saveChanges = function () {
            $scope.data.isLoading = true;
            $scope.data.model.PatientStatusId = $scope.data.lookups.selectedPatientStatus ? $scope.data.lookups.selectedPatientStatus.Id : null;
            $scope.data.model.NameSuffixId = $scope.data.lookups.selectedNameSuffix ? $scope.data.lookups.selectedNameSuffix.Id : null;
            $scope.data.model.NamePrefixId = $scope.data.lookups.selectedNamePrefix ? $scope.data.lookups.selectedNamePrefix.Id : null;
            $scope.data.model.GenderId = $scope.data.lookups.selectedGender ? $scope.data.lookups.selectedGender.Id : null;
            $scope.data.model.RaceId = $scope.data.lookups.selectedRace ? $scope.data.lookups.selectedRace.Id : null;
            $scope.data.model.LanguageId = $scope.data.lookups.selectedLanguage ? $scope.data.lookups.selectedLanguage.Id : null;
            $scope.data.model.MartialStatusId = $scope.data.lookups.selectedMaritalStatus ? $scope.data.lookups.selectedMaritalStatus.Id : null;
            $scope.data.model.PortalCommunicationPreference = $scope.data.lookups.selectedCommunicationOption ? $scope.data.lookups.selectedCommunicationOption.Id : null;

            externalDataService.savePatientData($scope.data.token, $scope.data.model).then(function (response) {
                $scope.getViewModel();
                showMessage('success', 'Successfully saved.', $timeout, $scope);
            }).catch(function (responseError) {
                showMessage('danger', responseError, $timeout, $scope);
            }).finally(function () {
                $scope.data.isLoading = false;
            });
        };

        $('#birth-date').datepicker({
            todayBtn: "linked",
            keyboardNavigation: false,
            forceParse: false,
            autoclose: true,
            format: "mm/dd/yyyy",
            orientation: "bottom auto",
            todayHighlight: true
        });

        $scope.getViewModel();
    }

    function showMessage(state, message, $timeout, $scope) {
        $scope.data.message = { state: state, message: message, show: true };
        $timeout(function () {
            $scope.data.message.show = false;
        }, 5000);
    }

    function GetSelectedValueById(values, id) {
        for (var i = 0; i < values.length; i++) {
            if (values[i].Id === id) {
                return values[i];
            }
        }
    }
})();