'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').component('pqPlannerParameters', {
        templateUrl: 'scheduler/plannerParameters.component/plannerParameters.component.html',
        controller: PlannerParametersController,
        bindings: {
            title: '<',
            showCommands: '<',
            options: '=',
            hasColorBlock: '<',
            onChanges: '&'
        }
    });

    function PlannerParametersController() {
        var $ctrl = this;

        $ctrl.$onInit = function () {};

        $ctrl.onCheckAll = function (isCheckAll) {
            $ctrl.options.forEach(function (option) {
                option.checked = isCheckAll;
            });
            $ctrl.onChanges();
        };
    }
})();