'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').component('schedulerNote', {
        templateUrl: 'scheduler/schedulerNote.component/schedulerNote.component.html',
        controller: SchedulerNoteController,
        bindings: {
            mode: '<',
            isLoading: '<',
            note: '<',
            providerId: '@',
            resourceId: '@',
            isProviderEnabled: '<',
            isResourceEnabled: '<'
        }
    });

    SchedulerNoteController.inject = ['$element', 'schedulerNoteDataService'];

    function SchedulerNoteController($element, schedulerNoteDataService) {
        var $ctrl = this;
        $ctrl.isVisible = false;
        var $element_nonEmptyNoteInfo = $element.find('.non-empty-note-info');

        $ctrl.$onChanges = function (changesObj) {
            update(changesObj.hasOwnProperty('note'));
        };

        $ctrl.openNote = function () {
            var currentDateString = schedulerNoteDataService.getParameters().currentDateString;
            var mode;
            if ($ctrl.providerId) {
                mode = 'provider';
            } else if ($ctrl.resourceId) {
                mode = 'resource';
            }

            var noteModalUrl;
            if (mode === 'provider') {
                noteModalUrl = '/SchedulerNote/Details/?Date=' + currentDateString + '&ProviderId=' + ($ctrl.providerId ? $ctrl.providerId : '');
            } else if (mode === 'resource') {
                noteModalUrl = '/SchedulerNote/Details/?Date=' + currentDateString + '&ResourceId=' + ($ctrl.resourceId ? $ctrl.resourceId : '');
            } else {
                noteModalUrl = '/SchedulerNote/Details/?Date=' + currentDateString;
            }

            showWafModal({
                url: noteModalUrl,
                actionHandler: function actionHandler(popupEventArgs) {
                    if (popupEventArgs.actionType === 'response' && popupEventArgs.response.Operation === 'Save' && popupEventArgs.response.IsSuccess) {

                        if (mode == 'provider') {
                            schedulerNoteDataService.refreshProviderNote(currentDateString, $ctrl.providerId);
                        } else if (mode = 'resource') {
                            schedulerNoteDataService.refreshResourceNote(currentDateString, $ctrl.resourceId);
                        } else {
                            schedulerNoteDataService.refreshNote(currentDateString);
                        }
                    }
                },
                cssClass: 'narrow'
            });
        };

        function update(isNoteChanged) {
            $ctrl.isVisible = $ctrl.providerId || $ctrl.resourceId ? ($ctrl.isProviderEnabled || $ctrl.isResourceEnabled) && $ctrl.mode === 'UnitDayView' : $ctrl.mode === 'UnitDayView' || $ctrl.mode === 'PatientFlow';

            if (isNoteChanged) {
                // clean up hint
                var hintApi = $element_nonEmptyNoteInfo.qtip('api');
                if (hintApi) {
                    hintApi.destroy();
                }

                var note = $ctrl.note;
                if (note) {
                    // add hint
                    $element_nonEmptyNoteInfo.qtip({
                        content: {
                            title: note.Subject,
                            text: note.Text ? note.Text.replace(/\n/g, '<br />') : ''
                        },
                        position: {
                            my: 'bottom center',
                            at: 'top center'
                        },
                        style: {
                            classes: 'qtip-light'
                        }
                    });
                }
            }
        }
    }
})();

var scheduler_ProviderNoteComponents = {};
var scheduler_ResourceNoteComponents = {};

function scheduler_InsertProviderNoteElement($container) {
    var providerId = $container.attr('data-provider-id');
    if (!providerId) {
        return;
    }

    var $element_providerNoteComponent = scheduler_ProviderNoteComponents[providerId];
    if (!$element_providerNoteComponent) {
        var code = '<scheduler-note' + ' mode="$ctrl.scheduler.mode"' + ' is-loading="$ctrl.schedulerNoteData.getOrInitProviderNoteRecord(' + providerId + ').isLoading"' + ' note="$ctrl.schedulerNoteData.getOrInitProviderNoteRecord(' + providerId + ').note"' + ' provider-id="' + providerId + '"' + ' is-provider-enabled="$ctrl.providerOptionsMap[' + providerId + '].checked">' + '</scheduler-note>"';

        var $injector = angular.element($container).injector();
        $injector.invoke(['$compile', function ($compile) {
            var $scope = angular.element($container).scope();
            $element_providerNoteComponent = $compile(code)($scope);
        }]);

        // add to cache
        scheduler_ProviderNoteComponents[providerId] = $element_providerNoteComponent;
    }

    $container.append($element_providerNoteComponent);
}

function scheduler_InsertResourceNoteElement($container) {
    var resourceId = $container.attr('data-resource-id');
    if (!resourceId) {
        return;
    }

    var $element_resourceNoteComponent = scheduler_ResourceNoteComponents[resourceId];
    if (!$element_resourceNoteComponent) {
        var code = '<scheduler-note' + ' mode="$ctrl.scheduler.mode"' + ' is-loading="$ctrl.schedulerNoteData.getOrInitResourceNoteRecord(' + resourceId + ').isLoading"' + ' note="$ctrl.schedulerNoteData.getOrInitResourceNoteRecord(' + resourceId + ').note"' + ' resource-id="' + resourceId + '"' + ' is-resource-enabled="$ctrl.resourceOptionsMap[' + resourceId + '].checked">' + '</scheduler-note>"';

        var $injector = angular.element($container).injector();
        $injector.invoke(['$compile', function ($compile) {
            var $scope = angular.element($container).scope();
            $element_resourceNoteComponent = $compile(code)($scope);
        }]);

        // add to cache
        scheduler_ResourceNoteComponents[resourceId] = $element_resourceNoteComponent;
    }

    $container.append($element_resourceNoteComponent);
}