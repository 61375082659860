'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('bhMarketingList', bhMarketingList);

    bhMarketingList.$inject = ['$uibModal', '$document'];

    function bhMarketingList($uibModal, $document) {
        return {
            restrict: 'A',
            link: function link(scope, element, attrs) {

                var isOlap = attrs.bhMarketingList == "olap";
                var isPatientSearch = attrs.bhMarketingList == "patient-search";

                element.off('click').on('click', function (e) {
                    e.preventDefault();

                    var modalInstance = $uibModal.open({
                        animation: false,
                        backdrop: 'static',
                        size: 'sm',
                        component: 'pqMarketingList',
                        resolve: {}
                    });

                    modalInstance.result.then(function (data) {
                        if (!data.marketingListId && !data.marketingListName) {
                            alert("One of field should be selected!");
                            return;
                        }

                        if (isOlap) {

                            var $pageContent = $('.report-designer').addClass('loading-container');

                            $.post('/Report/OlapDesignerGetMarketingList', { mdx: MdxToGetAllIds, marketingListId: data.marketingListId, marketingListName: data.marketingListName }).done(function (response) {
                                var $alert = createNotification(response.isSuccess, response.error, response.quantity, response.marketingListId, response.marketingListName);

                                $('.report-designer').prepend($alert);
                            }).always(function () {
                                $pageContent.removeClass('loading-container');
                            });
                        } else if (isPatientSearch) {
                            var url = 'Patient/AddToMarketingList';
                            var queryString = [];
                            if ($document[0].location.search) {
                                queryString = $document[0].location.search.substr(1).split('&');
                            }

                            if (data.marketingListId) {
                                queryString.push('addToMarketingListId=' + data.marketingListId);
                            } else {
                                queryString.push('addToMarketingListName=' + data.marketingListName);
                            }

                            url += '?' + queryString.join('&');

                            var $pageContent = element.parents('.page-content:first');

                            $pageContent.addClass('loading-container');

                            $.get(url).done(function (response) {

                                var $alert = createNotification(response.isSuccess, response.isSuccess ? null : response.error.message, response.data.quantity, response.data.marketingListId, response.data.marketingListName);

                                popup.showMessageModal({
                                    title: 'Adding the patients to marketing list result',
                                    message: $alert
                                });
                            }).always(function () {
                                $pageContent.removeClass('loading-container');
                            });
                        }
                    });
                });
            }
        };

        function createNotification(isSuccess, error, quantity, marketingListId, marketingListName) {
            var $alert = $('<div class="alert" role="alert"></div>');
            var $alertMessage = isSuccess ? quantity + ' patients added to marketing list <a href="MarketingList/Details/' + marketingListId + '" class="alert-link">' + marketingListName + '</a> successfully!' : error;
            $alert.append($alertMessage);
            $alert.addClass(isSuccess ? 'alert-success' : 'alert-danger');

            return $alert;
        }
    }
})();