'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

blocks.register('waf-action_Estimation-Details', function () {
    var $block;

    var estimationId;
    var lastEligibilityDate;

    //controls    
    var $inNetworkInput;

    //buttons
    var $calculateBtn;

    var $totalAllowedContainer;
    var $patientPartContainer;
    var $insuranceContainer;
    var $lastCalculationDateContainer;
    var $corporationField;

    var totalAllowedAmount;

    var inNetwork;

    var isInitialCalculationNeeded = true;

    var eligibilityServiceType;

    return {
        link: function link(block) {
            $block = block;

            reorganizeSummaryFieldsHack();

            setDefaultSummaryValues();

            estimationId = $block.find('.form-fields-panel').attr('data-primary-key');

            subscribeActions();

            var onEstimationLinesChanged = function onEstimationLinesChanged(e, data) {
                if (data.name == 'EstimationLines') {
                    if (data.$content.find('.search-result-table__row').length) {
                        var estimationLinesTotalAllowed;
                        data.$content.find('[name="AllowedTotalAmount"]').each(function () {
                            if (!estimationLinesTotalAllowed) estimationLinesTotalAllowed = 0.00;
                            estimationLinesTotalAllowed += parseCurrency($(this).val());
                        });
                        data.$content.find('.total-row .total.allowed').text(estimationLinesTotalAllowed).formatCurrency();

                        var estimationLinesTotalBilled;
                        data.$content.find('[name="BilledTotalAmount"]').each(function () {
                            if (!estimationLinesTotalBilled) estimationLinesTotalBilled = 0.00;
                            estimationLinesTotalBilled += parseCurrency($(this).val());
                        });
                        data.$content.find('.total-row .total.billed').text(estimationLinesTotalBilled).formatCurrency();

                        totalAllowedAmount = estimationLinesTotalAllowed;

                        if (totalAllowedAmount != undefined && isInitialCalculationNeeded) {
                            showCalculation();
                        }
                    } else {
                        totalAllowedAmount = undefined;
                        hideCalculation();
                    }

                    isInitialCalculationNeeded = false;
                }
            };

            $("html").on("waf-linked-search-loaded", onEstimationLinesChanged);

            if (!$corporationField.val()) {
                $.get('Estimation/CorporationSelector').done(function (response) {

                    var $modal = showModalDynamicForm(response.Content, { title: 'Select Corporation', cssClass: 'collection-add-visit-popup' });
                    var $form = $modal.find('form');
                    var $submitBtn = $form.find('.submit-button');
                    $submitBtn.text('Select');
                    $submitBtn.attr('disabled', 'disabled');
                    var corporationHolder = $form.find('[name$="_CorporationId"]');
                    $submitBtn.off().on('click', function (e) {

                        waf_SetBlockLoading($form, true);

                        if ($form.valid()) {

                            $.post('/Estimation/UpdateEstimationCorporation', {
                                estimationId: estimationId,
                                corporationId: corporationHolder.select2('val')
                            }).done(function () {

                                $corporationField.parent().find('.readonly-value').text(corporationHolder.select2('data').text);

                                popup.closeOverlay($modal);
                            }).always(function () {
                                waf_SetBlockLoading($form, false);
                            });
                        }
                    });

                    corporationHolder.on('change', function (e) {
                        if (corporationHolder.select2('val')) {
                            $submitBtn.removeAttr('disabled');
                        } else {
                            $submitBtn.attr('disabled', 'disabled');
                        }
                    });
                });
            }
        }
    };

    function reorganizeSummaryFieldsHack() {
        var summaryFieldRow = $('[data-group="Summary"] .row:first', $block);

        var summarySecondGroup = $('[data-group="SummarySecond"]', $block);
        var summarySecondFields = $('.row > div', summarySecondGroup).removeClass('col-sm-3').addClass('col-sm-1');
        summaryFieldRow.append(summarySecondFields);
        summarySecondGroup.remove();
        $('.field-container', summaryFieldRow).attr('data-label-position', 'Top').filter('[data-id$="_Patient"]').parent();

        var summaryFields = $('> div', summaryFieldRow);
        summaryFields.removeClass("col-sm-4").addClass('col-sm-1');
        summaryFields.find('.control-label').each(function (idx, el) {
            el.innerText = el.innerText.replace(':', '');
        });
        summaryFields.each(function (idx, el) {
            $(el).addClass('col-' + ++idx);
        });

        $totalAllowedContainer = summaryFields.find('[name$="_Allowed"]').parents('.field-control-container:first');
        $patientPartContainer = summaryFields.find('[name$="_PatientPart"]').parents('.field-control-container:first');
        $insuranceContainer = summaryFields.find('[name$="_Insurance"]').parents('.field-control-container:first');
        $lastCalculationDateContainer = summaryFields.find('[name$="_LastCalculationDate"]').parents('.field-control-container:first');
        $corporationField = summaryFields.find('[name$="_CorporationId"]');
    }

    function setDefaultSummaryValues() {
        //updateReadonly($totalAllowedContainer, '--');
        updateReadonly($patientPartContainer, '--');
        updateReadonly($insuranceContainer, '--');
    }

    function showCalculation(checkEligibility) {
        //show modal only if this appt is not in queue
        $block.addClass("loading-container");

        var link = ['/Estimation/CalculationDetail?Id=', estimationId, "&checkEligibility=", checkEligibility, '&inNetwork=', inNetwork ? inNetwork == "1" : null, '&eligibilityServiceTypeId=', eligibilityServiceType].join('');

        return $.ajax({
            type: 'GET',
            url: link
        }).done(function (data) {
            if (data) {

                if ((typeof data === 'undefined' ? 'undefined' : _typeof(data)) == "object" && data.eligibilityCheckFailed) {
                    showManualEntry(true);
                } else {
                    var $calculationArea = $block.find('.estimator-calculation-area');
                    if (!$calculationArea.length) {
                        var $area = $block.find('.form-linked-records-areas');
                        $calculationArea = $('<div class="estimator-calculation-area"></div>').insertAfter($area);
                        $calculationArea.html(data);
                    } else {
                        $calculationArea.html(data);
                    }

                    var patientPart = $calculationArea.find('#patient-part').val();
                    var insurance = $calculationArea.find('#insurance').val();
                    var calculationDate = $calculationArea.find('#LastCalculationDate').val();

                    updateReadonlyCurrency($totalAllowedContainer, totalAllowedAmount);
                    updateReadonlyCurrency($patientPartContainer, patientPart);
                    updateReadonlyCurrency($insuranceContainer, insurance);
                    updateReadonly($lastCalculationDateContainer, calculationDate);

                    inNetwork = $calculationArea.find('.js-in-network.active input').val();

                    var $eligibilityServiceType = $calculationArea.find('input[name=EligibilityServiceTypeId]');
                    var options = $eligibilityServiceType.data('options');

                    $eligibilityServiceType.select2({
                        data: options,
                        allowClear: false
                    });

                    eligibilityServiceType = $eligibilityServiceType.val();
                }
            }
        }).always(function () {
            $block.removeClass("loading-container");
        });
    }

    function hideCalculation() {
        var $calculationArea = $block.find('.estimator-calculation-area');
        if ($calculationArea.length) {
            $calculationArea.empty();
        }
    }

    function showManualEntry(eligibilityFailed) {
        showWafModal({
            url: '/Estimation/ManualEntry/' + estimationId + '?_eligibilityFailed=' + eligibilityFailed,
            actionHandler: function actionHandler(popupEventArgs) {
                if (popupEventArgs.actionType == 'response' && popupEventArgs.response && popupEventArgs.response.IsSuccess && popupEventArgs.response.Operation == 'Save') {
                    showCalculation(false);
                }
            },
            cssClass: 'wide'
        });
    }

    function updateReadonlyCurrency($container, value) {
        updateReadonly($container, value, $.fn.formatCurrency);
    }

    function updateReadonly($container, value, formatFunc) {
        $container.find('input').val(value);

        var readOnly = $container.find('.readonly-value:first');
        readOnly.text(value);

        if (formatFunc) {
            formatFunc.call(readOnly);
        }
    }

    function subscribeActions() {
        $block.on('click', '#js-eligibility-check', function () {
            showCalculation(true);
        });
        $block.on('click', '#js-manual-entry', showManualEntry);
        $block.on('click', '.js-in-network', function (e) {
            inNetwork = $(e.currentTarget).find('input').val();
            showCalculation();
        });
        $block.on('change', 'input[name=EligibilityServiceTypeId]', function (e) {
            eligibilityServiceType = $(e.currentTarget).val();
        });

        $block.find('[data-command-name="calculate"]').removeClass('btn-default').addClass('btn-primary').off().on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            if (totalAllowedAmount == undefined) {
                popup.showAlertModal("Please, enter at least one estimation line", closeBlock);
            } else {
                showCalculation();
            }
        });

        $block.find('[data-command-name="print"]').off().on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            $('body').css('visibility', 'hidden');
            print();
            $('body').css('visibility', 'visible');
        });

        $block.find('[data-command-name="Delete"]').off().on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            var currentBtn = this;
            popup.showConfirmModal("Are you sure you want to delete this estimation?", function (e) {

                $.post("/Estimation/DeleteEstimation", { estimationId: estimationId }).done(function (response) {
                    if (!response.IsSuccess) {
                        popup.showErrorModal(response.ErrorText);
                    } else {
                        popup.closeOverlay($(currentBtn).closest(".popup_content"));
                    }
                });
            });
        });
    }
});