'use strict';

blocks.register("waf-action_EstimationLine-EstimationLines", function () {
    var $block;

    //buttons
    var $addButton;
    var $addByReason;

    //inputs
    var $providerInput;
    var $insuranceInput;
    var $reasonSelect;

    return {
        link: function link(block) {
            $block = block;

            reorganizeTotal();

            $providerInput = $('[id=Details_R1_Provider]');

            waitForElementToDisplay('[id=Details_R1_PatientInsurance]', 100);
        }
    };

    function waitForElementToDisplay(selector, time) {
        if ($(selector).length) {
            $insuranceInput = $('[id=Details_R1_PatientInsurance]');
            $addButton = $block.find('[data-command-name="New"]');
            $addByReason = $block.find('[data-command-name="Import"]');

            $providerInput.change(addProviderToLink);
            $insuranceInput.change(addProviderToLink);

            initAddByReason();

            //$("html").on("waf-linked-search-loaded", function (e, data) {
            //    if (data.name === "EstimationLines") {
            addProviderToLink();
            //    }
            //});
        } else {
            setTimeout(function () {
                waitForElementToDisplay(selector, time);
            }, time);
        }
    }

    function reorganizeTotal() {
        var $tbody = $('.search-result-table > tbody', $block);
        if ($tbody.length) {
            var $totalRow = $('<tr class="total-row"><td colspan="6"></td><td class="total billed"></td><td class="total allowed"></td></tr>');

            var $menuCmdPanel = $('.menu-commands-panel', $block);

            $('td:first', $totalRow).append($menuCmdPanel.children());

            $menuCmdPanel.remove();

            $('.search-result-table > tbody', $block).append($totalRow);
        }
    }

    function initAddByReason() {
        var visitId = $block.parents('form').find('[id$=VisitIdParam]').val();

        $.get('Estimation/GetApptReasons', { visitId: visitId }).done(function (data) {
            $reasonSelect = $('<input type="text" class="appt-reasons">');
            $addButton.after($reasonSelect);

            data = $.map(data, function (val) {
                return { id: val.Id, text: val.DisplayValue };
            });

            $reasonSelect.select2({
                data: data,
                placeholder: "Add by Appt. Reason",
                allowClear: true,
                minimumResultsForSearch: -1
            });

            $reasonSelect.on('change', $addByReason);
        });

        var channel = new Channel('estimation');

        function $addByReason(e) {
            var fk = $block.parent().attr('data-foreign-key-value');
            var reasonId = $reasonSelect.val();

            $block.addClass('loading-container');

            $.post('Estimation/AddLinesByApptReason', {
                estimationId: fk,
                apptReasonId: reasonId
            }).done(function (data) {
                channel.trigger('refresh');
            }).always(function () {
                $block.removeClass('loading-container');
                $reasonSelect.select2("val", "");
            });

            e.preventDefault();
        };
    }

    function addProviderToLink() {
        var $detailsLinks = $block.find('[data-navigate-field="true"]');

        updateLink($addButton);

        $detailsLinks.each(function () {
            updateLink($(this));
        });
    }

    function updateLink($elem) {
        var link = $elem.attr('href').split("&_providerId")[0];
        var devider = link.indexOf("?") === -1 ? "?" : "&";

        link += devider + '_providerId=' + $providerInput.val() + '&_insuranceId=' + $insuranceInput.val();
        $elem.attr('href', link);

        return $elem;
    }
});