'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').component('pqPlanner', {
        templateUrl: 'scheduler/planner.component/planner.component.html',
        controller: PlannerController
    });

    PlannerController.$inject = ['$scope', '$http', '$timeout', '$window', 'pqHelpers', 'scheduleEventViewModel', 'scheduleEventDataService', 'scheduleEventSettingsService', 'plannerHelpers', '$document'];

    function PlannerController($scope, $http, $timeout, $window, pqHelpers, scheduleEventViewModel, scheduleEventDataService, scheduleEventSettingsService, plannerHelpers, $document) {
        var $ctrl = this;

        $ctrl.$onInit = function () {
            var schedulerStoredSettings = $window.userSession.get('schedulerSettings');
            $ctrl.componentLoading = true;
            $ctrl.isPlannerFiltersExpanded = schedulerStoredSettings && schedulerStoredSettings.hasOwnProperty('isPlannerFiltersExpanded') ? schedulerStoredSettings.isPlannerFiltersExpanded : true;
            $ctrl.patientSearch = {};

            var mode = schedulerStoredSettings && schedulerStoredSettings.mode || 'UnitDayView';

            $ctrl.schedulerViewModel = $scope.schedulerViewModel = {
                mode: mode,
                startDate: new Date(),
                endDate: new Date(),
                isSingleUnitMode: plannerHelpers.isSingleUnitMode(mode)
            };

            var params = pqHelpers.getQueryStringParams();
            scheduleEventViewModel.getViewModel(params).then(function (viewModel) {
                scheduler.config.drag_move = viewModel.allowDragAndDropInScheduler;

                $ctrl.viewModel = $scope.viewModel = viewModel;

                $ctrl.viewModel.selectedUnits = getCheckedUnits();

                if ($ctrl.schedulerViewModel.isSingleUnitMode && $ctrl.viewModel.selectedUnits.length > 1) {
                    $ctrl.viewModel.selectedUnits.forEach(function (u, i) {
                        if (i > 0) {
                            u.checked = false;
                        }
                    });

                    $ctrl.viewModel.selectedUnits = [$ctrl.viewModel.selectedUnits[0]];
                }

                checkOnSelectedEvent($ctrl.viewModel.selectedEvent);
                checkOnQueueMode($ctrl.viewModel);

                if (params.portalRequestId) {
                    viewModel.lists.providerOptions.forEach(function (n) {
                        n.checked = true;
                    });
                    viewModel.lists.officeOptions.forEach(function (n) {
                        n.checked = true;
                    });

                    adaptFiltersForEvent(viewModel.lists.providerOptions[0].id, viewModel.lists.officeOptions[0].id, null, true);
                }
                $ctrl.refreshData();
            }).finally(function () {
                $ctrl.componentLoading = false;
            });
        };

        var isRefreshingData = false;
        $ctrl.refreshData = function () {
            if (!isRefreshingData && !$ctrl.viewModel.overriding) {
                isRefreshingData = true;
                $timeout(function () {
                    refreshSchedulerEvents();
                    isRefreshingData = false;
                });
            }
        };

        $ctrl.refreshPatients = function (searchTerm) {
            if (!searchTerm) {
                $ctrl.patientSearch.patients = [];
                return;
            }
            var params = { _tab: 'Patient', _sea: 'SuggestSearchWithAdditionalFields', term: searchTerm, Appointment: 19 };
            return $http.get('/WafCommon?_act=Suggest&_=1432226543681', { params: params }).then(function (response) {
                $ctrl.patientSearch.patients = response.data;
            });
        };

        $ctrl.selectPatient = function (item, patientId) {
            if (patientId) {
                $window.location = '/Appointment/Search?Patient=' + patientId;
            }
        };

        $ctrl.collapseFilters = function (e) {
            e.stopPropagation();
            $ctrl.isPlannerFiltersExpanded = !$ctrl.isPlannerFiltersExpanded;
            $timeout(function () {
                scheduler.updateView();
            }); //refresh scheduler height

            var schedulerSettings = $window.userSession.get('schedulerSettings');
            schedulerSettings.isPlannerFiltersExpanded = $ctrl.isPlannerFiltersExpanded;
            $window.userSession.set('schedulerSettings', schedulerSettings);
        };

        $ctrl.isSingleUnitMode = function () {
            return plannerHelpers.isSingleUnitMode($ctrl.schedulerViewModel.mode);
        };

        $ctrl.onCheckUnit = function (unit) {
            if (!unit) {
                $ctrl.viewModel.selectedUnits = getCheckedUnits();
            } else if ($ctrl.isSingleUnitMode()) {
                if ($ctrl.viewModel.selectedUnits[0]) {
                    $ctrl.viewModel.selectedUnits[0].checked = false;
                    $ctrl.viewModel.selectedUnits = [];
                }
                if (unit.checked) {
                    $ctrl.viewModel.selectedUnits = [unit];
                }
            } else {
                $ctrl.viewModel.selectedUnits = getCheckedUnits();
            }

            $ctrl.refreshData();
        };

        $ctrl.onCheckOffice = function (callback) {
            scheduleEventDataService.getParametersByLocation({
                officeIds: $ctrl.viewModel.lists.officeOptions.filter(function (c) {
                    return c.checked;
                }).map(function (c) {
                    return c.id;
                })
            }).then(function (data) {
                $ctrl.viewModel.lists.providerOptions = plannerHelpers.choiceUnits(data.providers, $ctrl.viewModel.lists.providerOptions);
                $ctrl.viewModel.lists.resourceOptions = plannerHelpers.choiceUnits(data.resources, $ctrl.viewModel.lists.resourceOptions);
                $ctrl.viewModel.selectedUnits = getCheckedUnits();
                if ($ctrl.viewModel.selectedUnits.length === 0) {
                    var units = $ctrl.viewModel.lists.providerOptions.concat($ctrl.viewModel.lists.resourceOptions);
                    if (units && units.length > 0) {
                        units[0].checked = true;
                        $ctrl.viewModel.selectedUnits.push(units[0]);
                    }
                }
                $ctrl.refreshData();

                if (callback) {
                    callback();
                }
            });
        };

        $ctrl.onCheckAppointmentType = function () {
            saveFilterSettings(plannerHelpers.getFilters($ctrl.viewModel.lists));
            refreshFilteredEvents();
        };

        $ctrl.getRequestParams = function () {
            return {
                filters: plannerHelpers.getFilters($ctrl.viewModel.lists),
                appointmentTypes: plannerHelpers.filterAndSelectIds($ctrl.viewModel.lists.appointmentTypes)
            };
        };

        $ctrl.getCurrentState = function () {
            var stateParams = angular.merge({}, plannerHelpers.getAllFilters($ctrl.viewModel.lists), {
                mode: $ctrl.schedulerViewModel.mode,
                startDate: $ctrl.schedulerViewModel.startDate,
                endDate: $ctrl.schedulerViewModel.endDate
            });
            return stateParams;
        };

        $ctrl.updateState = function (stateParams) {
            $ctrl.schedulerViewModel.mode = stateParams.mode;
            $ctrl.schedulerViewModel.startDate = stateParams.startDate;
            $ctrl.schedulerViewModel.endDate = stateParams.endDate;
            plannerHelpers.checkFilterByIds(stateParams.officeIds, $ctrl.viewModel.lists.officeOptions);
            plannerHelpers.checkFilterByIds(stateParams.providerIds, $ctrl.viewModel.lists.providerOptions);
            plannerHelpers.checkFilterByIds(stateParams.resourceIds, $ctrl.viewModel.lists.resourceOptions);
            plannerHelpers.checkFilterByIds(stateParams.appointmentTypeIds, $ctrl.viewModel.lists.appointmentTypes);

            $ctrl.viewModel.selectedUnits = getCheckedUnits();
            scheduler.setCurrentView(new Date(stateParams.startDate), stateParams.mode);
            $ctrl.refreshData();
        };

        $document.on('scheduleEvent:backgroundSubmit', function () {
            restoreFilters();$scope.$broadcast('scheduleEvent:backgroundSubmit');
        });
        $document.on('scheduleEvent:closePopup', function () {
            $scope.$broadcast('scheduleEvent:closePopup');
        });

        $ctrl.getSearchSlotMessage = function () {
            var searchTypeStr = $ctrl.viewModel.searchSlotParams.searchedNextSlotId ? 'Template' : $ctrl.viewModel.searchSlotParams.markedTimespanId ? 'Open' : '';
            return searchTypeStr + ' Slot is searching now';
        };

        $ctrl.stopSearchingSlot = function () {
            $scope.$broadcast('searchSlot:stop');
        };

        $scope.$watch(function () {
            return moment($ctrl.schedulerViewModel.startDate).format('L') + ' ' + moment($ctrl.schedulerViewModel.endDate).format('L');
        }, function (nv, ov) {
            if (nv != ov) {
                $ctrl.refreshData();
            }
        }, true);

        $ctrl.getVisitStatusImgUrl = function (systemName) {
            return 'content/pages/schedule-event/images/statuses/' + systemName + '-legend@2x.png';
        };

        function refreshSchedulerEvents() {
            $ctrl.schedulerViewModel.loading = true;
            var params = {
                filters: plannerHelpers.getFilters($ctrl.viewModel.lists),
                startDate: moment($ctrl.schedulerViewModel.startDate).format('L'),
                endDate: moment($ctrl.schedulerViewModel.endDate).format('L'),
                includes: plannerHelpers.getIncludesByMode($ctrl.schedulerViewModel.mode)
            };

            if (!plannerHelpers.isQueueMode($ctrl.viewModel) && !isPredefinedAppt($ctrl.viewModel.selectedEvent)) {
                saveFilterSettings(params.filters);
            }

            scheduleEventDataService.getData(params).then(function (data) {
                if (data.nonWorkingTimes && $ctrl.schedulerViewModel.mode == 'month') {
                    if (!data.events) {
                        data.events = [];
                    }
                    data.nonWorkingTimes.forEach(function (time) {
                        data.events.push({
                            id: Math.ceil(Math.random() * 10000),
                            metaTypeId: 1,
                            start_date: moment(time.start_date).toDate(),
                            end_date: moment(time.end_date).toDate()
                        });
                    });
                }

                $ctrl.viewModel.events = data.events;
                refreshFilteredEvents();
                scheduler.deleteMarkedTimespan();

                if (data.workingTimes) {
                    $ctrl.viewModel.workingTimes = data.workingTimes;
                    scheduleEventSettingsService.addWorkingTimes(data.workingTimes, $ctrl.schedulerViewModel.mode);
                }
                if (data.nonWorkingTimes) {
                    scheduleEventSettingsService.addNonWorkingTimes(data.nonWorkingTimes, $ctrl.schedulerViewModel.mode);

                    if ($ctrl.viewModel.searchSlotParams.foundOpenSlotMarkTimespan) {
                        $ctrl.viewModel.searchSlotParams.markedTimespanId = scheduler.addMarkedTimespan($ctrl.viewModel.searchSlotParams.foundOpenSlotMarkTimespan);
                        delete $ctrl.viewModel.searchSlotParams.foundOpenSlotMarkTimespan;
                    }
                }
            }).finally(function () {
                $ctrl.schedulerViewModel.loading = false;
            });
        }

        // show popup if there is selectedEvent
        function checkOnSelectedEvent(selectedEvent) {
            if (!selectedEvent) {
                return;
            }

            var firstTime = true;
            storeFilterCopy();
            adaptFiltersForEvent(selectedEvent.providerId, selectedEvent.officeId, selectedEvent.appointmentTypeId, isPredefinedAppt(selectedEvent));

            if (!selectedEvent.id || selectedEvent.id === 0) {
                refreshSchedulerEvents();
                $ctrl.viewModel.restoreFilters = restoreFilters;
                $ctrl.viewModel.officeId = selectedEvent.officeId;
                $ctrl.viewModel.overriding = false;
                return;
            }

            $scope.$watch("viewModel.events", function (events) {
                if (events && firstTime) {
                    scheduler.showLightbox(selectedEvent.id, true);
                    scheduler.setCurrentView(new Date(selectedEvent.start_date));
                    firstTime = false;
                }
            });
        }

        function storeFilterCopy() {
            $ctrl.viewModel.selectedFiltersCopy = plannerHelpers.getAllFilters($ctrl.viewModel.lists);
        }

        function restoreFilters() {
            $ctrl.viewModel.overriding = false;
            if ($ctrl.viewModel.selectedFiltersCopy) {
                if (plannerHelpers.isSingleUnitMode($ctrl.schedulerViewModel.mode)) {
                    if ($ctrl.viewModel.selectedFiltersCopy.providerIds && $ctrl.viewModel.selectedFiltersCopy.providerIds.length > 1) {

                        $ctrl.viewModel.selectedFiltersCopy.providerIds = $ctrl.viewModel.selectedFiltersCopy.providerIds.splice(0, 1);
                    }
                }

                $ctrl.viewModel.officeId = null;
                plannerHelpers.checkFilterByIds($ctrl.viewModel.selectedFiltersCopy.officeIds, $ctrl.viewModel.lists.officeOptions);
                $ctrl.onCheckOffice(function () {
                    plannerHelpers.checkFilterByIds($ctrl.viewModel.selectedFiltersCopy.providerIds, $ctrl.viewModel.lists.providerOptions);
                    $ctrl.viewModel.selectedUnits = getCheckedUnits();
                });
                $ctrl.refreshData();
            }
        }

        function checkOnQueueMode(viewModel) {
            if (plannerHelpers.isQueueMode(viewModel)) {
                storeFilterCopy();
                var queue = plannerHelpers.getQueueInfo(viewModel);
                if (queue.providerId || queue.officeId) {
                    adaptFiltersForEvent(queue.providerId, queue.officeId, queue.appointmentTypeId, true);
                }
            }
        }

        function adaptFiltersForEvent(providerId, officeId, appointmentTypeId, override) {
            plannerHelpers.onCheckFilterById(providerId, $ctrl.viewModel.lists.providerOptions, override);
            plannerHelpers.onCheckFilterById(officeId, $ctrl.viewModel.lists.officeOptions, override);
            plannerHelpers.onCheckFilterById(appointmentTypeId, $ctrl.viewModel.lists.appointmentTypes);

            $ctrl.viewModel.selectedUnits = getCheckedUnits();
        }

        function refreshFilteredEvents() {
            $ctrl.viewModel.filteredEvents = filterEvents($ctrl.viewModel.events, $ctrl.schedulerViewModel.mode != 'PatientFlow');
        }

        function filterEvents(events, isCancelled) {
            if (!events) {
                return null;
            }

            var appointmentTypeIds = plannerHelpers.filterAndSelectIds($ctrl.viewModel.lists.appointmentTypes),
                officeIds = plannerHelpers.filterAndSelectIds($ctrl.viewModel.lists.officeOptions);

            return events.filter(function (event) {
                return event.metaTypeId !== 0 || event.appointmentTypeId && appointmentTypeIds.indexOf(event.appointmentTypeId) !== -1 && ( // by selected types                        
                officeIds.length === 0 || officeIds.indexOf(event.officeId) !== -1) && ( // by selected offices                        
                isCancelled ? event.appointmentStatusId !== $ctrl.viewModel.cancelledAppointmentStatusId : true); // not cancelled
            });
        }

        function saveFilterSettings(filters) {
            var settings = angular.merge({}, filters, {
                appointmentTypeIds: plannerHelpers.filterAndSelectIds($ctrl.viewModel.lists.appointmentTypes)
            });
            scheduleEventDataService.saveFilterSettings(settings);
        }

        function getCheckedUnits() {
            return $ctrl.viewModel.lists.providerOptions.concat($ctrl.viewModel.lists.resourceOptions).filter(function (c) {
                return c.checked;
            });
        }

        function isPredefinedAppt(selectedEvent) {
            return selectedEvent && (selectedEvent.providerId || selectedEvent.officeId);
        }
    }
})();