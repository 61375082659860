'use strict';

(function () {
    'use strict';

    angular.module('table.module').component('pqTable', {
        bindings: {
            model: '='
        },
        scope: true,
        templateUrl: 'components/table/table.component/table.component.html',
        controller: TableController
    });

    TableController.$inject = ['tableDataService', '$scope', '$interval', '$timeout', '$q'];

    function TableController(tableDataService, scope, $interval, $timeout, $q) {
        var $ctrl = this;

        var timeoutDefer = null;

        $ctrl.showAdvancedTools = false;

        $ctrl.$onDestroy = function () {
            if (timeoutDefer) {
                timeoutDefer.resolve();
            }
        };

        $ctrl.selectedRecords = [];

        $ctrl.$onInit = function () {
            $ctrl.tableInfo = {};
            $ctrl.refresh(true);
            $ctrl.totalItemNumber = null;
        };

        $ctrl.refresh = function (firstTime, searchId, resetPaging) {
            if (resetPaging && $ctrl.resetPaging) $ctrl.resetPaging();

            $ctrl.error = null;
            $ctrl.isLoading = true;

            var defer = $q.defer();

            $timeout(function () {
                var request = {
                    action: $ctrl.model.action,
                    data: {
                        metadata: $ctrl.tableInfo.metadata ? $ctrl.tableInfo.metadata : null,
                        filters: $ctrl.tableInfo.filters ? $ctrl.tableInfo.filters : null,
                        sorting: $ctrl.tableInfo.sorting || null,
                        isInitial: firstTime
                    }
                };

                tableDataService.getViewModel(request, createTimeoutPromise()).then(function (response) {
                    if (response.metadata) {
                        $ctrl.tableInfo = response;
                        defer.resolve(response);
                    } else {
                        defer.resolve();
                    }
                }).catch(function (responseError) {
                    $ctrl.error = responseError;

                    if (resetPaging && $ctrl.restorePagingOnFail) $ctrl.restorePagingOnFail();

                    defer.reject(responseError);
                }).finally(function () {
                    $ctrl.isLoading = false;
                });
            });

            return defer.promise;
        };

        $ctrl.getTotalItems = function () {
            $ctrl.error = null;
            $ctrl.isLoading = true;

            var request = {
                action: $ctrl.tableInfo.metadata.paging.totalItemUrl,
                data: {
                    filters: $ctrl.tableInfo.filters ? $ctrl.tableInfo.filters : null
                }
            };

            return tableDataService.getViewModel(request).then(function (response) {
                return response;
            }).catch(function (responseError) {
                $ctrl.error = responseError;
            }).finally(function () {
                $ctrl.isLoading = false;
            });
        };

        function createTimeoutPromise() {
            if (timeoutDefer) {
                timeoutDefer.resolve();
                timeoutDefer = null;
            }

            timeoutDefer = $q.defer();

            return timeoutDefer.promise;
        }
    }
})();