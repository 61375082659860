'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('messageHint', messageHintDirective);

    messageHintDirective.$inject = ['$document', '$templateCache', '$compile'];

    function messageHintDirective($document, $templateCache, $compile) {

        return {
            restrict: 'A',
            scope: {
                model: '='
            },
            link: function link(scope, $element, attrs) {

                $element.on('mouseenter', function () {
                    // add handler
                    $element.qtip({
                        content: {
                            text: function text(event, api) {

                                showHint();
                                scope.$apply();

                                function showHint() {
                                    var templateScope = scope.$new(true);
                                    templateScope.model = scope.model;

                                    var templateHtml = $templateCache.get('fields/messageHint.directive/messageHintBubble.tmpl.html');
                                    var $compiledTemplate = $compile(templateHtml)(templateScope);

                                    setTimeout(function () {
                                        api.set({
                                            //'content.title': addressModel.isSuccess ? 'Address' : 'Error',
                                            'content.text': $compiledTemplate
                                        });
                                    }, 0);
                                }
                            }
                        },
                        position: {
                            my: 'bottom center',
                            at: 'top center',
                            viewport: $(window),
                            effect: false // effect removed because sizes of loading and content are very different (looks not nice)
                        },
                        show: {
                            ready: true,
                            solo: true,
                            delay: 300 // todo: move into global config
                        },
                        hide: {
                            event: 'mouseleave mousedown'
                        },
                        style: {
                            classes: 'qtip-light ' + scope.model.classes,
                            'max-width': 50,
                            width: 600

                        },
                        events: {
                            hide: function hide(event, api) {
                                // scheduler creates new DOM elements for events very often (on each resize, mode/time change, etc.)
                                // so the simplest way to clean up qTips is to destroy them right after hide
                                api.destroy();
                            }
                        }
                    });
                });

                return;
            }
        };
    }
})();