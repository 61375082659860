"use strict";

(function () {
    "use strict";

    var confirmedByAudit = function confirmedByAudit() {
        return {
            restrict: "EA",
            replace: true,
            templateUrl: "control.directives/old/schedule-event/confirmed.by.audit.directive.tpl.html",
            scope: {
                user: "=",
                time: "="
            },

            link: function link($scope) {
                $scope.popoverTemplateUrl = "control.directives/old/schedule-event/confirmed.by.audit.directive.popover.tpl.html";
            }
        };
    };

    angular.module("shared.directives").directive("confirmedByAudit", confirmedByAudit);
})();