'use strict';

(function () {
    'use strict';

    angular.module('fields.module').component('pqTextField', {
        bindings: {
            field: '<'
        },
        template: '<span ng-bind="::$ctrl.field.value"></span>'
    });
})();