'use strict';

(function () {
    'use strict';

    angular.module('fields.module').component('pqNavigateField', {
        bindings: {
            field: '<'
        },
        template: '<a ng-href="{{::$ctrl.field.url}}" ng-bind="::$ctrl.field.value"></a>'
    });
})();