'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').factory('scheduleTemplateDataService', scheduleTemplateDataService);

    scheduleTemplateDataService.$inject = ['$http', '$resource'];

    function scheduleTemplateDataService($http, $resource) {
        var scheduleTemplateResource = $resource('/ScheduleTemplate/:action', null, {
            getViewModel: { method: 'Get', params: { action: 'planner' } },
            getData: { method: 'Get', params: { action: 'data' } },
            applyCheckedDays: { method: 'Post', params: { action: 'applyTemplate' } },
            applicationsByProvider: { method: 'Post', params: { action: 'applicationsByProvider' } },
            deleteAppliedSlots: { method: 'Post', params: { action: 'deleteAppliedSlots' } },
            extendAppliedSlots: { method: 'Post', params: { action: 'extendAppliedSlots' } },
            copyDay: { method: 'Post', params: { action: 'copyDay' } }
        });

        return {
            getViewModel: function getViewModel() {
                return scheduleTemplateResource.getViewModel({}).$promise;
            },
            getData: function getData(scheduleTemplateId) {
                return scheduleTemplateResource.getData({
                    scheduleTemplateId: scheduleTemplateId
                }).$promise;
            },
            getApplicationsByProvider: function getApplicationsByProvider(scheduleTemplateId, providerId) {
                return scheduleTemplateResource.applicationsByProvider({ scheduleTemplateId: scheduleTemplateId, providerId: providerId });
            },
            copyDay: function copyDay(scheduleTemplateId, fromDay, toDays) {
                return scheduleTemplateResource.copyDay({ scheduleTemplateId: scheduleTemplateId, fromDay: fromDay, toDays: toDays });
            },
            deleteAppliedSlots: function deleteAppliedSlots(applicationIds) {
                return scheduleTemplateResource.deleteAppliedSlots({ applicationIds: applicationIds });
            },
            extendAppliedSlots: function extendAppliedSlots(applicationIds, extendDate, isConflictResolutionConfirmed, successFunc) {
                scheduleTemplateResource.extendAppliedSlots({ applicationIds: applicationIds, extendDate: moment(extendDate).format('L'), isConflictResolutionConfirmed: isConflictResolutionConfirmed }).$promise.then(function (response) {
                    if (successFunc) {
                        return successFunc(response);
                    }return true;
                }, function (response) {});
            },
            applyCheckedDays: function applyCheckedDays(viewModel, successFunc) {
                var data = angular.merge({}, viewModel, {
                    startDate: moment(viewModel.startDate).format('L'),
                    expirationDate: moment(viewModel.expirationDate).format('L')
                });

                scheduleTemplateResource.applyCheckedDays(data).$promise.then(function (response) {
                    if (successFunc) {
                        return successFunc(response);
                    }return true;
                }, function (response) {});
            }
        };
    }
})();