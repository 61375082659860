'use strict';

(function () {
    'use strict';

    angular.module('fields.module').component('pqMultiselectField', {
        bindings: {
            field: '='
        },
        template: '<label class="checkbox-control fancy-checkbox custom-color-blue"><input type="checkbox" class="multi-choice-checkbox" ng-model="$ctrl.field.isChecked" ng-click="$ctrl.processChange()"><span></span></label>',
        controller: ['$scope', function MultiselectFieldController($scope) {
            var $ctrl = this;

            $ctrl.processChange = function () {
                return $scope.$emit('recordSelectionChanged');
            };
        }]
    });
})();