'use strict';

(function () {
    'use strict';

    angular.element(document).ready(function () {
        angular.bootstrap(document, ['allMedsApp']);
    });

    angular.module('allMedsApp', ['allMedsAppTS', 'ngResource', 'ngSanitize', 'ngCookies', 'ui.select', 'ui.bootstrap', 'fields.module', 'table.module', 'scheduler.module', 'behaviors.module', 'external.module', 'shared.directives' // shared.directives - old logic need refact
    ]).config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('httpResponseInterceptor');
        $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    }]).config(["uibDatepickerPopupConfig", function (datepickerPopupConfig) {
        datepickerPopupConfig.showButtonBar = false;
        datepickerPopupConfig.datepickerPopup = 'MM/dd/yyyy';
    }]).config(["uibDatepickerConfig", function (datepickerConfig) {
        datepickerConfig.showWeeks = false;
        datepickerConfig.formatMonth = 'MMM';
        datepickerConfig.yearColumns = 4;
        datepickerConfig.yearRows = 3;
    }]);

    function getAngularModal() {

        var $injector = angular.injector(['ng', 'allMedsApp']);

        return $injector.invoke(["$uibModal", function ($uibModal) {
            return $uibModal;
        }]);
    }

    window.getAngularModal = getAngularModal;
    window.getAngularType = function (name) {

        var $injector = angular.injector(['ng', 'allMedsApp']);

        return $injector.invoke([name, function (arg) {
            return arg;
        }]);
    };
})();