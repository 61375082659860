'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').directive('scheduleQueueMode', queueMode);

    queueMode.$inject = ['plannerHelpers'];

    function queueMode(plannerHelpers) {
        return {
            restrict: 'E',
            scope: true,
            templateUrl: 'scheduler/scheduleQueueMode.directive/scheduleQueueMode.directive.html',
            link: function link($scope) {
                console.log($scope.viewModel.rescheduleItem);
                var itemId;
                if ($scope.viewModel.rescheduleItem && $scope.viewModel.rescheduleItem.id) {
                    itemId = $scope.viewModel.rescheduleItem.id;
                }

                $scope.isQueueMode = function () {
                    return plannerHelpers.isQueueMode($scope.viewModel);
                };
                $scope.getName = function () {
                    var item = plannerHelpers.getQueueInfo($scope.viewModel);
                    if (item) {
                        var patient = item.patient;
                        if (patient) {
                            return patient.firstName + ' ' + patient.lastName + ' ' + (patient.nameSuffix ? patient.nameSuffix.value : '');
                        }
                    }
                };

                $scope.queueModeQuit = function () {
                    location.href = "/ScheduleEvent/Planner";
                };

                $scope.cancelRescheduleAndRemoveFromQueue = function () {
                    var parameters = {
                        url: "/AppointmentRescheduleQueueItem/DeleteItem",
                        type: "POST",
                        dataType: "json",
                        data: {
                            rescheduleId: $scope.viewModel.rescheduleItem.id
                        }
                    };
                    $.ajax(parameters).then(onDeleteSuccess).fail(onDeleteFailure);

                    function onDeleteSuccess(response) {
                        if (response.success) location.href = '/ScheduleEvent/Planner';else popup.showErrorModal(response.message);
                    }

                    function onDeleteFailure(response) {
                        popup.showErrorModal(response.message);
                    }
                };

                $scope.isRescheduleItem = function () {
                    return !!$scope.viewModel.rescheduleItem;
                };

                $scope.getQueueModeMessage = function () {
                    var model = $scope.viewModel;
                    var message = model.rescheduleItem ? "You're now rescheduling an appointment for " : model.recallItem ? "You're now scheduling a recall for " : model.checkupItem ? "You're now scheduling a check-out for " : model.waitlistItem ? "You're now scheduling an appointment from the waitlist for " : model.copyItem ? "You're now copying an appointment for " : "";
                    return message;
                };
            }
        };
    }
})();