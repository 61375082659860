'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('bhConvertToClaim', bhConvertToClaim);

    bhConvertToClaim.$inject = ['$uibModal', '$window'];

    function bhConvertToClaim($uibModal, $window) {
        return {
            restrict: 'A',
            require: '^wafActionVisitSearch',
            link: function link(scope, element, attrs, wafActionVisitSearchCtrl) {
                element.off('click').on('click', function (e) {
                    e.preventDefault();

                    var modalInstance = $uibModal.open({
                        animation: false,
                        backdrop: 'static',
                        size: 'lg',
                        component: 'visitConvertToClaim',
                        resolve: {
                            visitIds: function visitIds() {
                                return wafActionVisitSearchCtrl.getCheckedVisits();
                            }
                        }
                    });

                    modalInstance.result.then(function () {
                        $window.location.reload();
                    });
                });
            }
        };
    }
})();