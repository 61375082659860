'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').directive('addressHint', addressHintDirective);

    addressHintDirective.$inject = ['$http', '$document', '$templateCache', '$compile'];

    function addressHintDirective($http, $document, $templateCache, $compile) {

        return {
            restrict: 'A',
            scope: false,
            link: function link(scope, $element, attrs) {

                var addressModel;

                $element.on('mouseenter', function () {
                    // add handler
                    $element.qtip({
                        content: {
                            text: function text(event, api) {

                                if (!addressModel || addressModel.isSuccess != true) {

                                    $http({
                                        method: "GET",
                                        url: '/address/data/' + attrs.addressHint,
                                        cache: false
                                    }).then(function (response) {
                                        addressModel = response.data;
                                        showHint();
                                    });

                                    return $compile('<div class="event-bubble-loading" style="height: 50px;"><pq-preloader show="true" style="display: block;" delay="200" class="pls-blue" /></div>')(scope);
                                }

                                showHint();
                                scope.$apply();

                                function showHint() {
                                    var templateScope = scope.$new(true);
                                    templateScope.model = addressModel;

                                    var templateHtml = $templateCache.get('fields/addressHint.directive/addressDetailsBubble.tmpl.html');
                                    var $compiledTemplate = $compile(templateHtml)(templateScope);

                                    // wait until $apply ($http) is not finished ($apply is required to complete template generation)
                                    setTimeout(function () {
                                        api.set({
                                            'content.title': addressModel.isSuccess ? 'Address' : 'Error',
                                            'content.text': $compiledTemplate
                                        });
                                    }, 0);
                                }
                            }
                        },
                        position: {
                            my: 'bottom center',
                            at: 'top center',
                            viewport: $(window),
                            effect: false // effect removed because sizes of loading and content are very different (looks not nice)
                        },
                        show: {
                            ready: true,
                            solo: true,
                            delay: 300 // todo: move into global config
                        },
                        hide: {
                            event: 'mouseleave mousedown'
                        },
                        style: {
                            classes: 'qtip-light',
                            width: 300
                        },
                        events: {
                            hide: function hide(event, api) {
                                // scheduler creates new DOM elements for events very often (on each resize, mode/time change, etc.)
                                // so the simplest way to clean up qTips is to destroy them right after hide
                                api.destroy();
                            }
                        }
                    });
                });

                return;
            }
        };
    }
})();