'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').factory('requestNotificationChannel', requestNotificationChannel);

    requestNotificationChannel.$inject = ['$rootScope'];

    function requestNotificationChannel($rootScope) {
        // private notification messages
        var _START_REQUEST_ = '_START_REQUEST_';
        var _END_REQUEST_ = '_END_REQUEST_';

        // publish start request notification
        var requestStarted = function requestStarted() {
            $rootScope.$broadcast(_START_REQUEST_);
        };
        // publish end request notification
        var requestEnded = function requestEnded() {
            $rootScope.$broadcast(_END_REQUEST_);
        };
        // subscribe to start request notification
        var onRequestStarted = function onRequestStarted($scope, handler) {
            $scope.$on(_START_REQUEST_, function (event) {
                handler();
            });
        };
        // subscribe to end request notification
        var onRequestEnded = function onRequestEnded($scope, handler) {
            $scope.$on(_END_REQUEST_, function (event) {
                handler();
            });
        };

        return {
            requestStarted: requestStarted,
            requestEnded: requestEnded,
            onRequestStarted: onRequestStarted,
            onRequestEnded: onRequestEnded
        };
    }
})();