'use strict';

blocks.register('waf-action_Estimation-ManualEntry', function () {

    var channel = new Channel('estimation');

    var $block;

    return {
        link: function link(block) {
            $block = block;

            var $fieldsPanel = $block.find('.form-fields-panel');

            var text = 'You can fill eligibility data manually, and the estimator will run based on your data. If the patient has additional insurance, you can select it in the estimator screen and check eligibility';

            var additionalMarkup = '<div class="row"><div class="col-md-2"><div class="description-title">What to do next?</div></div><div class="col-md-9"><div class="description-text">' + text + '</div></div></div>';

            $fieldsPanel.prepend(additionalMarkup);

            var isEligibilityCheckFailed = $block.find('[name$="_EligibilityCheckFailed"]').val();

            if (isEligibilityCheckFailed == true) {
                $fieldsPanel.addClass('eligibility-failed');
            }

            applyValidation();
        }
    };

    function applyValidation() {
        $.validator.addMethod("manualentry-amounts-less-or-equal", function (value, element) {
            var baseFieldName = $(element).attr('DATA-ATTRIBUTE-CURRENT-FOR');

            var baseValue = $block.find('[name$="_' + baseFieldName + '"]').val();

            return parseFloatNaNToZero(value) <= parseFloatNaNToZero(baseValue);
        });

        $.validator.addMethod("manualentry-family-greater-or-equal", function (value, element) {
            var individualFieldName = $(element).attr('DATA-ATTRIBUTE-INDIVIDUAL');

            var individual = $block.find('[name$="_' + individualFieldName + '"]').val();

            return parseFloatNaNToZero(value) >= parseFloatNaNToZero(individual);
        });

        $('[DATA-ATTRIBUTE-CURRENT-FOR]', $block).each(function (idx, el) {
            $(el).rules("add", {
                "manualentry-amounts-less-or-equal": true,
                messages: {
                    "manualentry-amounts-less-or-equal": "Current amount can't be higher than the base amount"
                }
            });
        });

        $('[DATA-ATTRIBUTE-INDIVIDUAL]', $block).each(function (idx, el) {
            $(el).rules("add", {
                "manualentry-family-greater-or-equal": true,
                messages: {
                    "manualentry-family-greater-or-equal": "Family amount should be equal or higher than the individual amount"
                }
            });
        });
    }

    function cacheControls($block) {
        $lastEligibilityDateInput = $block.find('[name$="_LastEligibilityDate"]');
        $inNetworkInput = $block.find('[name$="InNetwork"]');
    }
});