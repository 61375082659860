"use strict";

(function () {
    "use strict";

    var lookupPreloadController = function lookupPreloadController($scope, $http, $rootScope) {
        $rootScope.loadingLookups = true;

        if (!$rootScope.lookups) $rootScope.lookups = {};

        if (!$rootScope.lookups[$scope.lookup]) {
            $http({ method: "POST", url: "/SharedDirectives/LookupValuesPreload/", data: { Value: $scope.lookups } }).success(function (result) {
                angular.forEach(result.data, function (item) {
                    $rootScope.lookups[item.Lookup] = item.Data;
                });

                $rootScope.loadingLookups = false;
            });
        }
    };

    lookupPreloadController.$inject = ["$scope", "$http", "$rootScope"];

    var lookupPreload = function lookupPreload() {
        return {
            restrict: "AE",
            scope: {
                lookups: "@"
            },

            link: function link() {},

            controller: lookupPreloadController
        };
    };

    angular.module("shared.directives").directive("lookupPreload", lookupPreload);
})();