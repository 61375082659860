'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('loadingWidget', loadingWidget);

    loadingWidget.$inject = ['requestNotificationChannel'];

    function loadingWidget(requestNotificationChannel) {
        return {
            restrict: "A",
            link: function link(scope, element) {
                var startRequestHandler = function startRequestHandler() {
                    //element.show();
                };
                var endRequestHandler = function endRequestHandler() {
                    element.hide();
                };
                requestNotificationChannel.onRequestStarted(scope, startRequestHandler);
                requestNotificationChannel.onRequestEnded(scope, endRequestHandler);
            }
        };
    }
})();