'use strict';

(function () {
	'use strict';

	angular.module('allMedsApp').directive('wafFeeschedulelineFeeschedulelines', waffeeschedulelinefeeschedulelines);

	waffeeschedulelinefeeschedulelines.$inject = ['$uibModal', '$document', '$compile'];

	function waffeeschedulelinefeeschedulelines($uibModal, $document, $compile) {
		var $block;
		var $deleteBtn;
		var $campaignInput;

		return {
			restrict: 'A',
			link: function link(scope, element, attrs) {
				$block = element;
				var cmdPanel = $block.find('.with-commands');
				var $pageSizeSelector = $('<div ls-page-size-selector></div>');
				cmdPanel.prepend($pageSizeSelector);
				$compile($pageSizeSelector)(scope);
			}
		};
	}
})();