'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').component('pqPreloader', {
        template: '<aside ng-show="$ctrl.showElement" class="ng-hide"><svg class="pl-circular" viewBox="25 25 50 50"><circle class="plc-path" cx="50" cy="50" r="20"></circle></svg><span ng-bind="$ctrl.message"></span></aside>',
        controller: PreloaderController,
        bindings: {
            show: '<',
            delay: '<?',
            message: '<?'
        }
    });

    PreloaderController.$inject = ['$timeout'];

    function PreloaderController($timeout) {
        var $ctrl = this,
            showTimer;

        $ctrl.$onInit = function () {
            $ctrl.showElement = false;
        };

        $ctrl.$onChanges = function (changesObj) {
            if (changesObj.show.currentValue) {
                showPreloader();
            } else {
                hidePreloader();
            }
        };

        function showPreloader() {
            if (showTimer) return;

            showTimer = $timeout(function () {
                $ctrl.showElement = true;
            }, getDelay());
        }

        function hidePreloader() {
            if (showTimer) {
                $timeout.cancel(showTimer);
            }

            showTimer = null;
            $ctrl.showElement = false;
        }

        function getDelay() {
            return $ctrl.delay ? $ctrl.delay : 200;
        }
    }
})();