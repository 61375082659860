'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').factory('scheduleTemplateApplicationsApi', scheduleTemplateApplicationsApi);

    function scheduleTemplateApplicationsApi() {
        return {
            reloadApplications: function reloadApplications(providerId, scheduleTemplateId) {
                // Obolenskiy commented - on Only!! IE 11 error: TypeError: Object doesn't support property or method 'onReloadApplication'
                // (AL - 496) screencast.com/t/Smy2eVm7T
                var self = this;

                var interval = setInterval(function () {
                    if (self.onReloadApplication) {
                        self.onReloadApplication(providerId, scheduleTemplateId);
                        clearInterval(interval);
                    }
                }, 100);
            }
        };
    }
})();