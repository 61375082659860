'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').factory('schedulerNoteDataService', schedulerNoteDataService);

    schedulerNoteDataService.$inject = ['$http'];

    function schedulerNoteDataService($http) {

        var data = {
            parameters: {
                currentDateString: undefined
            },
            isUpdatingAll: false,
            isLastUpdateAllSuccessful: false,
            global: {
                isLoading: false,
                // note is undefined if error and null if not set
                note: undefined
            },
            providers: {},
            getOrInitProviderNoteRecord: function getOrInitProviderNoteRecord(providerId) {
                var noteRecord = data.providers[providerId];
                if (noteRecord === undefined) {
                    // init provider note record by first request
                    noteRecord = {
                        isLoading: data.isUpdatingAll,
                        // if last updateAll was successful and it didn't initialize provider note record, such note is considered empty (set to null)
                        note: data.isLastUpdateAllSuccessful ? null : undefined
                    };
                    data.providers[providerId] = noteRecord;
                }
                return noteRecord;
            },
            resources: {},
            getOrInitResourceNoteRecord: function getOrInitResourceNoteRecord(resourceId) {
                var noteRecord = data.resources[resourceId];
                if (noteRecord === undefined) {
                    // init resource note record by first request
                    noteRecord = {
                        isLoading: data.isUpdatingAll,
                        // if last updateAll was successful and it didn't initialize resource note record, such note is considered empty (set to null)
                        note: data.isLastUpdateAllSuccessful ? null : undefined
                    };
                    data.resources[resourceId] = noteRecord;
                }
                return noteRecord;
            }
        };
        var activeUpdateAllPromise;

        var $this = {
            getData: function getData() {
                return data;
            },

            getParameters: function getParameters() {
                return data.parameters;
            },

            runForEachNoteRecord: function runForEachNoteRecord(noteRecordHandler) {
                noteRecordHandler(data.global, null);
                var providerIds = Object.keys(data.providers);
                for (var i = 0; i < providerIds.length; i++) {
                    var providerId = providerIds[i];
                    noteRecordHandler(data.providers[providerId], providerId);
                }

                var resourceIds = Object.keys(data.resources);
                for (var i = 0; i < resourceIds.length; i++) {
                    var resourceId = resourceIds[i];
                    noteRecordHandler(data.resources[resourceId], resourceId);
                }
            },

            setAllNoteRecordLoading: function setAllNoteRecordLoading(value) {
                $this.runForEachNoteRecord(function (noteRecord, providerId) {
                    noteRecord.isLoading = value;
                });
            },


            updateAll: function updateAll(schedulerStartDate) {
                var passedStartDateString = moment(schedulerStartDate).format('L');
                if (passedStartDateString === data.parameters.currentDateString) {
                    return;
                }

                data.parameters.currentDateString = passedStartDateString;

                data.isUpdatingAll = true;
                $this.setAllNoteRecordLoading(true);
                var promise = $http({
                    method: 'POST',
                    url: '/SchedulerNote/GetNotes',
                    data: {
                        date: data.parameters.currentDateString
                    }
                }).then(function (response) {
                    if (promise !== activeUpdateAllPromise) {
                        // if new request is run
                        return;
                    }

                    // empty all current note records
                    $this.runForEachNoteRecord(function (noteRecord, providerId) {
                        noteRecord.note = null;
                    });

                    // set new provider note records
                    for (var i = 0; i < response.data.length; i++) {
                        var receivedNoteRecord = response.data[i];
                        if (receivedNoteRecord.ProviderId) {
                            var noteRecord = data.getOrInitProviderNoteRecord(receivedNoteRecord.ProviderId);
                            noteRecord.note = receivedNoteRecord;
                        } else if (receivedNoteRecord.ResourceId) {
                            var noteRecord = data.getOrInitResourceNoteRecord(receivedNoteRecord.ResourceId);
                            noteRecord.note = receivedNoteRecord;
                        } else {
                            noteRecord = data.global;
                            noteRecord.note = receivedNoteRecord;
                        }
                    }

                    data.isLastUpdateAllSuccessful = true;
                }, function () {
                    if (promise !== activeUpdateAllPromise) {
                        // if new request is run
                        return;
                    }
                    $this.runForEachNoteRecord(function (noteRecord, providerId) {
                        noteRecord.note = undefined;
                    });
                    data.isLastUpdateAllSuccessful = false;
                }).finally(function () {
                    if (promise !== activeUpdateAllPromise) {
                        // if new request is run
                        return;
                    }
                    data.isUpdatingAll = false;
                    $this.setAllNoteRecordLoading(false);
                });
                activeUpdateAllPromise = promise;
            },

            refreshNote: function refreshNote(startDateString) {
                var noteRecord = data.global;
                if (!noteRecord) {
                    return;
                }

                noteRecord.isLoading = true;
                $http({
                    method: 'POST',
                    url: '/SchedulerNote/GetNoteInfo',
                    data: {
                        date: startDateString
                    }
                }).then(function (response) {
                    if (startDateString !== data.parameters.currentDateString) {
                        // if not actual
                        return;
                    }
                    noteRecord.note = response.data;
                }, function () {
                    if (startDateString !== data.parameters.currentDateString) {
                        // if not actual
                        return;
                    }
                    noteRecord.note = undefined;
                }).finally(function () {
                    if (!data.isUpdatingAll) {
                        noteRecord.isLoading = false;
                    }
                });
            },

            refreshProviderNote: function refreshProviderNote(startDateString, providerId) {
                var noteRecord = providerId ? data.getOrInitProviderNoteRecord(providerId) : data.global;
                if (!noteRecord) {
                    return;
                }

                noteRecord.isLoading = true;
                $http({
                    method: 'POST',
                    url: '/SchedulerNote/GetNoteInfo',
                    data: {
                        date: startDateString,
                        providerId: providerId
                    }
                }).then(function (response) {
                    if (startDateString !== data.parameters.currentDateString) {
                        // if not actual
                        return;
                    }
                    noteRecord.note = response.data;
                }, function () {
                    if (startDateString !== data.parameters.currentDateString) {
                        // if not actual
                        return;
                    }
                    noteRecord.note = undefined;
                }).finally(function () {
                    if (!data.isUpdatingAll) {
                        noteRecord.isLoading = false;
                    }
                });
            },

            refreshResourceNote: function refreshResourceNote(startDateString, resourceId) {
                var noteRecord = resourceId ? data.getOrInitResourceNoteRecord(resourceId) : data.global;
                if (!noteRecord) {
                    return;
                }

                noteRecord.isLoading = true;
                $http({
                    method: 'POST',
                    url: '/SchedulerNote/GetNoteInfo',
                    data: {
                        date: startDateString,
                        resourceId: resourceId
                    }
                }).then(function (response) {
                    if (startDateString !== data.parameters.currentDateString) {
                        // if not actual
                        return;
                    }
                    noteRecord.note = response.data;
                }, function () {
                    if (startDateString !== data.parameters.currentDateString) {
                        // if not actual
                        return;
                    }
                    noteRecord.note = undefined;
                }).finally(function () {
                    if (!data.isUpdatingAll) {
                        noteRecord.isLoading = false;
                    }
                });
            }
        };

        return $this;
    }
})();