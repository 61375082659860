"use strict";

(function () {
    "use strict";

    var loading = function loading($http) {
        return {
            restrict: "A",
            link: function link(scope, elm, attrs) {
                scope.isLoading = function () {
                    return $http.pendingRequests.length > 0;
                };

                scope.$watch(scope.isLoading, function (v) {
                    if (v) {
                        $(elm).closest(".page-content").css("cursor", "wait");

                        if (attrs.loading === "show") elm.show();else elm.hide();
                    } else {
                        $(elm).closest(".page-content").css("cursor", "default");

                        if (attrs.loading === "show") elm.hide();else elm.show();
                    }
                });
            }
        };
    };

    angular.module("shared.directives").directive("loading", loading);
})();