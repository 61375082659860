'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('lsPageSizeSelector', lsPageSizeSelector);

    lsPageSizeSelector.$inject = ['$cookies'];

    function lsPageSizeSelector($cookies) {
        return {
            restrict: 'A',
            scope: {},
            templateUrl: "waf/linkedSearch/lsPageSizeSelector.html",
            link: function link(scope, element) {

                var $linkedSearch = element.parents('.child-linked-record-container:first');
                var dataTable = $linkedSearch.attr('data-table-name');
                var searchName = $linkedSearch.attr('data-search-name');

                var lsModel = $linkedSearch.data('model');
                var matches = lsModel.currentUrl.match(/search-page-size=([^&]*)/);

                var useCookie = !matches;

                var size;
                if (useCookie) {
                    size = $cookies.get(dataTable + '_' + searchName + '_PageSize');
                } else {
                    size = matches[1];
                }

                lsModel.pageSize = size;

                scope.model = size;

                scope.sizes = $.map([10, 20, 50, 100], function (val, i) {
                    return { value: val, displayName: val };
                });

                scope.onChangeSize = function (currentSize) {
                    if (useCookie) {
                        $cookies.put(dataTable + '_' + searchName + '_PageSize', currentSize);
                    } else {
                        lsModel.currentUrl = lsModel.currentUrl.replace(/(search-page-size=)[^\&]+/, '$1' + currentSize);
                    }
                    window.refreshLinkedSearch($linkedSearch);
                };
            }
        };
    }
})();