'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').component('pqMarketingList', {
        templateUrl: 'marketingList/marketingList.html',
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<'
        },
        controller: MarketingListController
    });

    MarketingListController.$inject = ['$scope', '$http'];

    function MarketingListController($scope, $http) {
        var $ctrl = this;

        $ctrl.marketingListSearch = {};

        $ctrl.refreshMarketingList = function (search) {
            var params = {
                _tab: 'MarketingList',
                _sea: 'SuggestSearch',
                term: search
            };
            return $http.get('/WafCommon?_act=Suggest&_=1432226543681', { params: params }).then(function (response) {
                $ctrl.marketingListSearch.marketingLists = response.data;
            });
        };

        $ctrl.clearName = function () {
            $ctrl.marketingListSearch.marketingListName = null;
        };

        $ctrl.onSubmit = function () {
            $ctrl.close({
                $value: {
                    marketingListId: $ctrl.marketingListSearch.marketingListId,
                    marketingListName: $ctrl.marketingListSearch.marketingListName
                }
            });
        };

        $ctrl.onClose = function () {
            $ctrl.close({ $value: null });
        };

        $ctrl.onCancel = function () {
            $ctrl.dismiss({ $value: 'cancel' });
        };
    }
})();