'use strict';

(function () {
    'use strict';

    angular.module('table.module').directive('pqTogglebuttonsFilter', toggleButtonsFilter);

    function toggleButtonsFilter() {
        return {
            restrict: "E",
            templateUrl: 'components/table/searchFilters/toggleButtons.filter/toggleButtons.filter.html'
        };
    }
})();