'use strict';

(function () {
    'use strict';

    angular.module('table.module').component('pqPageSizeSelector', {
        require: {
            pqTableCtrl: '^pqTable'
        },
        bindings: {
            currentSize: '=',
            sizes: '<'
        },
        templateUrl: 'components/table/pageSizeSelector.component/pageSizeSelector.component.html',
        controller: pageSizeController
    });

    pageSizeController.$inject = ['$timeout'];

    function pageSizeController($timeout) {
        var $ctrl = this;

        $ctrl.$onInit = function () {
            $ctrl.sizeModel = $ctrl.currentSize.toString();
        };

        $ctrl.onChangeSize = function (selectedSize) {
            $ctrl.currentSize = parseInt(selectedSize);

            $timeout(function () {
                $ctrl.pqTableCtrl.refresh();
            });
        };
    }
})();