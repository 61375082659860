'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').factory('scheduleEventQueueService', scheduleEventQueueService);

    scheduleEventQueueService.$inject = ['$http', '$resource'];

    function scheduleEventQueueService($http, $resource) {
        var scheduleEventQueueResource = $resource('/:controller/:action', { controller: 'AppointmentRecallQueueItem' }, {
            setRecall: { method: 'Get', params: { action: 'SetRecall' } },
            findRecalls: { method: 'Get', params: { action: 'FindRecalls' } },
            getWaitlists: { method: 'Get', params: { action: 'GetWaitlists', controller: 'AppointmentWaitlistQueueItem' } },
            useWaitlist: { method: 'Get', params: { action: 'UseWaitlist', controller: 'AppointmentWaitlistQueueItem' } }
        });

        var methods = {
            eventResponseError: function eventResponseError(text) {
                popup.showAlertModal(text);
                return false;
            },

            standardThen: function standardThen(promise, successFunc) {
                return promise.then(function (response) {
                    if (successFunc && response.success) {
                        return successFunc(response);
                    } else if (response.message) {
                        methods.eventResponseError(response.message);
                    } else {
                        methods.eventResponseError("Unknown error");
                    }
                    return response;
                }, function (response) {
                    methods.eventResponseError(response.statusText);
                    return response;
                });
            }
        };

        return {
            findRecalls: function findRecalls(data, successFunc) {
                methods.standardThen(scheduleEventQueueResource.findRecalls(data).$promise, successFunc);
            },
            setRecall: function setRecall(data, successFunc) {
                methods.standardThen(scheduleEventQueueResource.setRecall(data).$promise, successFunc);
            },
            getWaitlists: function getWaitlists(id, successFunc) {
                return methods.standardThen(scheduleEventQueueResource.getWaitlists({ eventId: id }).$promise, successFunc);
            },
            useWaitlist: function useWaitlist(waitId, start_date, end_date, successFunc) {
                var startDateMoment = moment(start_date);
                var endDateMoment = moment(end_date);
                start_date = startDateMoment.format('L') + ' ' + startDateMoment.format('LT');
                end_date = endDateMoment.format('L') + ' ' + endDateMoment.format('LT');

                methods.standardThen(scheduleEventQueueResource.useWaitlist({ waitId: waitId, startDate: start_date, endDate: end_date }).$promise, successFunc);
            }
        };
    }
})();