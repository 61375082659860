'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').controller('providerCtrl', providerController);

    providerController.$inject = ['$scope', 'scheduleTemplateDataService', 'scheduleTemplateApplicationsApi'];

    function providerController($scope, scheduleTemplateDataService, scheduleTemplateApplicationsApi) {
        $scope.init = function (providerId) {
            $scope.applyViewModel = {
                provider: { value: providerId, displayValue: $('#Details_R1_FirstName').val() },
                scheduleTemplateId: null
            };

            scheduleTemplateDataService.getViewModel().then(function (response) {
                $scope.viewModel = response;

                scheduleTemplateApplicationsApi.reloadApplications($scope.applyViewModel.provider.value, $scope.applyViewModel.scheduleTemplateId);
            });
        };
    }
})();