'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').factory('visitSearchDataService', visitSearchDataService);

    visitSearchDataService.$inject = ['$http'];

    function visitSearchDataService($http) {
        var service = {
            getBatchClaimCreationDialogModel: getBatchClaimCreationDialogModel,
            batchCreateClaims: batchCreateClaims
        };

        return service;

        ////////////       	

        function getBatchClaimCreationDialogModel(visits) {
            return request('Visit/GetBatchClaimCreationDialogModel', visits);
        }

        function batchCreateClaims(data) {
            return request('Visit/BatchCreateClaims', data);
        }

        function request(url, data) {
            return $http({
                method: 'POST',
                url: url,
                responseType: 'json',
                data: data
            });
        }
    }
})();