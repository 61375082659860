'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('wafActionVisitSearch', wafActionVisitSearch);

    function wafActionVisitSearch() {
        return {
            restrict: 'A',
            controller: function controller($scope, $element) {
                var $searchResultForm = $('.search-result-form', $element);

                this.vm = {
                    $block: $element
                };

                this.getCheckedVisits = function () {
                    return $('.multi-choice-checkbox:checked', $searchResultForm).map(function (i, el) {
                        return $(el).closest('.search-result-table__row').attr('data-id');
                    }).toArray();
                };
            }
        };
    }
})();