"use strict";

(function () {
    "use strict";

    //TODO hrend

    var run = function run($compile, $rootScope, $templateRequest, $http, $uibModal) {
        var $element = $("#Confirm[value='Confirm']").closest('.field-control-container');

        $templateRequest("control.directives/old/schedule-event/schedule.event.search/schedule.event.search.tpl.html").then(function (template) {
            $element.each(function () {
                var $root = $(this);
                $root.html("");
                $root.append($compile(template)($rootScope));
            });
        });

        $rootScope.applyConfirm = function (event) {
            var $e = $(event.currentTarget);
            var $tr = $e.parents("tr");

            var modalInstance = $uibModal.open({
                animation: false,
                templateUrl: "control.directives/old/schedule-event/schedule.event.search/schedule.event.search.modal.tpl.html",
                controller: "modalEventSearchController",
                resolve: {
                    src: function src() {
                        return $tr.find("input#ConfirmationSourceId").val();
                    }
                }
            });

            modalInstance.result.then(function (src) {
                var id = $tr.data("id");

                $http({ url: "/Appointment/SaveConfirmationSource", method: "POST", data: { ApptId: id, ConfirmationSourceId: src } }).success(function (data) {
                    $tr.find("[data-id=ConfirmationSource] p").html(data.ConfirmationSource);
                    $tr.find("[data-id=ConfirmedBy] p").html(data.ConfirmedBy);
                    $tr.find("[data-id=ConfirmationTime] p").html(data.ConfirmationTime);
                    $tr.find('[data-id="AppointmentStatus"] p').html(data.AppointmentStatus);
                    $tr.find("input#ConfirmationSourceId").val(src);
                    $tr.find('[data-id="Confirm"]').remove();
                });
            });
        };
    };

    run.$inject = ["$compile", "$rootScope", "$templateRequest", "$http", "$uibModal"];

    var modalEventSearchController = function modalEventSearchController($scope, $uibModalInstance, src) {
        $scope.src = src;

        $scope.ok = function () {
            $uibModalInstance.close($scope.src);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };
    };

    modalEventSearchController.$inject = ["$scope", "$uibModalInstance", "src"];

    angular.module("shared.directives").controller("modalEventSearchController", modalEventSearchController).run(run);
})();