'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').directive('scheduleTemplateApplicationError', scheduleTemplateApplicationError);

    function scheduleTemplateApplicationError() {
        return {
            restrict: 'A',
            scope: true,
            link: function link($scope, $element) {
                $scope.close = function () {
                    popup.closeOverlay($element);
                };
            }
        };
    }
})();