'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').directive('scheduleEventMonthHint', scheduleEventMonthHintDirective);

    scheduleEventMonthHintDirective.$inject = ['$http', '$compile', 'scheduleEventDataService'];

    function scheduleEventMonthHintDirective($http, $compile, scheduleEventDataService) {
        return {
            restrict: 'A',
            scope: false,
            link: function link($scope, $element) {
                var $container = $element.find(".dhx_cal_data");
                $container.on("mouseenter", ".dhx_month_link table", function (e) {
                    //if (e.which) {
                    //    return; // mouse button is clicked (it means the event is being moved/resized)
                    //}

                    var $eventElement = $(this);
                    var eventsCount = parseInt($eventElement.attr('events-count'));

                    if (eventsCount == 0) {
                        return;
                    }

                    var $date = moment($eventElement.attr("date"));
                    var date = $date.toDate();
                    var dateEvents = scheduler.getEvents(date, scheduler.date.add(date, 1, "day"));

                    // add handler
                    $eventElement.qtip({
                        content: {
                            //title: 'Appt Type',
                            text: function text(event, api) {
                                var appointmentTypes = [];
                                dateEvents.map(function (event) {
                                    if (event.metaTypeId == 0 && event.patientId) {
                                        if (event.appointmentTypeId in appointmentTypes) {
                                            appointmentTypes[event.appointmentTypeId].count++;
                                        } else {
                                            appointmentTypes[event.appointmentTypeId] = {
                                                name: event.appointmentTypeName,
                                                count: 1
                                            };
                                        }
                                    }
                                });

                                var structure = $('<div class="row qtip-titlebar"><div class="col-sm-6">Appointment Type</div><div class="col-sm-6">Insurance Class</div></div>' + '<div class="row"><div class="col-sm-6 appt-type"></div><div class="col-sm-6 insurance-class"></div></div>');

                                if (appointmentTypes.length == 0) return '';

                                function nameCountTable(array) {
                                    var builder = '<table>';
                                    array.forEach(function (item) {
                                        builder += '<tr><td class="name">' + item.name + '</td><td class="count">' + item.count + '</td></tr>';
                                    });
                                    return builder += '</table>';
                                }

                                $('.appt-type', structure).append(nameCountTable(appointmentTypes));

                                scheduleEventDataService.getDayInsuranceClass({ startDate: $date.format('L'), filters: $scope.getFilters() }).then(function (data) {
                                    $('.insurance-class', structure).append(nameCountTable(data.insuranceItems));
                                });

                                return structure;
                            }
                        },
                        position: {
                            my: 'bottom center',
                            at: 'top center',
                            viewport: $(window),
                            effect: false // effect removed because sizes of loading and content are very different (looks not nice)
                        },
                        show: {
                            ready: true,
                            solo: true,
                            delay: 300 // todo: move into global config
                        },
                        hide: {
                            event: 'mouseleave mousedown'
                        },
                        style: {
                            classes: 'qtip-month-mode',
                            width: 300
                        },
                        events: {
                            hide: function hide(event, api) {
                                // scheduler creates new DOM elements for events very often (on each resize, mode/time change, etc.)
                                // so the simplest way to clean up qTips is to destroy them right after hide
                                api.destroy();
                            }
                        }
                    });
                });
            }
        };
    }
})();