'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('pqTimepicker', pqTimepicker);

    function pqTimepicker() {
        return {
            restrict: 'A',
            scope: {
                model: '=',
                name: '@',
                required: '=?',
                change: '&?'
            },
            template: '<input type="text" name="{{name}}" ng-model="_time" ng-required="{{required}}" class="form-control input-sm" />',
            link: function link(scope, element) {
                element.css('display', 'inherit').addClass("bootstrap-timepicker").addClass("input-group");

                var $timepicker = element.find("input:first");
                $('<span class="input-group-addon"><i class="glyphicon glyphicon-time"></i></span>').insertAfter($timepicker);

                $timepicker.timepicker({ defaultTime: '' });

                scope.$watch('model', function (nvMoment, ov) {
                    if (nvMoment) {
                        $timepicker.timepicker('setTime', nvMoment.format('LT'));
                    }
                });

                element.on('changeTime.timepicker', function (e) {
                    var obj = e.time;
                    var hours = obj.hours;
                    if (obj.meridian == 'PM' && hours != 12) {
                        hours += 12;
                    } else if (obj.meridian == 'AM' && hours == 12) {
                        hours = 0;
                    }

                    scope.model.set('hour', hours);
                    scope.model.set('minute', obj.minutes);

                    if (scope.change) {
                        scope.change();
                    }
                });
            }
        };
    }
})();