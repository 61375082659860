'use strict';

(function () {
    'use strict';

    angular.module('fields.module').component('pqActionField', {
        bindings: {
            field: '<',
            row: '<'
        },
        template: '<span ng-bind-html="::$ctrl.field.value" ng-click="$ctrl.click()"></span>',
        controller: ['$scope', function ActionFieldController($scope) {
            var _this = this;

            this.click = function () {
                $scope.$emit(_this.field.name, _this.row);
            };
        }]
    });
})();