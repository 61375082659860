'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').directive('scheduleTemplateExtendDate', scheduleTemplateExtendDateDirective);

    function scheduleTemplateExtendDateDirective() {
        return {
            restrict: 'A',
            scope: true,
            link: function link($scope, $element) {
                var $form = $('ng-form', $element);

                $scope.popup = {
                    cancelCommand: function cancelCommand() {
                        popup.closeOverlay($element);
                        $scope.isOpen = false;
                    },
                    saveCommand: function saveCommand() {
                        if (!$scope.extend_date_form.$valid) {
                            $form.addClass('show-validation-error');
                            return;
                        } else {
                            $form.removeClass('show-validation-error');
                        }

                        if (moment($scope.data.extendDate) < moment($scope.$parent.minExtendDate)) {
                            showAlertModal('Please Enter a Date more than Latest Start Date');
                            return;
                        }

                        if (moment($scope.$parent.sameEndDate).isSame($scope.data.extendDate, 'day')) {
                            showAlertModal('New expiration date should be sooner or later than current expiration date');
                            return;
                        }

                        $scope.$parent.extendApplications($scope.data.extendDate, false);
                        popup.closeOverlay($element);
                    }
                };

                $scope.data = {};
            }
        };
    }
})();