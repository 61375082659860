'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').component('wafSelectCampaign', {
        templateUrl: 'waf/waf.marketinglist.listmember/selectCampaign.component.html',
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<'
        },
        controller: SelectCampaignController
    });

    SelectCampaignController.$inject = ['$scope', '$http'];

    function SelectCampaignController($scope, $http) {
        var $ctrl = this;

        $ctrl.refresh = function (search) {
            var params = {
                _tab: 'Campaign',
                _sea: 'SuggestSearch',
                term: search
            };
            return $http.get('/WafCommon?_act=Suggest&_=1432226543681', { params: params }).then(function (response) {
                $ctrl.lists = response.data;
            });
        };

        $ctrl.onSubmit = function () {
            $ctrl.close({
                $value: {
                    campaignId: $ctrl.campaign.Value,
                    campaignName: $ctrl.campaign.DisplayName
                }
            });
        };

        $ctrl.onClose = function () {
            $ctrl.close({ $value: null });
        };

        $ctrl.onCancel = function () {
            $ctrl.dismiss({ $value: 'cancel' });
        };
    }
})();