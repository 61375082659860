'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').directive('scheduleTemplateConflictDecision', scheduleTemplateConflictDecision);

    function scheduleTemplateConflictDecision() {
        return {
            restrict: 'A',
            scope: true,
            link: function link($scope, $element) {
                $scope.cancel = function () {
                    popup.closeOverlay($element);
                };

                $scope.confirm = function () {
                    $scope.onConfirmed();
                    popup.closeOverlay($element);
                };

                function countConflicts(conflictDescriptions) {
                    var counter = 0;
                    conflictDescriptions.forEach(function (conflictDescription) {
                        conflictDescription.conflictGroups.forEach(function (conflictGroup) {
                            counter += conflictGroup.conflicts.length;
                        });
                    });
                    return counter;
                }

                $scope.$watch('lastResponse', function (response) {
                    if (response) {
                        $scope.overlappingApplicationCount = countConflicts(response.conflictResolutionDecision.overlappingApplications);
                        $scope.nonWorkingTimeSlotCount = countConflicts(response.conflictResolutionDecision.nonWorkingTimeSlots);

                        $scope.confirmMessage = 'Press \'Confirm\' ';
                        var confirmMessageActions = [];
                        if ($scope.overlappingApplicationCount > 0) {
                            confirmMessageActions.push('to delete overlapping applications');
                        }
                        if ($scope.nonWorkingTimeSlotCount > 0) {
                            confirmMessageActions.push('to apply the template to non-working hours');
                        }
                        $scope.confirmMessage += confirmMessageActions.join(' and ');
                        $scope.confirmMessage += '.';
                    }
                });
            }
        };
    }
})();