'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').component('visitConvertToClaim', {
        templateUrl: 'wafActions/visitSearch/visitConvertToClaim.component/visitConvertToClaim.html',
        bindings: {
            close: '&',
            dismiss: '&',
            resolve: '<'
        },
        controller: VisitConvertToClaimController
    });

    VisitConvertToClaimController.$inject = ['$scope', '$timeout', 'visitSearchDataService'];

    function VisitConvertToClaimController($scope, $timeout, visitSearchDataService) {
        var $ctrl = this;

        $ctrl.$onInit = function () {
            $ctrl.isLoading = true;
            $ctrl.loadingMessage = 'Checking...';
            $ctrl.voidOverlappingClaimsDecisionList = [{
                value: true,
                displayValue: 'Yes'
            }, {
                value: false,
                displayValue: 'No'
            }];

            function getBatchClaimCreationDialogModelSuccess(response) {
                if (!response.data) return;

                $ctrl.vm = response.data;

                if (response.data.OpenFinancialLogPopup) {
                    $ctrl.onAssignToFL();
                    return;
                }

                if (response.data.ShowDialog) {
                    if ($ctrl.vm.RequiresBatchVoidingDecision) {
                        $ctrl.vm.VoidOverlappingClaims = true;
                    }

                    $ctrl.vm.Visits.forEach(function (item) {
                        if (item.AvailableParentClaims) {
                            item.AvailableParentClaims = $ctrl.normalizeList(item.AvailableParentClaims);
                        }
                    });
                } else {
                    $timeout(function () {
                        batchCreateClaims(false, $ctrl.getVisits());
                    });
                }
            }

            visitSearchDataService.getBatchClaimCreationDialogModel($ctrl.resolve.visitIds).then(getBatchClaimCreationDialogModelSuccess).finally(function () {
                $ctrl.isLoading = false;
            });
        };

        $ctrl.getVisits = function () {
            return $ctrl.vm.Visits.map(function (item) {
                return item.Visit;
            });
        };

        $ctrl.onAssignToFL = function () {
            if ($ctrl.vm.OpenFinancialLogPopup) {
                showWafModal({
                    url: '/FinancialBucket/DetailsNew',
                    actionHandler: function actionHandler(action) {
                        if (action.actionType === 'response' && action.response.Operation === 'Save' && action.response.IsSuccess) {
                            action.close = true;

                            batchCreateClaims($ctrl.vm.VoidOverlappingClaims, $ctrl.getVisits());
                        }
                    },
                    cssClass: "middle"
                });
            } else {
                batchCreateClaims($ctrl.vm.VoidOverlappingClaims, $ctrl.getVisits());
            }
        };

        $ctrl.onSubmit = function () {
            if (!$scope.form.$valid) {
                $ctrl.formValidated = true;
                return;
            }

            batchCreateClaims($ctrl.vm.VoidOverlappingClaims, $ctrl.getVisits());
        };

        $ctrl.onClose = function () {
            $ctrl.close({ $value: null });
        };

        $ctrl.onCancel = function () {
            $ctrl.dismiss({ $value: 'cancel' });
        };

        $ctrl.getVisitUrl = function (visitId) {
            return 'Visit/Details/' + visitId;
        };

        $ctrl.getClaimUrl = function (claimId) {
            return 'Claim/Details/' + claimId;
        };

        $ctrl.normalizeList = function (claims) {
            return claims.map(function (claim) {
                return { value: parseInt(claim.Value), displayValue: claim.DisplayValue };
            });
        };

        $ctrl.recoverUrl = function (html) {
            var urls = $('<div>' + html + '</div>').find('a');
            return html;
        };

        function batchCreateClaims(voidOverlappingClaims, visits) {
            $ctrl.isLoading = true;
            $ctrl.loadingMessage = 'Creating...';

            return visitSearchDataService.batchCreateClaims({ voidOverlappingClaims: voidOverlappingClaims, visits: visits, applyLog: true }).then(function (response) {
                if (response.data && response.data.Statuses) {
                    //delete $ctrl.vm;	
                    $ctrl.complitedOperations = true;

                    $ctrl.statuses = {
                        successStatuses: response.data.Statuses.filter(function (item) {
                            return item.IsSuccess;
                        }),
                        errorStatuses: response.data.Statuses.filter(function (item) {
                            return !item.IsSuccess;
                        })
                    };

                    $ctrl.showApplyFinLogButton = $ctrl.statuses.errorStatuses.some(function (item) {
                        return item.ErrorType == 4;
                    });
                }
            }).catch(function (response) {}).finally(function () {
                $ctrl.isLoading = false;
            });
        }
    }
})();