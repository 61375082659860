"use strict";

(function () {
    "use strict";

    var loadFunction = function loadFunction($scope, $http, $rootScope) {
        if (!$rootScope.lookups[$scope.lookup]) {
            $http({ method: "POST", url: "/SharedDirectives/LookupValues/", data: { Value: $scope.lookup } }).success(function (result) {
                $rootScope.lookups[$scope.lookup] = result.data;
                $scope.lookupValues = $rootScope.lookups[$scope.lookup];
            });
        } else {
            $scope.lookupValues = $rootScope.lookups[$scope.lookup];
        }
    };

    var lookupSelectController = function lookupSelectController($scope, $http, $rootScope) {
        $scope.selected = null;
        $scope.lookupValues = [{ Id: 0, Name: "Not found" }];

        if (!$rootScope.lookups) $rootScope.lookups = {};

        if ($rootScope.loadingLookups === true) {
            var wait = $scope.$watch(function () {
                return $rootScope.loadingLookups;
            }, function (val) {
                if (!val) {
                    $scope.lookupValues = $rootScope.lookups[$scope.lookup];

                    if (!$rootScope.lookups[$scope.lookup]) loadFunction($scope, $http, $rootScope); //last chance to load 

                    wait();
                }
            });
        } else {
            loadFunction($scope, $http, $rootScope);
        }
    };

    lookupSelectController.$inject = ["$scope", "$http", "$rootScope"];

    var lookupSelect = function lookupSelect() {
        return {
            restrict: "E",
            replace: true,
            templateUrl: "control.directives/old/lookup.select.tpl.html",
            scope: {
                lookup: "@"
            },

            link: function link() {},

            controller: lookupSelectController
        };
    };

    angular.module("shared.directives").directive("lookupSelect", lookupSelect);
})();