'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('pqDatepicker', pqDatepicker);

    function pqDatepicker() {
        return {
            restrict: 'A',
            scope: {
                model: '=',
                name: '@',
                required: '=?',
                minDate: '=',
                disabled: '=?'
            },
            template: '<input type="text" class="form-control input-sm" ng-disabled="disabled" min-date="minDate" name="{{name}}" uib-datepicker-popup ng-model="model" ng-model-options="{ updateOn: \'blur\'}" is-open="opened" ng-click="datepickerOpen($event)" ng-required="required" />' + '<span class="input-group-addon" ng-disabled="disabled"><i class="fa fa-calendar"></i></span>',
            link: function link(scope, element) {
                element.css('display', 'inherit');

                $('.input-group-addon', element).on('click', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    if (!$(this).is('[disabled=disabled]')) $('[name="' + scope.name + '"]', element).click();
                });

                var tabFunc = function tabFunc(e) {
                    if (e.keyCode === 9) {
                        scope.$apply(function () {
                            scope.opened = false;
                        });
                    }
                };

                scope.$watch('opened', function () {
                    if (scope.opened) {
                        $(document).on('keyup', tabFunc);
                    } else {
                        $(document).off('keyup', tabFunc);
                    }
                });

                scope.datepickerOpen = function ($event) {
                    $event.preventDefault();
                    scope.opened = true;
                };
            }
        };
    }
})();