'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').directive('pqZoom', pqZoom);

    function pqZoom() {
        return {
            restrict: 'A',
            scope: {
                selectedCoef: '=coef',
                onChangeZoom: '&'
            },
            templateUrl: 'scheduler/zoom.directive/zoom.directive.tmpl.html',
            link: function link($scope) {
                var coefs = [1, 2, 3, 4, 6],
                    currentIndex = coefs.indexOf($scope.selectedCoef);

                $scope.increase = function () {
                    if (currentIndex + 1 < coefs.length) {
                        currentIndex++;
                        setCoef(currentIndex);
                    }
                };
                $scope.decrease = function () {
                    if (currentIndex - 1 >= 0) {
                        currentIndex--;
                        setCoef(currentIndex);
                    }
                };
                $scope.isIncreaseActive = function () {
                    return currentIndex + 1 < coefs.length;
                };
                $scope.isDecreaseActive = function () {
                    return currentIndex - 1 >= 0;
                };

                function setCoef(currentIndex) {
                    var newCoef = coefs[currentIndex];
                    $scope.selectedCoef = newCoef;
                    $scope.onChangeZoom({ zoomCoefNew: newCoef, zoomCoefOld: $scope.selectedCoef });
                }
            }
        };
    }
})();