'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('pqScrollableContent', pqScrollableContent);

    function pqScrollableContent() {
        return {
            restrict: 'A',
            link: function link(scope, element) {
                element.mCustomScrollbar({
                    theme: "dark-2"
                });
            }
        };
    }
})();