'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('patientPaymentFields', patientPaymentFields);

    patientPaymentFields.$inject = ['$compile', '$document', '$q', 'SwipeReader'];

    function patientPaymentFields($compile, $document, $q, swipeReader) {

        var $block, $form, scope;

        var $paymentMethodField;
        var $storedCreditCardField;
        var $officeField;

        var $creditCardNumber;
        var $cardName;
        var $cardExpirationMonth;
        var $cardExpirationYear;
        var $cardTrackData;
        var $cardDataSourceType;

        var creditCardLookupValue;

        var paySimpleInitialized = false;

        var recurringPaymentService = new RecurringPaymentService();
        var modalService = new ModalService();

        /* setup angular attributes */
        function setupDisplayConditions() {

            var $eftGroup = $block.find('.form-field-group[data-group="EFT"], .form-field-group[data-group="Check"]').attr('ng-show', "paymentMethodCode == 'eft'").css('display', 'block');

            var $checkGroup = $block.find('.form-field-group[data-group="Check"]').attr('ng-show', "paymentMethodCode === 'check'").css('display', 'block');

            $eftGroup.find('input').attr("ng-disabled", "paymentMethodCode != 'eft'");
            $checkGroup.find('input').attr("ng-disabled", "paymentMethodCode != 'check'");

            var $cardGroup = $block.find('.form-field-group[data-group="Card"]').attr('ng-show', "(paymentMethodCode === 'credit-card' || paymentMethodCode === 'credit-card-manual-run') && !isPaySimple").css('display', 'block');

            $block.find('.form-field-group[data-group="PaySimpleCard"]').attr('ng-show', "(paymentMethodCode === 'credit-card') && isPaySimple && isNew").css('display', 'block');

            var $vaultGroup = $block.find('.form-field-group[data-group="StoreCard"]').attr('ng-show', "(paymentMethodCode === 'credit-card') && isPaySimple && isNew").css('display', 'block');

            var $vaultCard = $vaultGroup.find("[name$='_VaultCard']");
            var $forAllPayments = $vaultGroup.find("[name$='_ForAllPayments']");
            var $forPaymentPlans = $vaultGroup.find("[name$='_ForPaymentPlans']");
            var $callFirst = $vaultGroup.find("[name$='_CallFirst']");

            var $vaultFields = $.merge($(), $vaultCard);
            $.merge($vaultFields, $forAllPayments);
            $.merge($vaultFields, $forPaymentPlans);
            $.merge($vaultFields, $callFirst);

            $vaultCard.attr("ng-disabled", "paymentMethodCode !== 'credit-card' || !!storedCreditCardId").attr("ng-required", "!isReadonly && paymentMethodCode === 'credit-card' && !storedCreditCardId").closest('.field-container').attr("ng-show", "!isReadonly && paymentMethodCode === 'credit-card' && !storedCreditCardId").attr("ng-class", "{'required-enabled': (!isReadonly && paymentMethodCode === 'credit-card' && !storedCreditCardId)}");

            $creditCardNumber = $cardGroup.find("[name$='_CardNumber']");
            $cardName = $cardGroup.find('input[name$=_CardName]');
            $cardExpirationMonth = $cardGroup.find('input[name$=_CardExpirationMonth]');
            $cardExpirationYear = $cardGroup.find('input[name$=_CardExpirationYear]');

            //TODO: need to move this fields into $cardGroup with WAF
            $cardTrackData = $block.find('input[id$=_CardTrackData]');
            $cardDataSourceType = $block.find('[id$=_CardDataSourceType]');

            var $cardSecurityNumber = $cardGroup.find('input[name$=_CardSecurityNumber]');

            var $cardInformationFields = $.merge($(), $creditCardNumber);
            $.merge($cardInformationFields, $cardName);
            $.merge($cardInformationFields, $cardExpirationMonth);
            $.merge($cardInformationFields, $cardExpirationYear);

            $vaultFields;

            $cardSecurityNumber.attr("ng-disabled", "paymentMethodCode !== 'credit-card' || !!storedCreditCardId").attr("ng-required", "!isReadonly && paymentMethodCode === 'credit-card' && !storedCreditCardId").closest('.field-container').attr("ng-show", "!isReadonly && paymentMethodCode === 'credit-card' && !storedCreditCardId").attr("ng-class", "{'required-enabled': (!isReadonly && paymentMethodCode === 'credit-card' && !storedCreditCardId)}");

            $cardTrackData.attr("ng-disabled", "(paymentMethodCode !== 'credit-card' && paymentMethodCode !== 'credit-card-manual-run') && !isSwiped");

            $cardDataSourceType.attr("ng-disabled", "paymentMethodCode !== 'credit-card' && paymentMethodCode !== 'credit-card-manual-run'");

            setupCardOwnerLink();
            setupMaxAmount();
        }

        function setupStoredCreditCardDisplayConditions() {
            $storedCreditCardField.attr("ng-disabled", "!isRecurringPaymentsOn || paymentMethodCode !== 'credit-card' || !hasAvailableStoredCreditCards").closest('.field-container').attr("ng-show", "!!isRecurringPaymentsOn && paymentMethodCode === 'credit-card' && hasAvailableStoredCreditCards");
        }

        function setupCardIconCondition() {
            $creditCardNumber.closest('.field-input-container').addClass('cc-type').attr("ng-class", "cardType ? ('cc-type-' + cardType) : ''");
        }

        function addQuickCreditCardButton() {
            var $cardPaymentBtn = $('<i title="Pay by card" class="fa fa-credit-card quick-card-payment-btn"></i>');

            $cardPaymentBtn.attr("ng-show", "patientId && hasAvailableStoredCreditCards");

            var $advancedPaymentContainer = $block.find('.field-control-container[data-id$=_AdvancedPayment] > .field-input-container');
            $advancedPaymentContainer.append($cardPaymentBtn);

            return $cardPaymentBtn;
        }

        function setupCardOwnerLink() {
            var $cardOwnerBlock = $('<div class="field-container card-owner-attached" data-label-position="Left" data-is-readonly="true" ng-show="paymentMethodCode === \'credit-card\' && storedCreditCardId">' + '<label class="control-label">Card Owner</label>' + '<div class="field-control-container">' + '<p class="readonly-value"><a target="_blank" href="Patient/Details/{{cardOwnerId}}">{{cardOwnerName}}</p>' + '</div>');

            $cardName.closest('.field-container').append($cardOwnerBlock);
        }

        function getCardMaxAmount() {
            var data = $storedCreditCardField.data('select2').data();

            if (data && data.data && data.data.maxAmount) {
                return parseFloatNaNToZero(data.data.maxAmount);
            }

            return null;
        }

        function setupMaxAmount() {
            $.validator.addMethod('validate-card-limit', function (value, element) {
                return scope.paymentMethodCode !== 'credit-card' || !scope.storedCreditCardId || scope.maxAmount == null || parseFloatNaNToZero(value) <= scope.maxAmount;
            });

            var $paymentAmount = $block.find('.form-fields-panel input[name$=_PaidAmount], .form-fields-panel input[name$=_DepositAmount]');

            $paymentAmount.parent().attr('ng-class', '{ \'max-card-amount-field\' : paymentMethodCode === \'credit-card\' && storedCreditCardId && (maxAmount != null) }');
            $paymentAmount.after('<span ng-show="paymentMethodCode === \'credit-card\' && storedCreditCardId && (maxAmount != null)" class="max-card-amount-info">Max: ${{maxAmount}}</span>');

            $paymentAmount.rules('add', {
                'validate-card-limit': true,
                messages: {
                    'validate-card-limit': 'The Applied amount can\'t be greater than limit for selected card'
                }
            });
        }

        /* ------------------------ */

        function checkStoredCreditCardHasOptions() {
            var dataOptions = $storedCreditCardField.attr('data-options');
            var options = dataOptions ? JSON.parse(dataOptions) : [];

            return options.length > 0;
        }

        function connectSwiper(scope) {

            var creditCardLookupValue = window.lookups.PaymentMethod.Values.firstOrDefault(function (x) {
                return x.IsSystem && x.Code == 'credit-card';
            });

            if (!creditCardLookupValue) {
                return;
            }

            var dataOptions = $paymentMethodField.data('options');

            var supportCreditCard = dataOptions.firstOrDefault(function (x) {
                return x.Value == creditCardLookupValue.Id;
            });

            if (!supportCreditCard) {
                return;
            }

            swipeReader.error(function (error) {
                return modalService.showAlertModalAsync('Swipe Error', error);
            }).success(function (data) {

                var cardModel = {
                    number: data.cardNumber,
                    name: data.names[1] + ' ' + data.names[0],
                    month: data.expMonth,
                    year: data.expYear,
                    trackData: data.tracks.join('?')
                };

                $paymentMethodField.val(creditCardLookupValue.Id).trigger('change', ['swipe', cardModel]);
            }).connect();

            scope.$on('$destroy', swipeReader.disconnect);
        }

        function clearPaymentFields() {
            var $inputs = $block.find('.form-field-group[data-group="EFT"], .form-field-group[data-group="Check"], .form-field-group[data-group="Card"]').find('input');

            $inputs.val('').filter('.basic-select-control').change();

            //TODO: remove this after fix WAF
            $cardTrackData.val('');
            $cardDataSourceType.val('');
        }

        function resetValidation() {
            $form.validate().resetForm();
            $form.find('.field-validation-error > ').remove();
        }

        function monitorDestroy(scope) {

            var document = $document[0];
            var element = $block[0];

            var isAttached = false;
            var interval;

            interval = setInterval(function () {
                if (!isAttached) {
                    isAttached = $.contains(document, element);
                } else {
                    isAttached = $.contains(document, element);

                    if (!isAttached) {
                        clearInterval(interval);
                        scope.$destroy();
                    }
                }
            }, 200);
        }

        function loadAllCreditCardOptionsAsync() {

            var defer = $.Deferred();

            var dataName = $storedCreditCardField.attr('name') + '-dependency-handler';

            var depHandlerFunc = $officeField.data(dataName);

            if (!depHandlerFunc) {
                console.log('no dependency handler');
                defer.reject();
            }

            depHandlerFunc().fail(defer.reject).done(function (options) {
                if (!options || !options.length) {
                    defer.reject();
                    return;
                }

                defer.resolve(options);
            });

            return defer.promise();
        }

        function onPaymentMethodChange(state) {
            var val = state.val;
            var action = state.action;
            var card = state.card;

            scope.paymentMethodId = val;

            var newCode = window.lookups.PaymentMethod.CodeById[val];

            if (scope.paymentMethodCode == newCode && !action) {
                return;
            }

            scope.paymentMethodCode = newCode;
            scope.cardType = null;
            scope.isSwiped = false;

            clearPaymentFields();

            if (action === 'swipe') {
                scope.isSwiped = true;

                $creditCardNumber.val(card.number);
                $cardName.val(card.name);
                $cardExpirationMonth.val(card.month).change();
                $cardExpirationYear.val(card.year).change();
                $cardTrackData.val(card.trackData);
                $cardDataSourceType.val('1');

                scope.cardType = allMeds.creditcard.getCreditCardType(card.number);

                if (scope.storedCreditCardId) {
                    scope.storedCreditCardId = null;

                    // need to trigger card to execute dependency handler for obtaining non-filtered office's list
                    $storedCreditCardField.val('').trigger('change', 'payment-method');
                }
            } else if (action === 'quick-select-card') {
                scope.storedCreditCardId = null;
                $storedCreditCardField.data('select2').val('');
                $officeField.data('select2').val('');

                loadAllCreditCardOptionsAsync().done(function (options) {

                    if (options.length === 1) {
                        // need to trigger card to execute dependency handler for obtaining filtered office's list
                        $storedCreditCardField.select2('val', options[0].Value).change();
                    } else {
                        $storedCreditCardField.data('select2').open();
                    }
                });
            } else if (scope.storedCreditCardId) {
                scope.storedCreditCardId = null;

                // need to trigger card to execute dependency handler for obtaining non-filtered office's list
                $storedCreditCardField.val('').trigger('change', 'payment-method');
            } else if (newCode === 'credit-card') {
                // need to trigger office to execute dependency handler for obtaining cards
                $officeField.trigger('change');
            }

            resetValidation();
        }

        function getStoredCreditCardDetailAsync(val) {

            var defer = $q.defer();

            if (val) {
                recurringPaymentService.getCreditCardDetails(val).fail(function (error) {
                    modalService.showAlertModalAsync('Failed to load credit card details', error);

                    defer.reject();
                }).done(function (cardModel) {
                    defer.resolve(cardModel);
                });
            } else {
                defer.reject();
            }

            return defer.promise;
        }

        return {
            restrict: 'A',
            scope: {
                paymentMethodId: '@',
                paymentMethodCode: '@',
                storedCreditCardId: '@',
                cardType: '@',
                cardSwiped: '@',
                isReadonly: '@',
                isRecurringPaymentsOn: '@',
                hasAvailableStoredCreditCards: '@',
                patientId: '@'
            },
            link: function link($scope, element, attrs) {
                $block = element;
                scope = $scope;
                $form = $block.find('form');
                $paymentMethodField = $block.find("[name$=_PaymentMethod]");
                $storedCreditCardField = $block.find("[name$=_StoredCreditCard]");
                $officeField = $block.find("[name$=_Office]");

                var $isPaySimpleField = $block.find("[name$=_IsPaySimple]");
                var isNew = !!!$block.find("#PatientPayment_R1_EobId").val();

                scope.isPaySimple = $isPaySimpleField.val() === "True";
                scope.isNew = isNew;

                // remove the transpay cc fields (because post fails on form validation even if the fields are hidden)
                if (scope.isPaySimple) {
                    $block.find("#form_field_group_card").remove();
                }

                creditCardLookupValue = window.lookups.PaymentMethod.Values.firstOrDefault(function (x) {
                    return x.IsSystem && x.Code == 'credit-card';
                });

                var $patientIdField = $block.find("[name$=_PatientId]");

                var isReadonlyForm = $paymentMethodField.prop('disabled');

                monitorDestroy(scope);
                setupDisplayConditions();

                scope.paymentMethodId = $paymentMethodField.val();
                scope.paymentMethodCode = window.lookups.PaymentMethod.CodeById[scope.paymentMethodId];
                scope.storedCreditCardId = $storedCreditCardField.val();
                scope.cardType = allMeds.creditcard.getCreditCardType($creditCardNumber.val());
                scope.isSwiped = $cardDataSourceType.val() === '1';
                scope.isReadonly = isReadonlyForm;
                scope.isRecurringPaymentsOn = allMeds.modules.isRecurringPaymentOn;
                scope.hasAvailableStoredCreditCards = checkStoredCreditCardHasOptions();
                scope.patientId = $patientIdField.val();

                if (!isReadonlyForm) {
                    waf_SetDependencyHandlerInterceptor($storedCreditCardField, function (fieldInfo, baseExecutor) {
                        if (scope.paymentMethodCode === 'credit-card' || fieldInfo.name === 'Office' && !$officeField.val()) {
                            return baseExecutor();
                        }

                        return $.Deferred().resolve();
                    });

                    setupStoredCreditCardDisplayConditions();
                    setupCardIconCondition();
                    connectSwiper(scope);

                    var paymentMethodDefer = $q.defer();
                    paymentMethodDefer.promise.then(null, null, onPaymentMethodChange);
                    $paymentMethodField.on('change', function (e, action, card) {
                        var val = this.value;
                        paymentMethodDefer.notify({ val: val, action: action, card: card });
                    });

                    $creditCardNumber.on('change', function () {
                        var val = this.value;
                        scope.$apply(function () {
                            scope.cardType = allMeds.creditcard.getCreditCardType(val);
                        });
                    });

                    if (allMeds.modules.isRecurringPaymentOn) {

                        $patientIdField.on('change', function () {
                            var val = this.value;

                            scope.$apply(function () {
                                scope.patientId = val;
                            });
                        });

                        var $quickButton = addQuickCreditCardButton();

                        $quickButton.on('click', function () {
                            $paymentMethodField.val(creditCardLookupValue.Id).trigger('change', 'quick-select-card');
                        });

                        $storedCreditCardField.on('change', function (e, flag) {

                            if (flag === 'payment-method' || flag === 'self') {
                                return;
                            }

                            if (scope.paymentMethodCode !== 'credit-card') {

                                $q.resolve(function () {
                                    scope.storedCreditCardId = null;
                                });

                                return;
                            }

                            var val = this.value;

                            clearPaymentFields();

                            getStoredCreditCardDetailAsync(val).then(function (cardModel) {
                                $creditCardNumber.val(cardModel.number);
                                $cardName.val(cardModel.name);
                                $cardExpirationMonth.val(cardModel.expirationMonth).change();
                                $cardExpirationYear.val(cardModel.expirationYear).change();

                                scope.storedCreditCardId = val;
                                scope.cardType = allMeds.creditcard.getCreditCardTypeById(cardModel.type);
                                scope.isSwiped = false;
                                scope.cardOwnerId = cardModel.cardOwnerId;
                                scope.cardOwnerName = cardModel.cardOwnerName;

                                scope.maxAmount = getCardMaxAmount();

                                resetValidation();
                            }).catch(function () {
                                scope.storedCreditCardId = null;
                                scope.cardType = null;

                                resetValidation();

                                $storedCreditCardField.val('').trigger('change', 'self');
                            });
                        });

                        $storedCreditCardField.on('select2-init', function () {
                            var hasOptions = checkStoredCreditCardHasOptions();

                            scope.$apply(function () {
                                scope.hasAvailableStoredCreditCards = hasOptions;
                            });
                        });
                    }
                }

                $compile($form.find('.form-fields-panel'))(scope);

                if (scope.storedCreditCardId) {
                    $storedCreditCardField.trigger('change');
                }
            }
        };
    }
})();