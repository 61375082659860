'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').directive('scheduleByPatient', scheduleByPatient);

    function scheduleByPatient() {
        return {
            restrict: 'E',
            scope: true,
            templateUrl: 'scheduler/scheduleByPatient.directive/scheduleByPatient.directive.html',
            link: function link($scope) {
                var submitted = false;

                $scope.$on('scheduleEvent:backgroundSubmit', function () {
                    submitted = true;
                });

                $scope.$on('scheduleEvent:closePopup', function () {
                    if ($scope.isPatientSelected() && submitted) {
                        $scope.quitScheduleByPatient();
                    }
                });

                $scope.getName = function () {
                    return $scope.viewModel.selectedEvent && $scope.viewModel.selectedEvent.patientName;
                };

                $scope.isPatientSelected = function () {
                    var selected = $scope.viewModel.selectedEvent && $scope.viewModel.selectedEvent.patientName && !$scope.viewModel.selectedEvent.id;

                    return selected;
                };

                $scope.quitScheduleByPatient = function () {
                    window.location.href = 'Patient/Details/' + $scope.viewModel.selectedEvent.patientId;
                };
            }
        };
    }
})();