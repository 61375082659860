'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').factory('scheduleEventViewModel', scheduleEventViewModel);

    scheduleEventViewModel.$inject = ['scheduleEventDataService', 'scheduleDefaultsService'];

    function scheduleEventViewModel(scheduleEventDataService, scheduleDefaultsService) {
        var service = {
            getViewModel: getViewModel
        };

        return service;

        function getViewModel(params) {
            return scheduleEventDataService.getViewModel(params).then(function (response) {
                if (!response.isSuccess) {
                    return false;
                }

                var data = response.data,
                    settings = {
                    lists: {
                        officeOptions: data.officeOptions,
                        providerOptions: data.providerOptions,
                        resourceOptions: data.resourceOptions,
                        appointmentTypes: data.appointmentTypes,
                        appointmentStatuses: data.appointmentStatuses,
                        visitStatuses: data.appointmentStatuses.filter(function (c) {
                            return c.flowStage != 2;
                        }),
                        billableStatuses: data.appointmentStatuses.filter(function (c) {
                            return c.flowStage == 2;
                        }),
                        templateSlotInitialPeriodOptions: data.templateSlotInitialPeriodOptions,
                        openSlotInitialDurationOptions: data.openSlotInitialDurationOptions,
                        reasons: data.appointmentReasons,
                        patients: [],
                        metaTypes: [{
                            id: 0,
                            name: 'Appointment'
                        }, {
                            id: 1,
                            name: 'Unavailable'
                        }],
                        recalls: []
                    },
                    events: null,
                    workingTimes: null,
                    allowDragAndDropInScheduler: data.allowDragAndDropInScheduler,
                    isSelectNewEventByPatient: data.isSelectNewEventByPatient,
                    defaultZoom: data.defaultSchedulerZoom,
                    cancelledAppointmentStatusId: data.cancelledAppointmentStatusId,
                    filterSettings: data.filterSettings,
                    rescheduleItem: data.rescheduleQueueItem,
                    copyItem: data.copyAppointmentItem,
                    recallItem: data.recallQueueItem,
                    checkupItem: data.checkoutQueueItem,
                    waitlistItem: data.waitlistQueueItem,
                    selectedEvent: data.selectedEvent,
                    selectedUnits: [],
                    filters: {
                        patientId: null
                    },
                    searchSlotParams: {}
                };

                return scheduleDefaultsService.process(settings); //todo old logic
            });
        }
    }
})();