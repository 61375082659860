'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('wafTransactionEobTransactionComposition', waftransactioneobtransactioncomposition);

    waftransactioneobtransactioncomposition.$inject = ['$uibModal', '$document', '$compile'];

    function waftransactioneobtransactioncomposition($uibModal, $document, $compile) {
        var $block;
        var $deleteBtn;
        var $linkedSearch;

        function extractIdsFromRows($form) {
            return _.map($form.find('.multi-choice-checkbox:checked'), getIds);
        }

        function getIds(elem) {
            var dataId = $(elem).closest('.search-result-table__row').attr('data-id');

            return parseInt(dataId);
        }

        function deleteSelectedTransactions(deleteMembers) {
            var postData = {
                eobId: $linkedSearch.data('model').foreignKeyValue,
                ids: deleteMembers
            };

            function onSuccess() {
                var linkedChannel = new Channel('eobtransactions');
                linkedChannel.trigger("transaction-delete");
            }

            postSelectedTransactions("/Transaction/DeleteTransactions", postData, onSuccess, 'The selected transactions have been deleted.');
        }

        function writeOffSelectedTransactions(transactionIds) {

            function onSuccess() {
                location.href = location.href;
            }

            $linkedSearch.addClass('loading-container');

            postSelectedTransactions("/Transaction/WriteOffTransactions", { ids: transactionIds }, onSuccess, 'Successful balance write-off.');
        }

        function postSelectedTransactions(url, postData, onSuccess, successMessage) {
            $linkedSearch.addClass('loading-container');

            $.post(url, postData).done(function (response) {
                $linkedSearch.removeClass('loading-container');

                if (!response.isSuccess) {
                    popup.showMessageModal({
                        title: 'Error',
                        message: response.error.message,
                        actions: [{ caption: "OK", style: 'danger' }]
                    });
                    return;
                }

                popup.showMessageModal({
                    title: 'Success',
                    message: successMessage,
                    actions: [{ caption: "OK", style: 'success', handler: onSuccess }]
                });
            });
        }

        function deleteTransactions() {
            confirmAction("Are you sure you want to remove these transactions from the deposit?", deleteSelectedTransactions);
        }

        function writeOffBalance() {
            confirmAction("Proceed with writing off balances for the selected transactions?", writeOffSelectedTransactions);
        }

        function confirmAction(message, _handler) {
            var selectedIds = extractIdsFromRows($block);

            if (selectedIds.length < 1) {
                popup.showMessageModal({
                    title: 'Warning',
                    message: 'Please select at least one record.',
                    actions: [{ caption: "OK", style: 'success' }]
                });

                return;
            }

            popup.showMessageModal({
                title: 'Confirm',
                message: message,
                actions: [{ caption: "Yes", style: 'success', handler: function handler() {
                        _handler(selectedIds);
                    } }, { caption: "No" }]
            });
        }

        return {
            restrict: 'A',
            link: function link(scope, element, attrs) {
                $block = element;
                $linkedSearch = $block.closest('#LinkedForm_EobTransactionComposition');

                addPageSizeSelector();

                $block.find('.linked-search-filters .field-control-container[data-id="showExtendedView"]').one('change', function (e) {
                    e.stopPropagation();
                    var value = $(e.target).val();

                    var model = $linkedSearch.data('model');

                    model.currentUrl = appendKeyValueToUrl(model.currentUrl, 'showExtendedView', value);

                    window.refreshLinkedSearch($linkedSearch);
                });

                var $writeOffBtn = $block.find('[data-command-name="BalanceWriteOff"]').attr('disabled', 'disabled').off().on('click', function (clickEvent) {
                    clickEvent.preventDefault();
                    writeOffBalance();
                });

                $deleteBtn = $block.find('[data-command-name="Delete"]').attr('disabled', 'disabled').off().on('click', function (e) {
                    e.preventDefault();
                    deleteTransactions();
                });

                $block.on('click', '.multi-choice-checkbox', function () {
                    if (this.checked) {
                        $deleteBtn.removeAttr('disabled');
                        $writeOffBtn.removeAttr('disabled');
                    } else if (!$block.find('.multi-choice-checkbox:checked').length) {
                        $deleteBtn.attr('disabled', 'disabled');
                        $writeOffBtn.attr('disabled', 'disabled');
                    }
                });

                $block.find('.multi-choice-common-checkbox').on('click', function () {
                    if (this.checked) {
                        $deleteBtn.removeAttr('disabled');
                        $writeOffBtn.removeAttr('disabled');
                    } else {
                        $deleteBtn.attr('disabled', 'disabled');
                        $writeOffBtn.attr('disabled', 'disabled');
                    }
                });

                function addPageSizeSelector() {
                    var cmdPanel = $block.find('.with-commands, .without-commands');
                    var $pageSizeSelector = $('<div ls-page-size-selector></div>');
                    cmdPanel.prepend($pageSizeSelector);
                    $compile($pageSizeSelector)(scope);
                }

                function appendKeyValueToUrl(url, key, value) {
                    if (!key) {
                        return url;
                    }
                    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
                    var separator = url.indexOf('?') !== -1 ? "&" : "?";
                    if (url.match(re)) {
                        return url.replace(re, '$1' + key + "=" + value + '$2');
                    } else {
                        return url + separator + key + "=" + value;
                    }
                }
            }
        };
    }
})();