'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').component('pqScheduler', {
        templateUrl: 'scheduler/scheduler.component/scheduler.component.html',
        controller: SchedulerController,
        bindings: {
            selectedUnits: '<',
            filteredEvents: '<',
            scheduler: '=',
            viewModel: '=',
            refreshData: '&'
        }
    });

    SchedulerController.inject = ['$scope', '$window', '$document', '$timeout', 'plannerHelpers', 'scheduleEventPopup', 'scheduleEventSettingsService', 'schedulerNoteDataService', 'scheduleEventDataService'];

    function SchedulerController($scope, $window, $document, $timeout, plannerHelpers, scheduleEventPopup, scheduleEventSettingsService, schedulerNoteDataService, scheduleEventDataService) {
        var $ctrl = this;

        $ctrl.$onInit = function () {
            var schedulerStoredSettings = $window.userSession.get('schedulerSettings');
            var params = $ctrl.getQueryStringParams();
            angular.extend($ctrl.scheduler, {
                hourHeightSize: 44, //px
                zoomCoef: schedulerStoredSettings && schedulerStoredSettings.zoomCoef ? schedulerStoredSettings.zoomCoef : $ctrl.viewModel.defaultZoom / 100,
                controller: 'ScheduleEvent',
                date: params.startDate ? new Date(params.startDate) : new Date(),
                dateTitle: null,
                calendarContainer: 'scheduler_calendar_here'
            });

            $scope.scheduler = $ctrl.scheduler; //fix old logic
            $scope.viewModel = $ctrl.viewModel; //fix old logic
            $scope.getFilters = function () {
                return plannerHelpers.getFilters($ctrl.viewModel.lists);
            }; //fix old logic
            scheduleEventSettingsService.init($scope, scheduler);

            $ctrl.schedulerNoteData = schedulerNoteDataService.getData();
            // watch startDate to update all scheduler notes
            $scope.$watch(function () {
                return $ctrl.scheduler.startDate.getTime();
            }, function (newValue, oldValue) {
                schedulerNoteDataService.updateAll(newValue);
            });
            // watch providerOptions to rebuild providerOptionsMap helper for provider scheduler notes
            $scope.$watch(function () {
                return $ctrl.viewModel.lists.providerOptions;
            }, function (newValue, oldValue) {
                $ctrl.providerOptionsMap = {};
                var providerOptions = newValue;
                if (providerOptions) {
                    for (var i = 0; i < providerOptions.length; i++) {
                        var providerOption = providerOptions[i];
                        $ctrl.providerOptionsMap[providerOption.id] = providerOption;
                    }
                }
            });

            // watch resourceOptions to rebuild resourceOptionsMap helper for resource scheduler notes
            $scope.$watch(function () {
                return $ctrl.viewModel.lists.resourceOptions;
            }, function (newValue, oldValue) {
                $ctrl.resourceOptionsMap = {};
                var resourceOptions = newValue;
                if (resourceOptions) {
                    for (var i = 0; i < resourceOptions.length; i++) {
                        var resourceOption = resourceOptions[i];
                        $ctrl.resourceOptionsMap[resourceOption.id] = resourceOption;
                    }
                }
            });
        };

        $ctrl.getQueryStringParams = function () {
            return window.location.search.split(/[&||?]/).filter(function (c) {
                return c.indexOf("=") > -1;
            }).map(function (c) {
                return c.split(/=/);
            }).map(function (c) {
                c[1] = c[1].replace(/\+/g, " ");
                return c;
            }).reduce(function (obj, item) {
                obj[item[0]] = item[1];
                return obj;
            }, {});
        };

        $ctrl.$postLink = function () {
            $ctrl.onChangeZoom($ctrl.scheduler.zoomCoef);
        };

        $ctrl.$onChanges = function (changesObj) {
            var selectedUnits = changesObj.selectedUnits;
            if (selectedUnits) {
                var hasChanges = !selectedUnits.currentValue.length || selectedUnits.currentValue.length != selectedUnits.previousValue.length || !(selectedUnits.previousValue.length && selectedUnits.currentValue.every(function (c) {
                    return selectedUnits.previousValue.some(function (p) {
                        return p.id == c.id;
                    });
                }));

                if (hasChanges) {
                    updateUnits();
                }
            }
        };

        $ctrl.onChangeMode = function (mode) {
            if ($ctrl.scheduler.mode === mode) {
                return;
            }

            $ctrl.scheduler.mode = mode;

            if (plannerHelpers.isSingleUnitMode($ctrl.scheduler.mode)) {
                if ($ctrl.viewModel.selectedUnits.length > 1) {
                    $ctrl.viewModel.selectedUnits.forEach(function (c) {
                        c.checked = false;
                    });
                    $ctrl.viewModel.selectedUnits = [$ctrl.viewModel.selectedUnits[0]];
                    $ctrl.viewModel.selectedUnits[0].checked = true;
                }
            }

            updateUnits();

            $ctrl.refreshData();

            var schedulerSettings = $window.userSession.get('schedulerSettings');
            schedulerSettings.mode = $ctrl.scheduler.mode;
            $window.userSession.set('schedulerSettings', schedulerSettings);
        };

        $ctrl.onChangeZoom = function (zoomCoefNew, zoomCoefOld) {
            scheduler.config.time_step = zoomCoefNew == 6 ? 5 : 15;

            if (zoomCoefNew) {
                var scrollDiv = $('.dhx_cal_data'),
                    scrollCoef = scrollDiv[0].scrollHeight / scrollDiv.scrollTop();

                scheduler.config.hour_size_px = zoomCoefNew * $ctrl.scheduler.hourHeightSize;
                $timeout(function () {
                    scheduler.updateView();
                    scrollDiv.scrollTop(scrollDiv[0].scrollHeight / scrollCoef);
                });
            }

            if (zoomCoefNew !== zoomCoefOld) {
                var schedulerSettings = $window.userSession.get('schedulerSettings');
                schedulerSettings.zoomCoef = zoomCoefNew;
                $window.userSession.set('schedulerSettings', schedulerSettings);
            }
        };

        //todo: channel
        $document.on('scheduleEvent:refresh', function () {
            $ctrl.refreshData();
        }); //for old logic
        $document.on('scheduleEvent:backgroundSubmit', function (e, eventId) {
            handleSubmit($ctrl.viewModel, eventId);
        });
        $document.on('scheduleEvent:initialized', function () {
            scheduleEventPopup.refreshHeaders();
            safeStorage(function (storage) {
                restoreExpandedCollapsedStates(storage);
            });
        });

        scheduler.attachEvent('onBeforeEventDelete', function (id, e) {
            $window.AllMeds.Appointment.delete(id, { callback: $ctrl.refreshData });
            return true;
        });
        scheduler.attachEvent('onViewChange', $ctrl.onChangeMode);

        scheduler.showLightbox = $.throttle(2000, true, showLightbox);

        function showLightbox(eventId, ignoreQuery) {
            if ($ctrl.viewModel.rescheduleItem && $ctrl.viewModel.rescheduleItem.originAppointmentId == eventId) {
                return;
            }

            var apptUrl = '/Appointment/Details/';
            if (!ignoreQuery) {
                var selectedEvent = scheduler.getEvent(eventId),
                    isNew = selectedEvent.text === 'New event',
                    unitParts = selectedEvent.schedulerUnitId && selectedEvent.schedulerUnitId.split(',').map(function (c) {
                    return c.split('-');
                }).reduce(function (obj, c) {
                    obj[c[0]] = c[1];return obj;
                }, {}); // "Provider-4,Resource-9" => {Provider:"4",Resource:"9"}

                var providerId = getResourceId("Provider", unitParts),
                    resourceId = getResourceId("Resource", unitParts);
                //add providerId to event and serch default office by event
                var officeId = selectedEvent.officeId || $ctrl.viewModel.copyItem && $ctrl.viewModel.copyItem.officeId || plannerHelpers.getDefaultOfficeId($.extend({}, selectedEvent, { providerId: providerId, resourceId: resourceId }), $ctrl.viewModel.workingTimes, $ctrl.viewModel.selectedUnits) || $ctrl.viewModel.officeId,
                    reschedulingId = $ctrl.viewModel.rescheduleItem && $ctrl.viewModel.rescheduleItem.originAppointmentId,
                    copyAppointmentId = $ctrl.viewModel.copyItem && $ctrl.viewModel.copyItem.id,
                    patientId = $ctrl.viewModel.selectedEvent && !$ctrl.viewModel.selectedEvent.id && $ctrl.viewModel.selectedEvent.patientId,
                    length = void 0;

                if ($ctrl.viewModel.waitlistItem && $ctrl.viewModel.waitlistItem.realAppointment) {
                    length = Date.parse($ctrl.viewModel.waitlistItem.realAppointment.endTime) - Date.parse($ctrl.viewModel.waitlistItem.realAppointment.startTime);
                }

                //id of event which has been rescheduled
                if (plannerHelpers.isQueueMode($ctrl.viewModel) && !isNew) {
                    processReplace(selectedEvent, providerId, resourceId, officeId, reschedulingId, copyAppointmentId, patientId);
                } else if (!isNew) {
                    apptUrl += eventId;

                    if (!selectedEvent.patientId && patientId) {
                        apptUrl += "?" + "patientId=" + patientId;
                    }

                    scheduleEventPopup.open(isNew, eventId, apptUrl);
                } else if (!providerId && !resourceId) {
                    $document.trigger('scheduleEvent:refresh');
                    showAlertModal("No Provider or Resource was chosen");
                } else {
                    apptUrl += "?" + getQuerystring(selectedEvent, providerId, resourceId, officeId, reschedulingId, copyAppointmentId, patientId, length);
                    if ($ctrl.viewModel.isSelectNewEventByPatient) {
                        var currentEvents = scheduler.getEvents(selectedEvent.start_date, selectedEvent.end_date);
                        var isOkToSchedule = true;
                        currentEvents.forEach(function (item) {
                            if (item.id != selectedEvent.id && item.schedulerUnitId === selectedEvent.schedulerUnitId) {
                                isOkToSchedule = false;
                            }
                        }, this);
                        if (isOkToSchedule) {
                            checkIfAllowScheduleOutsideTemplateSlots(isNew, eventId, apptUrl);
                        } else {
                            $document.trigger('scheduleEvent:refresh');
                            showAlertModal("Please choose a slot that doesn't have a scheduled appointment");
                        }
                    } else {
                        checkIfAllowScheduleOutsideTemplateSlots(isNew, eventId, apptUrl);
                    }
                }
            } else {
                if (eventId) {
                    apptUrl += eventId;
                } else if (eventId === 0) {
                    var _selectedEvent = $ctrl.viewModel.selectedEvent;
                    apptUrl += "?patientId=" + _selectedEvent.patientId;

                    if (_selectedEvent.officeId) {
                        apptUrl += "&officeId=" + _selectedEvent.officeId;
                    }

                    if (_selectedEvent.providerId) {
                        apptUrl += "&provider=" + _selectedEvent.providerId;
                    }
                    apptUrl += "&startDate=" + moment(_selectedEvent.start_date).format("L LT") + "&endDate=" + moment(_selectedEvent.end_date).format("L LT");
                }
                scheduleEventPopup.open(!eventId, eventId, apptUrl);
            }

            function checkIfAllowScheduleOutsideTemplateSlots(isNew, eventId, apptUrl) {
                if (!isNew) {
                    scheduleEventPopup.open(isNew, eventId, apptUrl);
                } else {
                    scheduleEventDataService.checkIfAllowScheduleOutsideTemplateSlots().then(function (response) {
                        if (response.isSuccess) {
                            if (response.data) {
                                scheduleEventPopup.open(isNew, eventId, apptUrl);
                            } else {
                                $document.trigger('scheduleEvent:refresh');
                                showAlertModal('You don\'t have permission to add appointment outside template slots.');
                            }
                        } else {
                            $document.trigger('scheduleEvent:refresh');
                            if (response.message) {
                                showAlertModal(response.message);
                            }
                        }
                    }, function (response) {
                        $document.trigger('scheduleEvent:refresh');
                        if (response.message) {
                            showAlertModal(response.message);
                        }
                    });
                }
            }

            function getQuerystring(selectedEvent, providerId, resourceId, officeId, reschedulingId, copyAppointmentId, patientId, length) {
                var querystring = ["startDate=" + moment(selectedEvent.start_date).format("L LT"), "endDate=" + moment(selectedEvent.end_date).format("L LT")];

                var aditions = getCurrentAditions();
                if (aditions) {
                    for (var a in aditions) {
                        querystring.push(aditions[a]);
                    }
                    if (aditions.providerId) {
                        querystring.push("Provider=" + providerId);
                    }
                }

                if (providerId && !aditions.providerId) {
                    querystring.push("Provider=" + providerId);
                }
                if (resourceId) {
                    querystring.push("Resource=" + resourceId);
                }
                if (officeId && !aditions.officeId) {
                    querystring.push("officeId=" + officeId);
                }
                if (reschedulingId) {
                    querystring.push("reschedulingId=" + reschedulingId);
                }
                if (copyAppointmentId) {
                    querystring.push("copyAppointmentId=" + copyAppointmentId);
                }
                if (patientId) {
                    querystring.push("patientId=" + patientId);
                }
                if (length) {
                    querystring.push("length=" + length);
                }

                ['recallItem', 'rescheduleItem', 'checkupItem', 'waitlistItem'].forEach(function (c) {
                    if ($ctrl.viewModel[c]) {
                        querystring.push(c + '=' + $ctrl.viewModel[c].id);
                    }
                });
                return querystring.join("&");
            }

            function getCurrentAditions() {
                var values = {};
                var aditionsRow = window.location.href.split('?')[1];
                if (aditionsRow && aditionsRow.indexOf('portalRequest') !== -1) {
                    var aditionItems = aditionsRow.split('&');

                    aditionItems.forEach(function (item) {
                        if (item.split('=').length > 1 && item.split('=')[0] !== 'startDate') {
                            var itemValue = item.split('#')[0];
                            values[item.split('=')[0]] = itemValue;
                        }
                    });
                }
                return values;
            }

            function getResourceId(name, unitParts) {
                if (unitParts) {
                    return unitParts[name];
                } else {
                    var resource = $ctrl.viewModel.lists.providerOptions.concat($ctrl.viewModel.lists.resourceOptions).filter(function (c) {
                        return c.type === name && c.checked;
                    });
                    return resource && resource[0] && resource[0].id;
                }
            }

            //when rescheduled or copied
            function processReplace(selectedEvent, providerId, resourceId, officeId, reschedulingId, copyAppointmentId) {
                var queue = plannerHelpers.getQueueInfo($ctrl.viewModel);
                if (!queue) return;

                if (selectedEvent.patientId) {
                    showAlertModal("Please choose a slot that doesn't have a scheduled appointment");
                    return;
                }

                apptUrl += "?" + getQuerystring(selectedEvent, providerId, resourceId, officeId, reschedulingId, copyAppointmentId);
                apptUrl += "&replacedAppt=" + selectedEvent.id;

                var templateAppointmentLength = selectedEvent.appointmentTypeDefaultTime;
                var rescheduleAppointmentLength = queue.appointmentType && queue.appointmentType.defaultTime;

                // No default time for rescheduled appointment/default time for template
                if (!rescheduleAppointmentLength && templateAppointmentLength) {
                    scheduleEventPopup.open(selectedEvent.text === 'New event', selectedEvent.id, apptUrl);
                    return;
                }

                // No default time for template/default time for rescheduled appointment
                if (!templateAppointmentLength && rescheduleAppointmentLength) {
                    scheduleEventPopup.open(selectedEvent.text === 'New event', selectedEvent.id, apptUrl + "&queueTypeId=" + queue.appointmentTypeId);
                    return;
                }

                // default time for both but they differ
                if (templateAppointmentLength != rescheduleAppointmentLength) {
                    popup.showMessageModal({
                        title: 'Confirmation',
                        message: 'You\'re trying to schedule a ' + rescheduleAppointmentLength + ' minute appointment into a ' + templateAppointmentLength + ' minute slot. Do you want to proceed?',
                        actions: [{
                            caption: 'Keep appointment length from original appointment',
                            style: 'success',
                            handler: function handler() {
                                scheduleEventPopup.open(selectedEvent.text === 'New event', selectedEvent.id, apptUrl + "&queueTypeId=" + queue.appointmentTypeId);
                            }
                        }, {
                            caption: 'Use new appointment length from the template slot',
                            style: 'info',
                            handler: function handler() {
                                scheduleEventPopup.open(selectedEvent.text === 'New event', selectedEvent.id, apptUrl);
                            }
                        }, {
                            caption: 'Cancel'
                        }]
                    });
                    return;
                }

                // default
                scheduleEventPopup.open(selectedEvent.text === 'New event', selectedEvent.id, apptUrl);
            }
        };

        function handleSubmit(viewModel, eventId) {
            //clear message
            viewModel.recallItem = null;
            viewModel.rescheduleItem = null;
            viewModel.checkupItem = null;
            viewModel.waitlistItem = null;

            if (viewModel.copyItem && window.history.replaceState) {
                window.history.replaceState({ event: eventId }, null, window.location.href.replace(/copyAppointmentId=\d+&?/gi, ''));
            }

            viewModel.copyItem = null;
        }

        function updateUnits() {
            //first - Providers, second - Resources
            var units = $ctrl.selectedUnits.filter(function (c) {
                return c.type === 'Provider';
            }).map(function (c) {
                return { id: c.id, key: 'Provider-' + c.id, name: c.name, type: c.type };
            }).concat($ctrl.selectedUnits.filter(function (c) {
                return c.type === 'Resource';
            }).map(function (c) {
                return { id: c.id, key: 'Resource-' + c.id, name: c.name, type: c.type, officeName: c.officeName, officeId: c.officeId };
            }));

            scheduler.updateCollection('units', units);
        }

        $ctrl.scrollPatientFlow = function (scrollRight) {
            var $table = $('.dhx_cal_data');
            var $header = $('.dhx_cal_header');
            var $container = $('.scheduler-container');

            if ($table.width() <= $container.width()) return;

            var delta = 70;
            var leftProp = $table.css('left');
            var prevShiftLeft = leftProp.substr(0, leftProp.length - 2) * -1;
            var shiftLeft;

            if (scrollRight) {
                shiftLeft = delta + prevShiftLeft + $container.width() < $table.width() ? delta + prevShiftLeft : $table.width() - $container.width();
            } else {
                shiftLeft = prevShiftLeft < delta ? 0 : prevShiftLeft - delta;
            }

            $table.css('left', shiftLeft * -1 + 'px');
            $header.css('left', shiftLeft * -1 - 1 + 'px');
        };
    }
})();