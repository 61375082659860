'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').directive('scheduleTemplate', scheduleTemplateDirective);

    function scheduleTemplateDirective() {
        return {
            restrict: 'E',
            scope: false,
            templateUrl: 'scheduler/scheduleTemplate/scheduleTemplatePopup/scheduleTemplatePopup.directive.html',
            link: function link($scope, $element, $attrs, $controller) {
                var el = $element[0];
                var $form = $('form', $element),
                    $metaType = $('[name="MetaType"]', $element);
                var $appointmentType = $('[name="appointment_type"]', $element);
                var selectedEvent = null;

                $scope.popup = {
                    saveCommand: function saveCommand() {
                        if (!$scope.schedule_template_popup_form.$valid) {
                            $form.addClass('show-validation-error');
                            return;
                        } else {
                            $form.removeClass('show-validation-error');
                        }

                        if (!widget.updateEventWithPopupForm()) {
                            return;
                        }
                        scheduler.endLightbox(true, el);
                        widget.clearViewModel();
                    },
                    cancelCommand: function cancelCommand() {
                        scheduler.endLightbox(false, el);
                        widget.clearViewModel();
                    },
                    deleteCommand: function deleteCommand() {
                        scheduler.endLightbox(false, el);
                        scheduler.deleteEvent($scope.scheduler.currentEvent.id);
                        widget.clearViewModel();
                    }
                };

                var widget = {
                    clearViewModel: function clearViewModel() {
                        $scope.scheduler.currentEvent = null;
                        selectedEvent = null;
                    },
                    updateEventWithPopupForm: function updateEventWithPopupForm() {
                        // additional validation
                        if ($scope.popup.startTime.diff($scope.popup.endTime) >= 0) {
                            alert("End Time must be later than Start Time.");
                            return false;
                        }

                        $.extend(selectedEvent, {
                            officeId: $scope.scheduler.currentEvent.officeId,
                            appointmentTypeId: $scope.scheduler.currentEvent.appointmentTypeId,
                            scheduleTemplateId: $scope.applyViewModel.scheduleTemplateId,
                            start_date: $scope.popup.startTime.toDate(),
                            end_date: $scope.popup.endTime.toDate(),
                            metaTypeId: $scope.scheduler.currentEvent.metaTypeId,
                            allowNewAppointments: $scope.scheduler.currentEvent.allowNewAppointments,
                            sendToRescheduleQueue: $scope.scheduler.currentEvent.sendToRescheduleQueue
                        });
                        return true;
                    },
                    clearValidationErrors: function clearValidationErrors() {
                        $('.input-validation-error', $element).removeClass('input-validation-error');
                    },
                    onAppointmentTypeChanged: function onAppointmentTypeChanged() {
                        var defaultTime = $appointmentType.find(':selected').attr('data-default-time');
                        if (!defaultTime) {
                            defaultTime = 0;
                        }

                        $scope.popup.endTime = moment($scope.popup.startTime).add(defaultTime, 'minutes');
                        if (!$scope.$$phase) {
                            $scope.$apply();
                        }
                    },
                    populateFormWithEvent: function populateFormWithEvent() {
                        var isNew = $scope.isSelectedEventNew = $scope.scheduler.currentEvent.text === 'New event';
                        if (isNew) {
                            // default values
                            $scope.scheduler.currentEvent.metaTypeId = 0; // Appointment

                            var events = scheduler.getEvents(new Date($scope.scheduler.currentEvent.start_date.toDateString()), $scope.scheduler.currentEvent.start_date);
                            events = events.sort(function (a, b) {
                                return a.end_date < b.end_date ? 1 : -1;
                            });
                            if (events.length > 0) {
                                $scope.scheduler.currentEvent.officeId = events[0].officeId;
                                $scope.scheduler.currentEvent.appointmentTypeId = events[0].appointmentTypeId;
                            }
                        }
                    }
                };

                $scope.updateOnStart = function () {
                    if ($scope.popup.startTime.isAfter($scope.popup.endTime)) {
                        widget.onAppointmentTypeChanged();
                    }
                };

                $appointmentType.on('change', widget.onAppointmentTypeChanged);

                scheduler.showLightbox = function (eventId) {
                    selectedEvent = scheduler.getEvent(eventId);
                    //clone event
                    var currentEvent = $scope.scheduler.currentEvent = $.extend(true, {}, selectedEvent);

                    if (!currentEvent.officeId) currentEvent.officeId = '';
                    if (!currentEvent.appointmentTypeId) currentEvent.appointmentTypeId = '';

                    var startDateTimeMoment = moment($scope.scheduler.currentEvent.start_date);
                    var endDateTimeMoment = moment($scope.scheduler.currentEvent.end_date);

                    $scope.popup.startDate = startDateTimeMoment;
                    $scope.popup.day = startDateTimeMoment.weekday();
                    $scope.popup.startTime = startDateTimeMoment;
                    $scope.popup.endTime = endDateTimeMoment;

                    widget.populateFormWithEvent();

                    $scope.$apply();

                    // MISC PLUGINS
                    $metaType.multiselect({ buttonClass: 'btn btn-primary' });
                    $metaType.multiselect('refresh');

                    widget.clearValidationErrors();
                    scheduler.startLightbox(eventId, el);

                    $scope.$watch('popup.day', function (day) {
                        $scope.popup.startDate.day(day);
                        $scope.popup.endTime.day(day);
                    });
                };
            }
        };
    }
})();