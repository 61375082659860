'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('wafMarketinglistListmembers', wafMarketinglistListmembers);

    wafMarketinglistListmembers.$inject = ['$uibModal', '$document', '$compile'];

    function wafMarketinglistListmembers($uibModal, $document, $compile) {
        var $block;
        var $deleteBtn;
        var $addToCampaign;

        return {
            restrict: 'A',
            link: function link(scope, element, attrs) {
                $block = element;

                addPageSizeSelector();

                $addToCampaign = $block.find('[data-command-name="AddToCampaign"]').attr('disabled', 'disabled').off().on('click', function (e) {
                    e.preventDefault();
                    var modalInstance = $uibModal.open({
                        animation: false,
                        backdrop: 'static',
                        size: 'sm',
                        component: 'wafSelectCampaign',
                        resolve: {}
                    });

                    modalInstance.result.then(function (data) {
                        if (!data.campaignId && !data.campaignName) {
                            return;
                        }

                        addMemberToCampaign(data.campaignId, data.campaignName);
                    });
                });

                $deleteBtn = $block.find('[data-command-name="Delete"]').attr('disabled', 'disabled').off().on('click', function (e) {
                    e.preventDefault();
                    deleteMembers();
                });

                $block.on('click', '.multi-choice-checkbox', function () {
                    if (this.checked) {
                        $deleteBtn.removeAttr('disabled');
                        $addToCampaign.removeAttr('disabled');
                    } else if (!$block.find('.multi-choice-checkbox:checked').length) {
                        $deleteBtn.attr('disabled', 'disabled');
                        $addToCampaign.attr('disabled', 'disabled');
                    }
                });

                $block.find('.multi-choice-common-checkbox').on('click', function () {
                    if (this.checked) {
                        $deleteBtn.removeAttr('disabled');
                        $addToCampaign.removeAttr('disabled');
                    } else {
                        $deleteBtn.attr('disabled', 'disabled');
                        $addToCampaign.attr('disabled', 'disabled');
                    }
                });

                function addPageSizeSelector() {
                    var cmdPanel = $block.find('.with-commands');
                    var $pageSizeSelector = $('<div ls-page-size-selector></div>');
                    cmdPanel.prepend($pageSizeSelector);

                    $compile($pageSizeSelector)(scope);
                }
            }
        };

        function extractSelectIds() {
            return $.map($block.find('.multi-choice-checkbox:checked'), function (elem) {
                return parseInt($(elem).closest('.search-result-table__row').attr('data-id'));
            });
        }

        function deleteMembers() {
            var chosen = $block.find('input.multi-choice-checkbox:checked');

            if (!chosen.length) {
                return;
            }

            var chosenMembers = [];

            chosen.each(function (i) {
                var member = $(this).parents('.search-result-table__row:first').attr('data-id');
                if (member) {
                    chosenMembers.push(parseInt(member));
                }
            });

            $.post("/MarketingListMember/DeleteMembers", { ids: chosenMembers }).done(function (response) {
                var $linkedSearch = $block.closest('#LinkedForm_ListMembers');
                if ($linkedSearch.length) {
                    window.refreshLinkedSearch($linkedSearch);
                }
            });
        }

        function addMemberToCampaign(campaignId, campaignName) {
            var chosenMembers = extractSelectIds();

            if (!chosenMembers.length) {
                return;
            }

            var $pageContent = $block.parents('.page-content:first');

            $pageContent.addClass('loading-container');

            $.post("/CampaignMember/AddMarketingListMemberToCampaign", { marketingListMemberIds: chosenMembers, campaignId: campaignId }).done(function (response) {
                var $alert = $('<div class="alert" role="alert"></div>');
                var $alertMessage = response.isSuccess ? response.data.quantity + ' patients added to the campaign <a href="Campaign/Details/' + campaignId + '" class="alert-link">' + campaignName + '</a> successfully!' : response.error.message;
                $alert.append($alertMessage);
                $alert.addClass(response.isSuccess ? 'alert-success' : 'alert-danger');

                popup.showMessageModal({
                    title: 'Adding patients to capmaign result',
                    message: $alert
                });
            }).always(function () {
                $pageContent.removeClass('loading-container');
            });
        }
    }
})();