'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').factory('scheduleTemplateSettingsService', scheduleTemplateSettingsService);

    function scheduleTemplateSettingsService() {
        return {
            init: function init($scope, scheduler) {
                ////// Data Processor
                schedulerDataProcessor.init($scope.scheduler.controller);

                $.extend(scheduler.config, schedulerDefaultConfig);

                scheduler.xy.scale_width = 110;

                var templates = {
                    week_scale_date: function week_scale_date(date) {
                        return scheduler.date.date_to_str('%l')(date);
                    },
                    event_header: function event_header(start, end, schedulerEvent) {
                        return '<span class="title">' + (schedulerEvent.appointmentTypeName ? schedulerEvent.appointmentTypeName : '') + '</span>';
                    },
                    event_text: function event_text(start, end, schedulerEvent) {
                        return '<div class="description"><span>' + (schedulerEvent.officeName ? 'Office: ' + schedulerEvent.officeName : '') + '</span></div>';
                    },
                    event_class: function event_class(start, end, schedulerEvent) {
                        var builder = "";
                        if (!isUndefinedOrNull(schedulerEvent.metaTypeId)) {
                            builder += " meta-type-" + schedulerEvent.metaTypeId;
                        }
                        return builder;
                    },
                    hour_scale: function hour_scale(date) {
                        return scheduler.templates.hour_scale_by_zoom(date, $scope.scheduler.zoomCoef, $scope.scheduler.hourHeightSize);
                    }
                };

                scheduler.attachEvent('onTemplatesReady', function () {
                    $.extend(scheduler.templates, schedulerDefaultTemplates, templates);
                });
            }
        };
    }
})();