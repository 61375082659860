'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').component('searchTemplateSlot', {
        bindings: {
            initialPeriods: '<',
            initialDurations: '<',
            schedulerModel: '<',
            isSearching: '=',
            searchSlotParams: '=',
            getRequestParams: '&',
            getPlannerState: '&getCurrentState',
            updatePlannerState: '&updateState'
        },
        templateUrl: 'scheduler/searchSlot.component/searchSlot.component.html',
        controller: SearchTemplateSlotController
    });

    SearchTemplateSlotController.$inject = ['$scope', '$document', '$q', 'scheduleEventDataService'];

    function SearchTemplateSlotController($scope, $document, $q, scheduleEventDataService) {
        var $ctrl = this,
            plannerStateParams = void 0,
            lastCombinedModel = void 0,
            $advancedParams = void 0,
            weekDaysArray = ['1', '2', '3', '4', '5', '6', '7'];

        $ctrl.$onInit = function () {
            $ctrl.isSearchButtonDisabled = false;
            $ctrl.viewModel = {
                isTemplateSlotMode: true,
                initialPeriod: $ctrl.initialPeriods[0].systemName,
                initialDuration: $ctrl.initialDurations[0].systemName,
                isAmChecked: true,
                isPmChecked: true,
                initialTime: null,
                skippedEventIds: [],
                weekDays: Array.from(weekDaysArray)
            };
            lastCombinedModel = getCombinedModel();
        };

        $ctrl.changeMode = function (isTemplateSlotMode) {
            return $ctrl.viewModel.isTemplateSlotMode = isTemplateSlotMode;
        };

        $ctrl.searchSlot = function () {
            $ctrl.isSearchButtonDisabled = true;
            $ctrl.isSearching = true;

            var params = $ctrl.getRequestParams();
            params.searchParams = $ctrl.viewModel;

            if (!plannerStateParams) {
                plannerStateParams = $ctrl.getPlannerState();
                $document.on('scheduleEvent:backgroundSubmit', stopSearchSlot);
            }

            $q.when($ctrl.viewModel.isTemplateSlotMode ? searchNextTemplateSlot(params) : searchNextOpenSlot(params)).finally(function () {
                $ctrl.isSearchButtonDisabled = false;
                $ctrl.isSearching = false;
            });
        };

        $ctrl.$doCheck = function () {
            var currentCombinedModel = getCombinedModel();
            if (lastCombinedModel !== currentCombinedModel && $ctrl.viewModel.initialTime) {
                clearSearchSlotParams();
                lastCombinedModel = currentCombinedModel;
            }
        };

        $ctrl.showAdvanced = function ($e) {
            if (!$advancedParams) {
                $advancedParams = initCustomPopup('modal-advanced-filters');
            }

            $advancedParams.popup('show');
        };

        $ctrl.checkWeekDay = function ($e) {
            if ($e.toElement.checked) {
                $ctrl.viewModel.weekDays.push($e.toElement.value);
            } else {
                $ctrl.viewModel.weekDays.splice($ctrl.viewModel.weekDays.indexOf($e.toElement.value), 1);
            }
        };

        $ctrl.onCheckAll = function (isAll) {
            if (isAll) {
                $ctrl.viewModel.weekDays = Array.from(weekDaysArray);
            } else {
                $ctrl.viewModel.weekDays = [];
            }
        };

        $ctrl.getIsChecked = function (val) {
            return $ctrl.viewModel.weekDays.indexOf(val) > -1;
        };

        $scope.$on('searchSlot:stop', stopSearchSlot);

        $scope.$on('scheduler:dateChangedManually', function () {
            $ctrl.viewModel.initialTime = moment().format('LLL');
        });

        function stopSearchSlot() {
            resetView();
            clearSearchSlotParams();
            $ctrl.updatePlannerState({ stateParams: plannerStateParams });
            plannerStateParams = null;
            scheduler.scrollUnit(0);
            $document.off('scheduleEvent:backgroundSubmit', stopSearchSlot);
        }

        function searchNextTemplateSlot(params) {
            var deferred = $q.defer();

            if (!params.appointmentTypes.length) {
                showAlertModal('For finding an empty appointment please choose appointmenmt type');
                deferred.reject(null);
            } else {
                scheduleEventDataService.searchNextTemplateSlot(params).then(function (data) {
                    var templateSlot = data.templateSlot;
                    if (templateSlot.eventId) {
                        $ctrl.searchSlotParams.searchedNextSlotId = templateSlot.eventId;
                        var newDate = new Date(templateSlot.startTime);
                        var props = scheduler._props[scheduler._mode];

                        if (props) {
                            var step = props.step,
                                size = props.size,
                                position = props.position;

                            var providerOption = props.options.find(function (o) {
                                return o.id == templateSlot.providerId;
                            });
                            var providerPosition, providerDelta;

                            providerPosition = props.order[providerOption.key];
                            if (providerPosition > props.size + props.position || props.position > providerPosition) {
                                providerDelta = providerPosition - props.position - props.size / 2;
                            }
                        }

                        if (params.filters.providerIds.length == 1) {
                            scheduler.setCurrentView(newDate, 'week');
                            $ctrl.viewModel.initialTime = templateSlot.startTime;
                        } else {
                            scheduler.setCurrentView(newDate);
                            if (providerDelta) {
                                scheduler.scrollUnit(providerDelta);
                            }

                            $ctrl.viewModel.skippedEventIds.push(templateSlot.eventId);
                        }

                        var hour = moment(templateSlot.startTime).get('hour');
                        $('.dhx_cal_data').scrollTop(scheduler.config.hour_size_px * hour);

                        scheduler.updateView();
                        $document.on('keyup', keyup);

                        deferred.resolve(templateSlot.eventId);
                    } else {
                        showAlertModal(templateSlot.message);
                        deferred.reject(null);
                    }
                });
            }

            return deferred.promise;
        }

        function searchNextOpenSlot(params) {
            if (!params.searchParams.initialTime) {
                params.searchParams.initialTime = moment().format('LLL');
            }

            return scheduleEventDataService.searchNextOpenSlot(params).then(function (data) {
                if (data.openSlot.startTime) {
                    $ctrl.viewModel.initialTime = data.openSlot.endTime;

                    var markTimespan = {
                        start_date: moment(data.openSlot.startTime).toDate(),
                        end_date: moment(data.openSlot.endTime).toDate(),
                        css: 'searched-open-slot',
                        html: '<span>Open Slot</span>'
                    };

                    if (moment(data.openSlot.startTime) < $ctrl.schedulerModel.startDate || moment(data.openSlot.startTime) > $ctrl.schedulerModel.endDate || $ctrl.schedulerModel.mode != 'week') {
                        $ctrl.searchSlotParams.foundOpenSlotMarkTimespan = markTimespan;
                    } else {
                        if ($ctrl.searchSlotParams.markedTimespanId) {
                            scheduler.deleteMarkedTimespan($ctrl.searchSlotParams.markedTimespanId);
                            delete $ctrl.searchSlotParams.markedTimespanId;
                        }

                        $ctrl.searchSlotParams.markedTimespanId = scheduler.addMarkedTimespan(markTimespan);
                    }

                    scheduler.setCurrentView(new Date(data.openSlot.startTime), 'week');

                    var hour = moment(data.openSlot.startTime).get('hour');
                    $('.dhx_cal_data').scrollTop(scheduler.config.hour_size_px * hour);

                    $document.on('keyup', keyup);
                } else {
                    showAlertModal(data.openSlot.message);
                }
            });
        }

        function keyup(e) {
            if (e.keyCode === 27) {
                stopSearchSlot();
            }
            $document.off('keyup', keyup);
        }

        function resetView() {
            scheduler.setCurrentView($ctrl.viewModel.initialTime || new Date());
            scheduler.scrollUnit(-2000);
        }

        function clearSearchSlotParams() {
            $ctrl.searchSlotParams.searchedNextSlotId = null;
            $ctrl.searchSlotParams.skippedEventIds = [];
            if ($ctrl.searchSlotParams.markedTimespanId) {
                scheduler.deleteMarkedTimespan($ctrl.searchSlotParams.markedTimespanId);
                $ctrl.searchSlotParams.markedTimespanId = null;
                scheduler.updateView();
            }
            $ctrl.viewModel.initialTime = null;
            $ctrl.viewModel.skippedEventIds = [];
        }

        function getCombinedModel() {
            return $ctrl.viewModel.initialPeriod + ' ' + $ctrl.viewModel.isAmChecked + ' ' + $ctrl.viewModel.isPmChecked;
        }
    }
})();