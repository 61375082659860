'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').directive('dhxScheduler', dhxSchedulerDirective);

    dhxSchedulerDirective.$inject = ['$compile'];

    function dhxSchedulerDirective($compile) {
        return {
            restrict: 'A',
            scope: false,
            transclude: true,
            template: '<div class="dhx_cal_navline clearfix" ng-transclude></div><div class="dhx_cal_header"></div><div class="dhx_cal_data"></div>',
            link: function link($scope, $element, $attrs, $controller) {
                //styling for dhtmlx scheduler
                $element.addClass("dhx_cal_container");

                if ($scope.scheduler.settingsService) {
                    $scope.scheduler.settingsService.init($scope, scheduler);
                }

                //default state of the scheduler
                if (!$scope.scheduler) $scope.scheduler = {};
                $scope.scheduler.mode = $scope.scheduler.mode || "week";
                $scope.scheduler.date = $scope.scheduler.date || new Date();

                //watch data collection, reload on changes
                $scope.$watch($attrs.data, function (collection) {
                    scheduler.clearAll();
                    scheduler.parse(collection, "json");
                    scheduler.updateView();
                });

                ////size of scheduler
                $scope.$watch(function () {
                    return $element[0].offsetWidth + "." + $element[0].offsetHeight;
                }, function () {
                    scheduler.setCurrentView();
                });

                ////// Week View
                scheduler.ignore_week = function (date) {
                    //if (date.getDay() === 0)
                    //    return true;
                };

                //init scheduler
                scheduler.init($element[0], $scope.scheduler.date, $scope.scheduler.mode);

                // CALENDAR
                if ($scope.scheduler.calendarContainer) {
                    var schedulerCalendar = scheduler.renderCalendar({
                        container: $scope.scheduler.calendarContainer,
                        date: scheduler._date,
                        navigation: true,
                        handler: function handler(date) {
                            scheduler.setCurrentView(date);
                            $scope.$parent.$broadcast('scheduler:dateChangedManually');
                        }
                    });
                    scheduler.linkCalendar(schedulerCalendar);
                }
            }
        };
    }
})();