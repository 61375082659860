'use strict';

(function () {
    'use strict';

    angular.module('external.module').factory('externalDataService', externalDataService);

    externalDataService.$inject = ['$http', '$q'];

    function externalDataService($http, $q) {
        var service = {
            getPatientInfoViewModel: getPatientInfoViewModel,
            getAppointmentRequestViewModel: getAppointmentRequestViewModel,
            getPaymentViewModel: getPaymentViewModel,
            getAppointmentRequests: getAppointmentRequests,
            getAppointments: getAppointments,
            savePatientData: savePatientData,
            requestAppointment: requestAppointment,
            executePayment: executePayment
        };

        return service;

        function getPatientInfoViewModel(token) {
            return getData({
                url: 'PatientInfoExtension/Data?token=' + token
            });
        }

        function getAppointmentRequestViewModel(token) {
            return getData({
                url: 'AppointmentExtension/Data?token=' + token
            });
        }

        function getPaymentViewModel(token) {
            return getData({
                url: 'PaymentsExtension/Data?token=' + token
            });
        }

        function getAppointmentRequests(token, dateFrom, dateTo) {
            return getData({
                url: 'AppointmentExtension/AppointmentRequests?dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&token=' + token
            });
        }

        function getAppointments(token, dateFrom, dateTo) {
            return getData({
                url: 'AppointmentExtension/Appointments?dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&token=' + token
            });
        }

        function savePatientData(token, data) {
            return sendData({
                url: 'PatientInfoExtension/PatientInfo',
                token: token,
                data: data
            });
        }

        function requestAppointment(token, data) {
            return sendData({
                url: 'AppointmentExtension/CreateAppointment',
                token: token,
                data: data
            });
        }

        function executePayment(token, data) {
            return sendData({
                url: 'PaymentsExtension/AcceptPayment',
                token: token,
                data: data
            });
        }

        function getData(request) {
            return $http({
                method: 'GET',
                url: request.url,
                responseType: 'json'
            }).then(function (response) {
                var wrapper = response.data;
                if (!wrapper || !wrapper.isSuccess) {
                    if (wrapper && wrapper.error) {
                        return $q.reject(wrapper.error);
                    } else {
                        return $q.reject('An error occured. Please contact the support.');
                    }
                }
                return wrapper.data;
            }, function () {
                return $q.reject(request);
            });
        }

        function sendData(request) {
            return $http({
                method: 'POST',
                url: request.url + '?token=' + request.token,
                responseType: 'json',
                data: request.data
            }).then(function (response) {
                var wrapper = response.data;
                if (!wrapper || !wrapper.isSuccess) {
                    if (wrapper && wrapper.error) {
                        return $q.reject(wrapper.error);
                    } else {
                        return $q.reject('An error occured. Please contact the support.');
                    }
                }
                return wrapper.data;
            }, function () {
                return $q.reject(request);
            });
        }
    }
})();