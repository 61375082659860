'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').component('pqSearchTabs', {
        bindings: {
            tabs: '<',
            currentTab: '<',
            formName: '@',
            formFieldName: '@',
            formAction: '@'
        },
        templateUrl: 'components/search/tabs.component/tabs.component.html',
        controller: SearchTabsController
    });

    function SearchTabsController() {
        var $ctrl = this;

        $ctrl.childTabs = [];
        var selectedTabUrl;

        $ctrl.tabs.forEach(function (item) {

            trySetSelectedTabURL(item);
            if (item.childTabs && item.childTabs.length > 0) {
                item.childTabs.forEach(function (subtabs) {
                    trySetSelectedTabURL(subtabs);
                });
            }
        });

        function trySetSelectedTabURL(tab) {

            var urlParts = tab.url.split('?');
            if (window.location.pathname.indexOf(urlParts[0]) > -1) {
                if (urlParts.length === 1) {
                    selectedTabUrl = tab.url;
                } else if (urlParts.length === 2) {
                    var parsedSearch = parseUrlParams(window.location.search);
                    var parsedItem = parseUrlParams(tab.url);

                    if (parsedItem && parsedSearch) {
                        var match = true;

                        for (var key in parsedItem) {
                            match = match && parsedSearch[key] == parsedItem[key];
                        }

                        if (match) selectedTabUrl = tab.url;
                    }
                }
            }
        }

        function parseUrlParams(tabURL) {
            var twoParts = tabURL.split('?');
            if (twoParts.length < 2) return null;
            var queryPart = twoParts[twoParts.length - 1];
            return JSON.parse('{"' + decodeURI(queryPart).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
        }

        $ctrl.isTabSelected = function (tab) {

            if (tab.childTabs && tab.childTabs.length > 0) {
                for (var i = 0; i < tab.childTabs.length; i++) {
                    if (this.isTabSelected(tab.childTabs[i])) {

                        this.childTabs = tab.childTabs;

                        return true;
                    }
                }
            } else {

                var tabURL = tab.url;

                if (selectedTabUrl && selectedTabUrl === tabURL || !selectedTabUrl && tab.isDefault) {
                    var additionalParam = parseUrlParams(tabURL);
                    var $form = $('.global-search-form');
                    if ($form.length > 0 && additionalParam) {
                        for (var paramName in additionalParam) {
                            var $input = $form.find('[name="' + paramName + '"]');
                            if ($input.length == 0) {
                                $input = $("<input type='hidden' name='" + paramName + "'/>");
                                $form.append($input);
                            }
                            $input.val(additionalParam[paramName]);
                        }
                    }
                    return true;
                }
            }

            return false;
        };

        $ctrl.selectTab = function (e, url) {
            e.preventDefault();
            $(".page-content").addClass('loading-container');
            window.location.href = url;
        };
    }
})();