"use strict";

(function () {
    "use strict";

    //TODO old logic need refact

    angular.module("allMedsApp").directive("draggablePopup", function () {
        return {
            restrict: "A",
            link: function link(scope, element) {
                element.parent().draggable();
            }
        };
    }).controller("ProductDetailsController", ['$scope', '$uibModal', function ($scope, $uibModal) {
        $scope.calculate = function () {
            $uibModal.open({
                animation: false,
                templateUrl: "product/tax-popup-tpl.html",
                controller: "TaxOverridePopupController"
                //size: size
            });
        };
    }]).controller("TaxOverridePopupController", ['$scope', '$http', '$uibModalInstance', function ($scope, $http, $uibModalInstance) {
        $scope.submitted = false;
        $scope.valid = true;
        $scope.numeric = /^\d+$/;
        $scope.model = {
            state: "",
            zip: ""
        };

        $scope.tax = 0;
        $scope.getTax = function () {
            if (!$scope.tax || isNaN($scope.tax) || $scope.tax === 0) return "No tax found.";

            return $scope.tax + " %";
        };

        $scope.calculate = function () {
            $scope.submitted = true;
            $scope.valid = true;

            if (!$scope.taxOverrideForm.$valid) {
                $scope.valid = false;
                return;
            }

            var pId = $("#Details_R1_Id").val();
            var params = {
                url: "/Product/TaxOverride",
                method: "POST",
                data: {
                    Zip: $scope.model.Zip,
                    State: $scope.model.State,
                    ProductId: parseInt(pId)
                }
            };

            $http(params).success(function (data) {
                $scope.tax = data.tax;
            });
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss();
        };
    }]);
})();