'use strict';

(function () {
    'use strict';

    angular.module('table.module').factory('tableDataService', tableDataService);

    tableDataService.$inject = ['$http', '$q'];

    function tableDataService($http, $q) {
        var service = {
            getViewModel: getViewModel
        };

        return service;

        ////////////       

        function getViewModel(request, cancellationPromise) {

            var defer = $q.defer();

            $http({
                method: 'POST',
                url: request.action,
                responseType: 'json',
                timeout: cancellationPromise,
                data: request.data
            }).then(function (response) {
                var wrapper = response.data;
                if (!wrapper || !wrapper.isSuccess) {
                    defer.reject(wrapper.error);
                } else {
                    defer.resolve(wrapper.data);
                }
            }, function () {
                defer.reject(request);
            });

            return defer.promise;
        }
    }
})();