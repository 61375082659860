'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('negativeBalanceOffset', negativeBalanceOffset);

    function negativeBalanceOffset() {
        return {
            restrict: 'A',
            require: "pqTable",
            scope: false,
            link: function link(scope, element, attrs, tableCtrl) {
                scope.$on('Offset', function (e, row) {
                    var params = e.targetScope.$ctrl.field.params;

                    var modal = params.count === '1' ? allMeds.angularControlFactory.openNegativeBalanceOffsetControl(params.lineId) : allMeds.angularControlFactory.openNegativeBalanceVisitLinesControl(tableCtrl.tableInfo.filters, row[0].value);

                    modal.result.then(function (changed) {
                        return changed && tableCtrl.refresh();
                    });
                });
            }
        };
    }
})();