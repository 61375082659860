'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('wafCampaignmemberCampaignmemberincampaign', wafcampaignmembercampaignmemberincampaign);

    wafcampaignmembercampaignmemberincampaign.$inject = ['$uibModal', '$document', '$compile'];

    function wafcampaignmembercampaignmemberincampaign($uibModal, $document, $compile) {
        var $block;
        var $deleteBtn;
        var $campaignInput;

        return {
            restrict: 'A',
            link: function link(scope, element, attrs) {
                $block = element;
                addPageSizeSelector();
                $campaignInput = $('#LinkedForm_CampaignMemberInCampaign').data('model');

                $deleteBtn = $block.find('[data-command-name="Delete"]').attr('disabled', 'disabled').off().on('click', function (e) {
                    e.preventDefault();
                    var deleteMembers = extractIdsFromRows($block);
                    if (deleteMembers.length > 0) {
                        $.post("/CampaignMember/DeleteMembersAsync", {
                            campaignId: $campaignInput.foreignKeyValue,
                            ids: deleteMembers
                        }).done(function (response) {
                            if (response.isSuccess) {
                                var linkedChannel = new Channel('campaignMembers');
                                popup.showMessageModal({
                                    title: 'Success',
                                    message: 'The transactions has been deleted successfully.',
                                    actions: [{
                                        caption: "OK",
                                        style: 'success',
                                        handler: function handler() {
                                            linkedChannel.trigger("refresh");
                                        }
                                    }]
                                });
                            } else {
                                popup.showMessageModal({
                                    title: 'Error',
                                    message: response.errorMessage,
                                    actions: [{
                                        caption: "OK",
                                        style: 'danger'
                                    }]
                                });
                            }
                        });
                    } else {
                        popup.showMessageModal({
                            title: 'Warning',
                            message: 'Please select at least one record.',
                            actions: [{
                                caption: "OK",
                                style: 'success'
                            }]
                        });
                    }
                });

                $block.on('click', '.multi-choice-checkbox', function () {
                    if (this.checked) {
                        $deleteBtn.removeAttr('disabled');
                    } else if (!$block.find('.multi-choice-checkbox:checked').length) {
                        $deleteBtn.attr('disabled', 'disabled');
                    }
                });

                $block.find('.multi-choice-common-checkbox').on('click', function () {
                    if (this.checked) {
                        $deleteBtn.removeAttr('disabled');
                    } else {
                        $deleteBtn.attr('disabled', 'disabled');
                    }
                });

                function addPageSizeSelector() {
                    var cmdPanel = $block.find('.with-commands');
                    var $pageSizeSelector = $('<div ls-page-size-selector></div>');
                    cmdPanel.prepend($pageSizeSelector);
                    $compile($pageSizeSelector)(scope);
                }
                function extractIdsFromRows($form) {
                    return _.map($form.find('.multi-choice-checkbox:checked'), getIds);
                }

                function getIds(elem) {
                    return $(elem).closest('.search-result-table__row').attr('data-id');
                }
            }
        };
    }
})();