'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').controller('scheduleTemplateCtrl', scheduleTemplateController);

    scheduleTemplateController.$inject = ['$scope', '$http', 'scheduleTemplateDataService', 'scheduleTemplateSettingsService', 'scheduleTemplateApplicationsApi'];

    function scheduleTemplateController($scope, $http, scheduleTemplateDataService, scheduleTemplateSettingsService, scheduleTemplateApplicationsApi) {
        var controls = {
            $applyForm: $('#provider_apply_form'),
            $conflictDecisionPopup: $('#schedule-template-conflict-decision-popup'),
            $applicationErrorPopup: $('#schedule-template-application-error-popup'),
            $extendDatePopup: $('#schedule-template-extend-date-popup')
        };

        $scope.init = function (scheduleTemplateId) {
            $scope.viewModel = {};
            $scope.scheduler = {
                hourHeightSize: 44, //px                
                currentEvent: null,
                controller: 'ScheduleTemplate',
                zoomCoef: null,
                mode: 'week',
                date: moment.utc().format('L'),
                startDate: null,
                endDate: null,
                settingsService: scheduleTemplateSettingsService
            };
            $scope.applyViewModel = {
                scheduleTemplateId: scheduleTemplateId,
                providerId: null,
                frequency: '',
                startDate: moment().toDate(),
                expirationDate: moment().add(2, 'weeks').toDate(),
                days: [],
                isDisabled: false
            };

            $scope.isLoading = true;
            scheduleTemplateDataService.getViewModel().then(function (response) {
                $scope.viewModel = response;

                $scope.reLoadData(scheduleTemplateId);

                $scope.applyViewModel.provider = $scope.viewModel.providers[0];
                $scope.scheduler.zoomCoef = $scope.viewModel.defaultSchedulerZoom ? $scope.viewModel.defaultSchedulerZoom / 100 : 6;

                reloadApplications();

                $scope.applyViewModel.frequency = $scope.viewModel.frequencyList[0].systemName;
                $scope.viewModel.days.forEach(function (day) {
                    day.checked = true;
                    day.redirect = day.value;
                });
            }).finally(function () {
                $scope.isLoading = false;
            });

            $scope.lists = {
                metaTypes: [{ id: 0, name: 'Appointment Slot' }, { id: 1, name: 'Unavailable Slot' }],
                specificWeeks: [{ name: '1st Week', id: 1 }, { name: '2nd Week', id: 2 }, { name: '3rd Week', id: 3 }, { name: '4th Week', id: 4 }, { name: '5th Week', id: 5 }]
            };
        };

        $scope.reLoadData = function (scheduleTemplateId) {
            $scope.isLoading = true;

            scheduleTemplateDataService.getData(scheduleTemplateId).then(function (response) {
                $scope.data = response;
            }).finally(function () {
                $scope.isLoading = false;
            });
        };

        $scope.onApplyCheckedDays = function (isConflictResolutionConfirmed) {
            if (!controls.$applyForm.valid()) {
                return false;
            }

            var days = $scope.applyViewModel.days;

            if (days.length === 0) {
                alert("Please, choose the days you want to apply.");
                return false;
            }

            var dayCounters = [0, 0, 0, 0, 0, 0, 0];
            for (var i = 0; i < days.length; i++) {
                dayCounters[days[i].day] += 1;
            }
            if (dayCounters.find(function (counter) {
                return counter > 1;
            }) !== undefined) {
                alert("You can't apply two columns to the same day");
                return false;
            }

            if (moment($scope.applyViewModel.expirationDate) < moment($scope.applyViewModel.startDate)) {
                alert("Expiration date should be later than start date");
                return false;
            }

            if (isConflictResolutionConfirmed) $scope.applyViewModel.isConflictResolutionConfirmed = isConflictResolutionConfirmed;

            $scope.applyViewModel.isDisabled = true;

            var response = scheduleTemplateDataService.applyCheckedDays($scope.applyViewModel, function (data) {
                applyTemplateResponse(data, $scope.onApplyCheckedDays);
                $scope.applyViewModel.isDisabled = false;
            });
        };

        // has the same method in scheduleTemplateApplications.js
        function applyTemplateResponse(response, onConflictResolutionConfirmedFunc) {
            // reset decision flag after any response
            $scope.applyViewModel.isConflictResolutionConfirmed = null;

            switch (response.type) {
                case 0:
                    // Success
                    showAlertModal(response.successMessage);
                    $scope.reloadApplications();
                    break;
                case 1:
                    // Error
                    var applicationErrorPopup = controls.$applicationErrorPopup;
                    popup.openOverlay(applicationErrorPopup);
                    applicationErrorPopup.scope().lastResponse = response;
                    break;
                case 2:
                    // DecisionRequired
                    var conflictDecisionPopup = controls.$conflictDecisionPopup;
                    popup.openOverlay(conflictDecisionPopup);
                    conflictDecisionPopup.scope().lastResponse = response;
                    conflictDecisionPopup.scope().onConfirmed = function () {
                        onConflictResolutionConfirmedFunc(true);
                    };
                    break;
            }
        }

        function reloadApplications() {
            $scope.applyViewModel.providerId = $scope.applyViewModel.provider.value;
            scheduleTemplateApplicationsApi.reloadApplications($scope.applyViewModel.providerId, $scope.applyViewModel.scheduleTemplateId);
        }

        $scope.reloadApplications = reloadApplications;

        $scope.$watch('viewModel.days', function (days) {
            if (days) {
                $scope.applyViewModel.days = days.filter(function (x) {
                    return x.checked;
                }).map(function (x) {
                    var isRedirected = x.value !== x.redirect;
                    return {
                        day: isRedirected ? x.redirect : x.value,
                        redirectedFromDay: isRedirected ? x.value : null
                    };
                });
            } else {
                $scope.applyViewModel.days = [];
            }
        }, true);

        $scope.$watch('scheduler.zoomCoef', function (zoomCoef, zoomCoefOld) {
            if (zoomCoef) {
                scheduler.config.time_step = zoomCoef == 6 ? 10 : 15;

                var scrollDiv = $('.dhx_cal_data');
                var scrollCoef = scrollDiv[0].scrollHeight / scrollDiv.scrollTop();

                scheduler.config.hour_size_px = $scope.scheduler.zoomCoef * $scope.scheduler.hourHeightSize;
                scheduler.updateView();

                scrollDiv.scrollTop(scrollDiv[0].scrollHeight / scrollCoef);
            }
        });

        $scope.$watch('applyViewModel.startDate', function (newVal, oldVal) {
            if (newVal != oldVal) {
                var startDate = moment($scope.applyViewModel.startDate);
                if (moment($scope.applyViewModel.expirationDate) < startDate) {
                    $scope.applyViewModel.expirationDate = startDate.add(2, 'weeks').toDate();
                }
            }
        });
    };
})();