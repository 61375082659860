'use strict';

(function () {
    'use strict';

    angular.module('table.module').directive('pqDaterangeFilter', dateRangeFilter);

    function dateRangeFilter() {
        return {
            restrict: "E",
            templateUrl: 'components/table/searchFilters/dateRange.filter/dateRange.filter.html',
            link: function link(scope, element, attrs) {
                var $inputs = $('input', element),
                    options = {
                    todayBtn: "linked",
                    keyboardNavigation: false,
                    keepEmptyValues: true,
                    forceParse: false,
                    autoclose: true,
                    format: "m/d/yyyy",
                    orientation: "bottom auto",
                    todayHighlight: true
                };

                scope.$$postDigest(function () {
                    $('.input-daterange', element).datepicker(options).on('change', function (e) {
                        var formats = ['MMDDYYYY', 'M/D/YYYY', 'MM/DD/YYYY', 'MM/D/YYYY', 'M/DD/YYYY'],
                            dateFromStr = $inputs[0].value,
                            dateToStr = $inputs[1].value;

                        if (dateFromStr) {
                            var dateFrom = moment.utc($inputs[0].value, formats, true);

                            if (dateFrom.isValid()) {
                                scope.filter.values[0] = dateFrom.format('l');
                            }
                        } else {
                            scope.filter.values[0] = null;
                        }

                        if (dateToStr) {
                            var dateTo = moment.utc($inputs[1].value, formats, true);

                            if (dateTo.isValid()) {
                                scope.filter.values[1] = dateTo.format('l');
                            }
                        } else {
                            scope.filter.values[1] = null;
                        }

                        scope.$apply();
                    });
                });
            }
        };
    }
})();