'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').factory('scheduleEventPopup', scheduleEventPopup);

    scheduleEventPopup.$inject = ['$window', '$document'];

    function scheduleEventPopup($window, $document) {
        var service = {
            open: open,
            refreshHeaders: refreshHeaders
        };

        return service;

        function open(isNew, eventId, url) {
            var currentLocalUrl = $window.location.href.toString().split($window.location.host)[1];

            if (currentLocalUrl.indexOf('selectedEventId=') !== -1) {
                currentLocalUrl = currentLocalUrl.split('?')[0];
            }

            if (!isNew) {
                var popupUrl = "{0}{1}selectedEventId={2}".$format(currentLocalUrl, currentLocalUrl.indexOf('?') !== -1 ? '&' : '?', eventId);
                history.replaceState({ event: eventId }, null, popupUrl);
                registerCurrentPageInHistory();
            }

            showWafModal({
                url: url,
                cssClass: 'wide',
                actionHandler: function actionHandler(popupEventArgs) {
                    switch (popupEventArgs.actionType) {
                        case 'popup-close':
                            history.replaceState({ event: eventId }, null, currentLocalUrl);
                            registerCurrentPageInHistory();
                            $document.trigger('scheduleEvent:refresh');
                            break;
                        case 'submit':
                            $document.trigger('scheduleEvent:backgroundSubmit', eventId);
                            break;
                        case 'response':
                            if (isNew && popupEventArgs.response.Operation === 'Save' && popupEventArgs.response.IsSuccess) {
                                var splitedUrl = currentLocalUrl.split('#'),
                                    popupUrl = "{0}{1}selectedEventId={2}{3}".$format(splitedUrl[0], splitedUrl.indexOf('?') !== -1 ? '&' : '?', popupEventArgs.response.Id, splitedUrl[1] ? '#' + splitedUrl[1] : '');
                                history.replaceState({ event: eventId }, null, popupUrl);
                                registerCurrentPageInHistory();
                            }
                            if (popupEventArgs.response.isSuccess !== undefined && !popupEventArgs.response.isSuccess) {
                                popupEventArgs.close = true;
                                $document.trigger('scheduleEvent:refresh');
                                if (popupEventArgs.response.message) {
                                    showAlertModal(popupEventArgs.response.message);
                                }
                            }
                            break;
                        case 'content-loaded':
                            safeStorage(function (storage) {
                                restoreExpandedCollapsedStates(storage, popupEventArgs.content);
                            });

                            var notesHeader = $(popupEventArgs.content).find('#LinkedForm_ScheduleEventNote');
                            refreshHeaders(notesHeader);
                            break;
                        default:
                    }
                }
            });
        }

        function refreshHeaders(notesHeader) {
            if (!notesHeader) {
                notesHeader = $('#LinkedForm_ScheduleEventNote');
            }

            var length = notesHeader.find('input[name$="CreateDate"]').filter(function () {
                return !!this.value;
            }).length;

            var $elem = notesHeader.find('.collapsible-header');
            if (length > 0 && $elem.find('.badge').length == 0) {
                $elem.append('<span class="badge small-green">' + length + '</span>');
            } else {
                $elem.remove('.badge');
            }
        }
    }
})();