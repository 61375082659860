'use strict';

(function () {
    "use strict";

    angular.module('allMedsApp').directive('spinner', spinnerDirective);

    function spinnerDirective() {
        return {
            restrict: 'E',
            template:
            // The progress 'circle' is composed of two half-circles: the left side and the right
            // side. Each side has CSS applied to 'fill-in' the half-circle to the appropriate progress.
            '<div class="md-spinner-wrapper">' + '<div class="md-inner">' + '<div class="md-gap"></div>' + '<div class="md-left">' + '<div class="md-half-circle"></div>' + '</div>' + '<div class="md-right">' + '<div class="md-half-circle"></div>' + '</div>' + '</div>' + '</div>',
            compile: compile
        };

        function compile(tElement) {
            // The javascript in this file is mainly responsible for setting the correct aria attributes.
            // The animation of the progress spinner is done entirely with just CSS.
            tElement.attr('aria-valuemin', 0);
            tElement.attr('aria-valuemax', 100);
            tElement.attr('role', 'progressbar');
            return postLink;
        }

        function postLink(scope, element, attr) {
            var circle = element[0];
            // Scale the progress circle based on the default diameter.
            var diameter = attr.mdDiameter || 48;
            var scale = diameter / 48;
            circle.style['transform'] = 'scale(' + scale + ')';
        }
    }
})();