'use strict';

(function () {
    'use strict';

    angular.module('allMedsApp').directive('pqInfoPopup', pqInfoPopup);

    function pqInfoPopup() {
        return {
            restrict: 'A',
            template: '',
            link: function link(scope, el, attrs) {
                //generate unique id getTime - not enough
                var d = new Date().getTime();
                var internalId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = (d + Math.random() * 16) % 16 | 0;
                    d = Math.floor(d / 16);
                    return (c == 'x' ? r : r & 0x3 | 0x8).toString(16);
                });

                $(el).attr('internal-popup-id', internalId);
                $(el).popover({
                    trigger: 'click',
                    html: true,
                    content: function content() {
                        $('[pq-info-popup]').not('[internal-popup-id=' + internalId + ']').popover('hide');
                        return attrs.popoverHtml;
                    },
                    placement: 'top'
                });
            }
        };
    }
})();