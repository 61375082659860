'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').factory('scheduleDefaultsService', scheduleDefaultsService);

    function scheduleDefaultsService() {
        return {
            process: function process(settings) {
                var filters = angular.fromJson(settings.filterSettings);

                set(filters.officeIds, settings.lists.officeOptions);
                set(filters.providerIds, settings.lists.providerOptions);
                set(filters.resourceIds, settings.lists.resourceOptions);
                set(filters.appointmentTypeIds, settings.lists.appointmentTypes);

                if ((!filters.providerIds || !filters.providerIds.length) && (!filters.resourceIds || !filters.resourceIds.length) && settings.lists.providerOptions.length > 0) {
                    settings.lists.providerOptions[0].checked = true;
                }

                return settings;

                function set(ids, options) {
                    if (ids && ids.length) {
                        options.forEach(function (n) {
                            n.checked = false;
                        });

                        ids.forEach(function (id) {
                            options.forEach(function (n) {
                                if (n.id == id) {
                                    n.checked = true;
                                }
                            });
                        });
                    }
                }
            }
        };
    }
})();