'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').directive('scheduleTemplateApplications', scheduleTemplateApplications);

    scheduleTemplateApplications.$inject = ['scheduleTemplateDataService', 'scheduleTemplateApplicationsApi'];

    function scheduleTemplateApplications(scheduleTemplateDataService, scheduleTemplateApplicationsApi) {
        return {
            restrict: 'E',
            templateUrl: "scheduler/scheduleTemplate/schedule.template.applications/scheduleTemplateApplications.html",
            scope: {
                frequencyList: '=',
                days: '=',
                provider: '=',
                scheduleTemplateId: '=',
                showTemplateName: '@'
            },
            link: function link($scope, $element) {
                var controls = {
                    $conflictDecisionPopup: $('#schedule-template-conflict-decision-popup'),
                    $applicationErrorPopup: $('#schedule-template-application-error-popup'),
                    $extendDatePopup: $("#schedule-template-extend-date-popup")
                };

                $scope.onDeleteApplications = function () {
                    var appointmentIdsToRemove = $scope.getSelectedApplicationIds();

                    $scope.refreshSlotsResult = scheduleTemplateDataService.deleteAppliedSlots(appointmentIdsToRemove);
                };

                $scope.extendApplications = function (extendDate, isConflictResolutionConfirmed) {
                    if (extendDate) $scope.extendDate = extendDate;

                    $scope.extendApplicationsResult = scheduleTemplateDataService.extendAppliedSlots($scope.getSelectedApplicationIds(), $scope.extendDate, isConflictResolutionConfirmed, function (response) {
                        applyTemplateResponse(response, $scope.extendApplications);
                    });
                };

                // has the same method in scheduleTemplateController.js
                function applyTemplateResponse(response, onConflictResolutionConfirmedFunc) {
                    switch (response.type) {
                        case 0:
                            // Success
                            showAlertModal(response.successMessage);
                            $scope.reloadApplications();
                            break;
                        case 1:
                            // Error
                            var applicationErrorPopup = controls.$applicationErrorPopup;
                            popup.openOverlay(applicationErrorPopup);
                            applicationErrorPopup.scope().lastResponse = response;
                            break;
                        case 2:
                            // DecisionRequired
                            var conflictDecisionPopup = controls.$conflictDecisionPopup;
                            popup.openOverlay(conflictDecisionPopup);
                            conflictDecisionPopup.scope().lastResponse = response;
                            conflictDecisionPopup.scope().onConfirmed = function () {
                                onConflictResolutionConfirmedFunc($scope.extendDate, true);
                            };
                            break;
                    }
                }

                $scope.$watch('extendApplicationsResult.$resolved', function ($resolved) {
                    if ($resolved) {
                        if ($scope.extendApplicationsResult.success) $scope.reloadApplications();
                    }
                });

                $scope.$watch('refreshSlotsResult.$resolved', function ($resolved) {
                    if ($resolved) {
                        $scope.reloadApplications();
                    }
                });

                $scope.getSelectedApplications = function () {
                    if (!$scope.applications || !$scope.applications.items) return [];

                    return $scope.applications.items.filter(function (x) {
                        return x.delete == true;
                    });
                };

                $scope.getSelectedApplicationIds = function () {
                    return $scope.getSelectedApplications().map(function (x) {
                        return x.id;
                    });
                };

                $scope.minExtendDate = new Date();

                $scope.openExtendDatePopup = function () {
                    var appointmentsSelected = $scope.getSelectedApplications();
                    //var appointment1 = appointmentsSelected[0];

                    //if (!appointmentsSelected.every(function (x) { return appointment1.endDate == x.endDate; })) {
                    //    alert('You can only extend days application that have the same expiration date');
                    //    return;
                    //}

                    var appointmentLatestFirst = appointmentsSelected.sort(function (a, b) {
                        return moment(b.startDate) - moment(a.startDate);
                    })[0];

                    $scope.minExtendDate = moment(appointmentLatestFirst.startDate).add(1, 'days').toDate();

                    var allSame = appointmentsSelected.every(function (x) {
                        return appointmentsSelected[0].endDate == x.endDate;
                    });

                    $scope.sameEndDate = allSame ? appointmentsSelected[0].endDate : null;

                    popup.openOverlay(controls.$extendDatePopup);
                    controls.$extendDatePopup.scope().isOpen = true;
                };

                $scope.getFrequencyDisplayValue = function (frequencyValue) {
                    if (!frequencyValue) {
                        return '';
                    }
                    if (frequencyValue.startsWith('[')) {
                        // Special Weeks
                        var weekNumbers = angular.fromJson(frequencyValue);
                        if (weekNumbers.length === 0) {
                            return '';
                        }
                        var displayValue = weekNumbers.map(function (weekNumber) {
                            switch (weekNumber) {
                                case 1:
                                    return '1st';
                                case 2:
                                    return '2nd';
                                case 3:
                                    return '3rd';
                                default:
                                    return weekNumber + 'th';
                            }
                        }).join(', ');
                        displayValue += ' Week';
                        if (weekNumbers.length > 1) {
                            displayValue += 's';
                        }
                        return displayValue;
                    } else {
                        // Reqular Frequency
                        var frequency = $scope.frequencyList.firstOrDefault(function (x) {
                            return x.systemName === frequencyValue;
                        });
                        return frequency !== null ? frequency.displayName : '';
                    }
                };

                $scope.getDayDisplayValue = function (dayIndex) {
                    var day = $scope.days.firstOrDefault(function (x) {
                        return x.value == dayIndex;
                    });
                    return day !== null ? day.displayValue : '';
                };

                $scope.reloadApplications = function () {
                    scheduleTemplateApplicationsApi.onReloadApplication($scope.provider.value, $scope.scheduleTemplateId);
                };

                scheduleTemplateApplicationsApi.onReloadApplication = function (providerId, scheduleTemplateId) {
                    $scope.applications = scheduleTemplateDataService.getApplicationsByProvider(scheduleTemplateId, providerId);
                };
            }
        };
    }
})();