'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').component('pqSchedulerWaitlist', {
        bindings: {
            oldEventId: '<',
            slotEventId: '<',
            items: '<',
            selectWaitlistCallback: '&'
        },
        templateUrl: 'scheduler/schedulerWaitlist.component/schedulerWaitlist.component.html',
        controller: SchedulerWaitlistController
    });

    SchedulerWaitlistController.$inject = ['$http'];

    function SchedulerWaitlistController($http) {
        var ctrl = this;

        ctrl.formatDate = function (date) {
            return moment(date).format('L');
        };

        // use item from Waitlist
        ctrl.selectWaitlist = function (waitId) {
            $http({
                method: 'POST',
                url: '/AppointmentWaitlistQueueItem/UseWaitlist/?waitId=' + waitId + '&oldEventId=' + ctrl.oldEventId + '&slotEventId=' + ctrl.slotEventId
            }).finally(function () {
                ctrl.selectWaitlistCallback();
            });
        };
    }
})();