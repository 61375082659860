'use strict';

(function () {
    'use strict';

    angular.module('behaviors.module').directive('commandBehavior', commandBehavior);

    commandBehavior.$inject = ['$compile'];

    function commandBehavior($compile) {
        return {
            restrict: 'A',
            link: function link(scope, element, attrs) {
                if (attrs.commandTargetFormId) {
                    var somebh = 'bh-' + attrs.commandBehavior.toLowerCase() + '_' + attrs.commandTargetFormId.toLowerCase();

                    if (element.attr(somebh) === undefined) {
                        element.attr(somebh, '');
                        $compile(element)(scope);
                    }
                }
            }
        };
    }
})();