'use strict';

(function () {
    'use strict';

    angular.module('scheduler.module').factory('plannerHelpers', plannerHelpers);

    function plannerHelpers() {
        var service = {
            choiceUnits: choiceUnits,
            filterAndSelectIds: function filterAndSelectIds(list) {
                return list.filter(function (c) {
                    return c.checked;
                }).map(function (c) {
                    return c.id;
                });
            },
            onCheckFilterById: function onCheckFilterById(id, list, override) {
                if (id) {
                    var item = list.find(function (c) {
                        return c.id === id;
                    });if (item) {
                        item.checked = true;
                    }
                }if (override) {
                    list.map(function (c) {
                        return c.id != id ? c.checked = false : c.checked = true;
                    });
                }
            },
            getIncludesByMode: getIncludesByMode,
            getFilters: getFilters,
            getAllFilters: getAllFilters,
            checkFilterByIds: checkFilterByIds,
            getDefaultOfficeId: getDefaultOfficeId,
            isSingleUnitMode: function isSingleUnitMode(mode) {
                return mode === 'week' || mode === 'month';
            },
            getQueueInfo: getQueueInfo,
            isQueueMode: isQueueMode
        };

        return service;

        function getFilters(viewModelLists) {
            return {
                officeIds: service.filterAndSelectIds(viewModelLists.officeOptions),
                providerIds: service.filterAndSelectIds(viewModelLists.providerOptions),
                resourceIds: service.filterAndSelectIds(viewModelLists.resourceOptions)
            };
        }

        function getAllFilters(viewModelLists) {
            var filters = getFilters(viewModelLists);
            filters.appointmentTypeIds = service.filterAndSelectIds(viewModelLists.appointmentTypes);
            return filters;
        }

        function checkFilterByIds(ids, options) {
            if (ids && ids.length) {
                options.forEach(function (c) {
                    c.checked = false;
                });
                ids.forEach(function (id) {
                    options.find(function (c) {
                        return c.id == id;
                    }).checked = true;
                });
            }
        }

        function choiceUnits(newUnits, oldUnits) {
            var unitIds = oldUnits.filter(function (c) {
                return c.checked;
            }).map(function (c) {
                return c.id;
            });
            newUnits.filter(function (c) {
                return unitIds.indexOf(c.id) !== -1;
            }).forEach(function (c) {
                c.checked = true;
            });
            return newUnits;
        }

        function getIncludesByMode(schedulerMode) {
            var includes = {
                coverageProfileInfo: true,
                workingTime: false,
                officeInfo: false,
                patientInfo: false,
                providerInfo: false,
                resourceInfo: false,
                reasonInfo: true,
                appointmentTypeInfo: false,
                flowStatuses: false
            };
            switch (schedulerMode) {
                case 'month':
                    includes.appointmentTypeInfo = true;
                    includes.nonWorkingTime = true;
                    break;
                case 'week':
                case 'UnitDayView':
                    includes.workingTime = true;
                    includes.nonWorkingTime = true;
                    includes.officeInfo = true;
                    includes.patientInfo = true;
                    includes.appointmentTypeInfo = true;
                    break;
                case 'PatientFlow':
                    includes.officeInfo = true;
                    includes.PaidInfo = true;
                    includes.patientInfo = true;
                    includes.providerInfo = true;
                    includes.resourceInfo = true;
                    includes.flowStatuses = true;
                    includes.responsibleInfo = true;
                    break;
            }
            return includes;
        }

        function getDefaultOfficeId(event, workingTimes, selectedUnits) {
            if (workingTimes) {
                var suitableWorkingTimes = workingTimes.filter(function (wt) {
                    if (wt.providerId == event.providerId) {
                        return wt.timeRanges.some(function (t) {
                            return moment(event.start_date).isBetween(t.start_date, t.end_date) || moment(event.end_date).isBetween(t.start_date, t.end_date);
                        });
                    }
                });

                if (suitableWorkingTimes.length > 0) {
                    return suitableWorkingTimes[0].officeId;
                } else if (event.resourceId) {
                    var units = selectedUnits.filter(function (unit) {
                        if (unit.type == "Resource" && unit.id == event.resourceId) {
                            return unit.officeId;
                        }
                    });

                    return Array.isArray(units) && units[0].officeId;
                }
            }

            return null;
        }

        function getQueueInfo(viewModel) {
            var item = viewModel.recallItem || viewModel.checkupItem;
            if (!item) {
                if (viewModel.rescheduleItem) {
                    item = viewModel.rescheduleItem.originAppointment;
                }
                if (viewModel.waitlistItem) {
                    item = viewModel.waitlistItem.realAppointment ? viewModel.waitlistItem.realAppointment : viewModel.waitlistItem;
                }
                if (viewModel.copyItem) {
                    item = viewModel.copyItem;
                }
            }
            return item;
        }

        function isQueueMode(viewModel) {
            return viewModel.rescheduleItem || viewModel.recallItem || viewModel.waitlistItem || viewModel.checkupItem || viewModel.copyItem;
        }
    }
})();