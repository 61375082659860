'use strict';

(function () {
    'use strict';

    angular.module('table.module').directive('pqTableResult', pqTableResult);

    function pqTableResult() {
        return {
            restrict: 'E',
            templateUrl: 'components/table/table.result.directive/table.result.directive.html',
            scope: {
                metadata: '<',
                result: '<',
                sorting: '<',
                refresh: '<'
            },
            link: function link(scope) {},
            controller: function controller($scope) {
                $scope.checkedAll = false;

                $scope.sort = function (columnName) {
                    if (!$scope.sorting.sortColumn || !$scope.result.rows.length) return;

                    if ($scope.sorting.sortColumn == columnName) {
                        $scope.sorting.isDescending = !$scope.sorting.isDescending;
                    } else {
                        $scope.sorting.isDescending = false;
                        $scope.sorting.sortColumn = columnName;
                    }

                    $scope.refresh();
                };

                $scope.toggleMultiselectCheck = function () {
                    $scope.checkedAll = !$scope.checkedAll;

                    $scope.result.rows.forEach(function (row) {
                        row.find(function (col) {
                            return col.type === "Multiselect";
                        }).isChecked = $scope.checkedAll;
                    });
                };

                $scope.$on('recordSelectionChanged', function () {
                    $scope.checkedAll = $scope.result.rows.every(function (row) {
                        return row.find(function (col) {
                            return col.type === "Multiselect";
                        }).isChecked;
                    });
                });
            }
        };
    }
})();