'use strict';

(function () {
    'use strict';

    angular.module('table.module').directive('pqSearchFilter', pqSearchFilter);

    pqSearchFilter.$inject = ['$compile'];

    function pqSearchFilter($compile) {
        return {
            restrict: 'E',
            require: '^pqTable',
            scope: {
                filter: '='
            },
            templateUrl: 'components/table/searchFilters/searchFilter.directive/searchFilter.directive.html',
            link: function link(scope, element, attrs, pqTableCtrl) {
                var $container = $('.filter-control-container', element);

                $('<pq-' + scope.filterType + '-filter />').appendTo($container);

                $compile($container.contents())(scope);

                scope.$watch(function () {
                    return scope.filter.values;
                }, function (nv, ov) {
                    if (nv != ov) {
                        pqTableCtrl.refresh(null, null, true);
                    }
                }, true);
            },
            controller: function controller($scope) {
                $scope.filterType = $scope.filter.type.toLowerCase();
            }
        };
    }
})();